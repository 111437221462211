import React, { useEffect } from 'react';

import CRD_PREQUALIFICATIONS from './crd_prequalifiers/crd_prequalifiers';



export default function TAB_PREQUALIFICATIONS({ requirement_id, requirement_status }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <CRD_PREQUALIFICATIONS requirement_id={requirement_id} requirement_status={requirement_status} />
    );
};
