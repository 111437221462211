import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Message } from 'semantic-ui-react';
import datelib from 'libs/date-lib';
import { useNavigate } from 'react-router-dom';
import auth from 'libs/auth-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_INDIVIDUAL from './mdl_add_individual/mdl_add_individual';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';
import CMP_MDL_INDIVIDUAL_INVITE from 'components/cmp_mdl_individual_invite/cmp_mdl_individual_invite';
import CMP_MDL_INDIVIDUAL_INVITE_BULKERRORS from 'components/cmp_mdl_individual_invite_bulkerrors/cmp_mdl_individual_invite_bulkerrors';
import CMP_INDIVIDUAL_STATUS from 'components/cmp_individual_status/cmp_individual_status';


export default function TAB_INDIVIDUALS({ assignment, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_onsite, set_onsite ] = useState(false);
    const [ var_candelete, set_candelete ] = useState(false);
    const [ var_addindividual_open, set_addindividual_open ] = useState(false);
    const [ var_invite_individual_open, set_invite_individual_open ] = useState(false);
    const [ var_deleteindividual_open, set_deleteindividual_open ] = useState(false);
    const [ var_deleteindividual_id, set_deleteindividual_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_invite_bulkerrors_batchid, set_invite_bulkerrors_batchid ] = useState(null);
    const [ var_invite_bulkerrors_open, set_invite_bulkerrors_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'INDIVIDUALS' && assignment.location_id) {
            set_message_text(null);
            set_refreshtable(!var_refreshtable);
            populate_individuals();
            set_onsite(auth.has_access(assignment, 'ORG-ONSITE', 'read'));
            set_candelete(auth.has_access(assignment, 'ORG-INDV', 'delete'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, assignment.location_id]);

    useEffect(() => {
        if (!var_addindividual_open && var_focuselement?.id === 'btn_addindividual') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addindividual_open]);

    useEffect(() => {
        if (!var_invite_individual_open && var_focuselement?.id === 'btn_invite_individual') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_invite_individual_open]);

    useEffect(() => {
        if (!var_deleteindividual_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_individuals').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deleteindividual_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(populateconfig) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_individuals(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_individuals(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_individuals(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_individuals(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('org-indv', '/get-individuals/' + assignment.location_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }

    function API_delete_individual_location() {
        return API.del('org-indv', '/delete-individual-location/' + var_deleteindividual_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addindividual() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_addindividual_open(true);
    }

    function onChange_addindividual() {
        populate_individuals();
        set_addindividual_open(false);
        set_message_type('SUCCESS');
        set_message_text(t('The selected individuals have been added.'));
    }

    function onClick_individual(id) {
        set_message_text(null);
        navigate(`/assignments/${assignment.location_id}/INDIVIDUALS/individual/${id}`);
    }

    function onDelete_individual(id) {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_deleteindividual_id(id);
        set_deleteindividual_open(true);
    }

    async function onConfirm_delete() {
        set_focuselement(document.getElementById('crd_individuals'));
        set_deleteindividual_open(false);
        set_processing(true);
        await API_delete_individual_location();
        populate_individuals();
        set_processing(false);
    }

    function onClick_invite_individual() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_invite_individual_open(true);
    }

    function onInvite(is_invite_suppressed) {
        populate_individuals();
        set_message_type('SUCCESS');
        is_invite_suppressed
            ? set_message_text(t('Individual added.'))
            : set_message_text(t('An invitation has been sent.'));
    }

    function onInviteBulk(batch_id, total_count, failure_count) {
        if (total_count > failure_count) {
            populate_individuals();
        }
        if (failure_count === 0) {
            set_message_type('SUCCESS');
            set_message_text(`${total_count} ${t('individuals have been successfully processed.')}`);
        } else {
            set_message_type('ERROR');
            set_message_text(render_bulk_invite_error_message(failure_count));
            set_invite_bulkerrors_batchid(batch_id);
        }
        set_invite_individual_open(false);
    }

    // RENDER APP ======================================================================================================

    return (
        <>

            <div className='card rounded-lg shadow-sm' id='crd_individuals' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_individuals' className='text--xl-medium'>{t('Individuals')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        {auth.has_access(assignment, 'ORG-INDV', 'insert') &&
                            <Button id='btn_addindividual' className='primary' onClick={onClick_addindividual}>{t('Add individual')}</Button>
                        }

                        {auth.has_access(assignment, 'ORG-INDV', 'invite') &&
                            <Button id='btn_invite_individual' className='secondary' onClick={onClick_invite_individual}>{t('Invite individual')}</Button>
                        }
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='individuals' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Individuals')} downloadfunction={download_records}
                        onChange={populate_individuals}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='individual_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='email' datatype='text' filtertype='text'>{t('EMAIL')}</Table.HeaderCell>
                            <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('JOB TITLE')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            {var_onsite && <Table.HeaderCell field='is_visitor' datatype='text' filtertype='option'>{t('VISITOR')}</Table.HeaderCell>}
                            {var_onsite && <Table.HeaderCell field='onsite_display' datatype='text' filtertype='option'>{t('ON-SITE')}</Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_individuals.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <CMP_INDIVIDUAL_STATUS
                                        individual={item}
                                        is_open={false}
                                        onOpen={() => null}
                                        onClose={() => null}
                                        onChange={() => null}
                                        onMessage={() => null}
                                        disabled={true}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.individual_id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.job_title}</Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    {!var_onsite ?
                                        <>
                                            {item.organization}
                                            {var_candelete &&
                                                <Button className='tertiary' onClick={() => onDelete_individual(item.id)}>
                                                    <Icon name='delete' className='color--primary-500'/>
                                                </Button>
                                            }
                                        </>
                                        :
                                        <>{item.organization}</>
                                    }
                                    </Table.Cell>
                                {var_onsite && <Table.Cell>{item.is_visitor}</Table.Cell>}
                                {var_onsite &&
                                    <Table.Cell className='cell__icon--right'>
                                        <div className={'badge text--sm-medium  ' +
                                            (   item.onsite === 'On-site' ?
                                                'badge--green'
                                                : item.onsite === 'Missing' ?
                                                    'badge--red'
                                                    : item.onsite === 'Remote' ?
                                                        'badge--gray'
                                                        : 'badge--gray')}>
                                            {item.onsite_display}
                                        </div>
                                        {(var_candelete && item.is_visitor !== 'Yes') &&
                                            <Button className='tertiary' onClick={() => onDelete_individual(item.id)} >
                                                <Icon name='delete' className='color--primary-500' alt={t('Remove individual from assignment')} />
                                            </Button>
                                        }
                                    </Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

                <div className='card__footer--mobilebtns'>
                    {auth.has_access(assignment, 'ORG-INDV', 'insert') &&
                        <Button id='btn_addindividual' className='primary' onClick={onClick_addindividual}>{t('Add individual')}</Button>
                    }

                    {auth.has_access(assignment, 'ORG-INDV', 'invite') &&
                        <Button id='btn_invite_individual' className='secondary' onClick={onClick_invite_individual}>{t('Invite individual')}</Button>
                    }
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

            <CMP_MDL_INDIVIDUAL_INVITE
                display={var_invite_individual_open}
                onClose={() => set_invite_individual_open(false)}
                onChange={onInvite}
                onChangeBulk={onInviteBulk}
                assignment={assignment}
            />

            <CMP_MDL_INDIVIDUAL_INVITE_BULKERRORS
                display={var_invite_bulkerrors_open}
                onClose={() => set_invite_bulkerrors_open(false)}
                batch_id={var_invite_bulkerrors_batchid}
            />

            <MDL_ADD_INDIVIDUAL
                is_open={var_addindividual_open}
                onClose={() => set_addindividual_open(false)}
                assignment={assignment}
                onChange={onChange_addindividual}
            />

            <CMP_CONFIRMATION display={var_deleteindividual_open} title={t('Remove individual')} message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Removing this individual will impact their ability to work on this assignment and your compliance reports.')}</div>
                        <div>{t('They can be added again at any time.')}</div>
                    </div>
                } positive_option={t('Remove')} negative_option={t('Cancel')} onConfirm={onConfirm_delete} onCancel={() => set_deleteindividual_open(false)} />
        </>
    )

    function render_bulk_invite_error_message(failure_count) {
        return (
            <div className='msg__bulk_invite_error'>
                <div>{`${t('An error occurred that prevented')} ${failure_count} ${t('from being processed.')}`}</div>
                <div className='text--anchor' onClick={() => set_invite_bulkerrors_open(true)}>{t('View individuals and details')}</div>
            </div>
        );
    }
}