import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';

import CMP_REQUIREMENT from 'components/cmp_requirement/cmp_requirement';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_ADDCREDENTIAL from 'components/cmp_addcredential/cmp_addcredential';
import CMP_WARNING from 'components/cmp_warning/cmp_warning';

import 'i18n';

import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';


export default function CRD_REQUIREMENTS(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'status_display' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_selected_individualrequirement, set_selected_individualrequirement ] = useState({});
    const [ var_updaterequirement_open, set_updaterequirement_open ] = useState(false);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_mdl_addcredential_open, set_mdl_addcredential_open ] = useState(false);
    const [ var_cmp_warning_open, set_cmp_warning_open ] = useState(false);
    const [ var_cmp_warning_message, set_cmp_warning_message ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( () => {
        populate_requirements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_updaterequirement_open) {
            if (var_focuselement && !var_mdl_confirmation_open) {
                if (var_focuselement.isConnected) {
                    var_focuselement.focus();
                    set_focuselement(null);
                } else {
                    document.getElementById('crd_requirements').focus();
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_updaterequirement_open]);

    useEffect(() => {
        if (!var_mdl_confirmation_open) {
            if (var_focuselement && !var_mdl_addcredential_open) {
                if (var_focuselement.isConnected) {
                    var_focuselement.focus();
                    set_focuselement(null);
                } else {
                    document.getElementById('crd_requirements').focus();
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_confirmation_open]);

    useEffect(() => {
        if (!var_mdl_addcredential_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_requirements').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_addcredential_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(populateconfig, focuselement_id) {

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_requirements(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_requirements(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_requirements').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_requirements(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }
    async function download_requirements(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_requirements(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-all-requirements',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_requirement(id){
        let requirement = var_requirements.find(item => item.id === id);
        set_selected_individualrequirement(requirement);
        set_updaterequirement_open(true);
    }

    /**
     * 
     * @param {Object} results 
     * @param {'YES'|'NO'} results.updated
     * @param {'YES'|'NO'} results.add_credential
     * @param {'YES'|'NO'} results.added_credential_is_preapproved
     * @param {String} results.added_credential_name
     * @param {'YES'|'NO'} missing
     * @param {String} focuselement_id 
     */
    function onChange_requirement(results, missing, focuselement_id) {
        if (missing === 'YES') {
            set_focuselement(document.getElementById(focuselement_id));
            set_mdl_confirmation_open(true);
        }
        if (results.updated === 'YES') {
            populate_requirements(null, focuselement_id);
        }
        // User chose to add a credential so show warning about preapproval list or manual admin approval
        if (results.add_credential === 'YES') {
            set_cmp_warning_open(true);
            if (results.added_credential_is_preapproved === 'YES') {
                set_cmp_warning_message(t('{{credential_name}} will be automatically submitted to the requirement once verified by the issuer.', { credential_name: results.added_credential_name }));
            } else {
                set_cmp_warning_message(t('{{credential_name}} must be verified by the issuer before it can be submitted to a requirement.',  { credential_name: results.added_credential_name }));
            }
        }
        set_updaterequirement_open(false);
    }

    function onClick_credential(id) {
        navigate('/credentials/ALL/' + id);
    }

    function onConfirm_addcredential() {
        set_mdl_confirmation_open(false);
        set_mdl_addcredential_open(true);
    }

    function onChange_addcredential() {
        set_mdl_addcredential_open(false);
        populate_requirements(null, var_focuselement?.id);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_requirements' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_requirements' className='text--xl-medium'>{t('All requirements')}</div>
                    </div>
                </div>

                <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Requirements')} downloadfunction={download_requirements}
                        onChange={populate_requirements}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='cell__icon' field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='comments' datatype='text' filtertype='text'>{t('COMMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='location_name' datatype='text' filtertype='text'>{t('ASSIGNMENT')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_requirements.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='cell__icon cell--center'>
                                {
                                        item.status === 'Valid'
                                        ? <Icon name='checkmark' className='color--green-500' alt={t('Valid')} />
                                        : item.status === 'Expiring'
                                        ? <Icon name='warning_circle' className='color--green-500' alt={t('Expiring')} />
                                        : item.status === 'Pending'
                                        ? <Icon name='eye_circle' className='color--yellow-500' alt={t('Pending')} />
                                        :  item.status === 'Invalid'
                                        ? <Icon name='xicon_solid' className='color--red-600' alt={t('Invalid')} />
                                        : <Icon name='error' className='color--red-600'  alt={item.status_display} />
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <Button id={`btn_cmp_requirement_${item.id}`} className='tertiary' onClick={() => { set_focuselement(document.activeElement); onClick_requirement(item.id); }}>
                                        <Table.CellContentTwoLine primary_text={<div className='text--anchor'>{item.name}</div>} secondary_text={item.status_display} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className='text--anchor' >
                                    <Button className='tertiary' onClick={() => onClick_credential(item.individualcredential_id)}>
                                        {item.credential}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.comments}</Table.Cell>
                                <Table.Cell>{item.organization_name}</Table.Cell>
                                <Table.Cell>{item.location_name}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>

            <CMP_REQUIREMENT
                individualrequirement_id={var_selected_individualrequirement.id}
                requirement_id={var_selected_individualrequirement.requirement_id}
                requirement_name={var_selected_individualrequirement.name}
                requirement_status={var_selected_individualrequirement.status}
                assignedfrom_type={var_selected_individualrequirement.location_id ? 'LOCATION' : 'ORGANIZATION'}
                assignedfrom_id={var_selected_individualrequirement.location_id || var_selected_individualrequirement.organization_id}
                assignedfrom_name={var_selected_individualrequirement.location_name || var_selected_individualrequirement.organization_name}
                credential_name={var_selected_individualrequirement.credential_name}
                individualcredential_id={var_selected_individualrequirement.individualcredential_id}
                is_open={var_updaterequirement_open}
                onClose={() => set_updaterequirement_open(false)}
                onChange={onChange_requirement}
            />

            <CMP_CONFIRMATION
                display={var_mdl_confirmation_open}
                title={t('Add a credential')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Would you like to add a credential to your wallet for') + ' ' + var_selected_individualrequirement.name + ' ?'}</div>
                        <div>{t('A credential may fulfill the requirement once it has been reviewed and validated by an administrator.')}</div>
                    </div>}
                positive_option={t('Add credential')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_addcredential}
                onCancel={() => set_mdl_confirmation_open(false)}
            />

            <CMP_ADDCREDENTIAL
                display={var_mdl_addcredential_open}
                onClose={() => set_mdl_addcredential_open(false)}
                onChange={onChange_addcredential}
                default_credential_category='ALL'
            />

            <CMP_WARNING
                display={var_cmp_warning_open}
                title={t('Credential awaiting verification')}
                message={var_cmp_warning_message}
                onClose={() => set_cmp_warning_open(false)}
            />
        </>
    )

}
