import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import { CHECKBOX, FORM_FILEUPLOAD, FORM_INPUT, FORM_SELECT, FORM_TEXTAREA, FORM_TOGGLE, INPUT, SELECT, TOGGLE } from 'components/cmp_form/cmp_form';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'i18n';
import form_helper from 'libs/form-lib';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_CREDENTIAL from './mdl_add_credential/mdl_add_credential';

import './cmp_edit_prequalifier.css';

function CMP_EDIT_PREQUALIFIER({ prequalifier, validate, onChange, onValidate }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_last_new_id, set_last_new_id ] = useState('0');
    const [ var_addcredential_open, set_addcredential_open ] = useState(false);
    const [ var_addcredential_status, set_addcredential_status ] = useState('VALID');
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_deletecredential_id, set_deletecredential_id ] = useState(null);
    const [ var_deletecredential_open, set_deletecredential_open ] = useState(false);
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_select_options, set_select_options ] = useState([]);

    const [ var_errors, set_errors ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
        set_fileupload_status(null);
        if (prequalifier.survey) {
            populate_survey_list();
        } else if (prequalifier.verifiable_presentation?.has_verification_template_feature) {
            populate_verification_template_list();
        }
    }, []);

    useEffect(() => {
        if (!var_addcredential_open && var_focuselement?.id === 'btn_addcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    }, [ var_addcredential_open ]);

    useEffect(() => {
        if (!var_deletecredential_open && var_focuselement?.id === 'selectcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    }, [var_deletecredential_open ]);

    useEffect(() => {
        if (var_fileupload_status) {
            let updated = _.cloneDeep(prequalifier);
            updated.document.fileupload_status = var_fileupload_status;
            onChange(updated);
        }
    }, [ var_fileupload_status ]);

    useEffect(() => {
        if (validate) {
            let updated = _.cloneDeep(prequalifier);
            let errors = [];
            if (!form_helper.validate_required_string(updated.name)) {
                errors.push({ property: 'name', description: t('Name is required') });
            }
            if (!form_helper.validate_required_string(updated.description)) {
                errors.push({ property: 'description', description: t('Description is required') });
            }
            switch (prequalifier.prequalifier_type) {
                case 'ATTESTATION':
                    if (!form_helper.validate_required_string(updated.attestation.attestation_text)) {
                        errors.push({ property: 'attestation.attestation_text', description: t('Attestation is required') });
                    }
                    if (!form_helper.validate_required_string(updated.attestation.checkbox_label)) {
                        errors.push({ property: 'attestation.checkbox_label', description: t('Checkbox label is required') });
                    }
                    // cleanse properties
                    form_helper.cleanse_string_property(updated.attestation, 'attestation_text');
                    form_helper.cleanse_string_property(updated.attestation, 'checkbox_label');
                    break;
                case 'CREDENTIAL':
                    if (updated.prequalifier_subtype === 'CREDIT') {
                        form_helper.cleanse_decimal_property(updated.credential, 'credits_required');
                        updated.credential.preapprovals.forEach(item => form_helper.cleanse_decimal_property(item, 'credit_value'));
                        if (!form_helper.validate_positive_number(updated.credential.credits_required)) {
                            errors.push({ property: 'credential.credits_required', description: t('The number of required credits must be set as a value greater than zero.') });
                        }
                        if (updated.credential.save_credit_value === 'YES' && updated.credential.preapprovals.some(item => !form_helper.validate_positive_number(item.credit_value))) {
                            errors.push({ property: 'credit_value', description: t('The credit value of a credential must be set as a value greater than zero.') });
                        } else if (updated.credential.preapprovals.reduce((acc, curr) => acc + curr.credit_value, 0) < updated.credential.credits_required) {
                            errors.push({ property: 'credential.credit_threshold', description: updated.credential.save_credit_value === 'YES' ? t('The total value of the approved credentials falls below the threshold required to fulfill the pre-qualifier requirement.') : t('The total value of the approved credentials falls below the threshold required to fulfill the pre-qualifier requirement. If you would like to override the default credit value, check the \'Save credit values\' checkbox.') });
                        }
                    }
                    if (updated.prequalifier_subtype !== 'OPEN LIST' && updated.credential.preapprovals.length === 0) {
                        errors.push({ property: 'credential.preapprovals', description: t('At least one credential must be selected') });
                    }
                    break;
                case 'DOCUMENT':
                    if (updated.id === undefined && updated.document.fileupload_status !== 'UPLOAD READY') {
                        errors.push({ property: 'document.filename', description: t('Document is required') });
                    }
                    break;
                case 'SURVEY':
                    if (!updated.survey.survey_id) {
                        errors.push({ property: 'survey.survey_id', description: t('Select a survey.') });
                    }
                    if (!form_helper.validate_required_string(updated.survey.title)) {
                        errors.push({ property: 'survey.title', description: t('The title of the modal window is required.') });
                    }
                    // cleanse properties
                    form_helper.cleanse_string_property(updated.survey, 'title');
                    break;
                case 'VERIFIABLE_PRESENTATION':
                    if (updated.verifiable_presentation.template_type === 'LIST' && !updated.verifiable_presentation.verification_template_id) {
                        errors.push({ property: 'verifiable_presentation.verification_template_id', description: t('Verification Template is required.') });
                    } else if (updated.verifiable_presentation.template_type === 'URL' && !form_helper.validate_required_string(updated.verifiable_presentation.verification_template_url)) {
                        errors.push({ property: '', description: t('Template URL is required.') });
                    }
                    // cleanse properties
                    if (updated.verifiable_presentation.template_type === 'LIST') {
                        updated.verifiable_presentation.verification_template_url = null;
                    } else {
                        form_helper.cleanse_string_property(updated.verifiable_presentation, 'verification_template_url');
                    }
                    break;
                default:
                    break;
            }
            // cleanse properties
            form_helper.cleanse_string_property(updated, 'name');
            form_helper.cleanse_string_property(updated, 'description');
            set_errors(errors);
            onValidate(errors.length === 0, updated);
        }
    }, [ validate, prequalifier, onValidate, t ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_survey_list() {
        let data = await API_get_org_prequalifier_survey_options();
        set_select_options(data.filter(item => item.status === 'ACTIVE' || item.id === prequalifier.survey.survey_id).map(item => ({ value: item.id, text: item.name })));
    }

    async function populate_verification_template_list() {
        let data = await API_get_org_prequalifier_verification_template_options();
        set_select_options(data.filter(item => item.status === 'ACTIVE' || item.id === prequalifier.verifiable_presentation.verification_template_id).map(item => ({ value: item.id, text: item.name })));
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_prequalifier_survey_options() {
        return API.get('requirements2', '/get-org-prequalifier-survey-options');
    }

    function API_get_org_prequalifier_verification_template_options() {
        return API.get('requirements2', '/get-org-prequalifier-verification-template-options');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(e, { name, value }) {
        let updated = _.cloneDeep(prequalifier);
        let errors = var_errors;

        switch (name) {
            case 'credential.credits_required':
                value = form_helper.format_decimal_string(value, 3, 2);
                if (value.length === 0) {
                    value = null;
                }
                errors = errors.filter(item => item.property !== 'credential.credit_threshold');
                break;
            default:
                break;
        }

        let property_array = name.split('.');
        let object = updated;
        for (let index = 0; index < property_array.length; index++) {
            if (index < property_array.length - 1) {
                object = object[property_array[index]];
            } else {
                object[property_array[index]] = value;
            }
        }
        onChange(updated);
        set_errors(errors.filter(item => item.property !== name));
    }

    function onDelete_credential(id) {
        set_focuselement(document.getElementById('selectcredential'));
        set_deletecredential_id(id);
        set_deletecredential_open(true);
    }

    function onConfirm_delete() {
        let updated = _.cloneDeep(prequalifier);
        updated.credential.preapprovals = updated.credential.preapprovals.filter(item => item.id !== var_deletecredential_id);
        if (var_deletecredential_id.length === 36) {
            if (updated.credential.deleted_preapprovals === undefined) {
                updated.credential.deleted_preapprovals = [];
            }
            updated.credential.deleted_preapprovals.push(var_deletecredential_id);
        }
        onChange(updated);
        set_deletecredential_open(false);
    }

    function onClick_addcredential(status) {
        set_focuselement(document.activeElement);
        set_addcredential_status(status);
        set_addcredential_open(true);
    }

    function onCredentialAdded(issuer_id, issuer_name, credential_ids, credential_list) {
        let last_id = Number(var_last_new_id);
        let updated = _.cloneDeep(prequalifier);
        if (credential_ids === null) {
            last_id++;
            updated.credential.preapprovals.push({ id: String(last_id), issuer_id, issuer_name, credential_id: null, credential_name: t('ALL'), credit_value: null, footprint_credit_value: null, status: var_addcredential_status });
        } else {
            for (let credential_id of credential_ids) {
                last_id++;
                let credential = credential_list.find(item => item.id === credential_id);
                updated.credential.preapprovals.push({ id: String(last_id), issuer_id, issuer_name, credential_id, credential_name: credential.name, credit_value: credential.credit_value, footprint_credit_value: credential.footprint_credit_value, status: var_addcredential_status });
            }
        }
        set_last_new_id(last_id);
        onChange(updated);
        set_addcredential_open(false);
        set_errors(var_errors.filter(item => item.property !== 'credential.credit_threshold'));
    }

    function onChange_credential_credit_value(id, value) {
        let updated = _.cloneDeep(prequalifier);
        value = form_helper.format_decimal_string(value, 3, 2);
        updated.credential.preapprovals.find(item => item.id === id).credit_value = value.length === 0 ? null : value;
        onChange(updated);
        set_errors(var_errors.filter(item => !['credential.preapprovals.credit_value', 'credential.credit_threshold'].includes(item.property)));
    }

    function onChange_save_credit_value() {
        let updated = _.cloneDeep(prequalifier);
        updated.credential.save_credit_value = updated.credential.save_credit_value === 'YES' ? 'NO' : 'YES';
        if (updated.credential.save_credit_value === 'NO') {
            // revert all credit values to their default
            for (let preapproval of updated.credential.preapprovals) {
                preapproval.credit_value = preapproval.footprint_credit_value;
            }
        }
        onChange(updated);
        set_errors(var_errors.filter(item => !['credential.preapprovals.credit_value', 'credential.credit_threshold'].includes(item.property)));
    }

    function onChange_file(details) {
        let updated = _.cloneDeep(prequalifier);
        updated.document.filename = details.filename;
        updated.document.submitted_filename = details.display_name;
        onChange(updated);
    }

    function onChange_fileselect() {
        set_errors(var_errors.filter(item => item.property !== 'document.filename'));
    }

    function onChange_fileupload_status(new_status) {
        set_fileupload_status(new_status);
    }

    // RENDER APP ======================================================================================================

    return (
        <Form id='cmp_edit_prequalifier' className='modal__content' aria-labelledby='hdr_edit_prequalifier' tabIndex='0'>
            {prequalifier.prequalifier_type === 'ATTESTATION'
                ? render_attestation()
                : prequalifier.prequalifier_type === 'CREDENTIAL'
                ? render_credential()
                : prequalifier.prequalifier_type === 'DOCUMENT'
                ? render_document()
                : prequalifier.prequalifier_type === 'SURVEY'
                ? render_survey()
                : prequalifier.prequalifier_type === 'VERIFIABLE_PRESENTATION'
                ? render_verifiable_presentation()
                : null}
        </Form>
    );

    function render_attestation() {
        return (
            <div className='content__wrapper content_attestation'>

                <div className='content__description text--sm-medium'>{t('This pre-qualification enables capturing a declaration from an individual.')}</div>

                {render_name_description()}

                <FORM_TEXTAREA
                    property='attestation.attestation_text'
                    label={t('Attestation')}
                    value={prequalifier.attestation.attestation_text}
                    onChange={onChange_input}
                    placeholder={t('Attestation details')}
                    maxLength={1000}
                    disabled={false}
                    errors={var_errors}
                    description={t('The detailed description offers supplementary information about the attestation being requested. It is presented to the individual when they opt to complete the attestation process, providing a clearer understanding of the acknowledgement they are being asked to make.')}
                />

                <FORM_TEXTAREA
                    property='attestation.checkbox_label'
                    label={t('Checkbox label')}
                    value={prequalifier.attestation.checkbox_label}
                    onChange={onChange_input}
                    placeholder={t('Checkbox label')}
                    maxLength={200}
                    disabled={false}
                    errors={var_errors}
                    description={t('An explicit acknowledgement of the attestation is captured through a provided checkbox.')}
                />

            </div>
        );
    }

    function render_credential() {
        return (
            <div className='content__wrapper content_credential'>

                {var_errors.some(item => item.property === 'credential.credit_threshold') &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        content={var_errors.find(item => item.property === 'credential.credit_threshold').description}
                    />
                }

                {render_name_description()}

                {prequalifier.prequalifier_subtype === 'CREDIT' &&
                    <FORM_INPUT
                        property='credential.credits_required'
                        label={t('Required credits')}
                        value={[null, undefined].includes(prequalifier.credential.credits_required) ? '' : String(prequalifier.credential.credits_required)}
                        onChange={onChange_input}
                        placeholder={t('Number')}
                        errors={var_errors}
                        description={t('Please specify the exact number of credits an individual must submit to satisfy this pre-qualification.')}
                    />
                }

                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Approved credentials')}</div>
                    <div className='field detailsgroup__value'>
                        <div className='detailsgroup__description text--sm-regular approved_credentials_description'>
                            <div>{t('Add credentials that are approved to meet this pre-qualification. All other credentials will be rejected.')}</div>
                            {prequalifier.prequalifier_subtype === 'CREDIT' &&
                                <div>
                                    <span className='text--sm-bold'>{t('Note')}: </span>
                                    <span>{t('Each credential has a default value of 1 credit unless the issuer specifies otherwise. You can adjust this value after adding the credential.')}</span>
                                </div>
                            }
                        </div>
                        <div className='dropzone__fileupload credentials__wrapper'>
                            <Icon name='credential2' className='icon__upload' />
                            <div>
                                <Button id='btn_addcredential' type='button' className='tertiary button__browse' onClick={() => onClick_addcredential('VALID')}>
                                    {t('Add approved credentials')}
                                </Button>
                            </div>

                            {var_errors.some(item => item.property === 'credential.preapprovals.credit_value') &&
                                <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    content={var_errors.find(item => item.property === 'credential.preapprovals.credit_value').description}
                                />
                            }

                            {prequalifier.prequalifier_subtype === 'CREDIT' && prequalifier.credential.preapprovals.length > 0 &&
                                <div className='credentials__wrapper__header text--sm-medium'>
                                    <div>{t('Credential')}</div>
                                    <div>{t('Credit value')}</div>
                                </div>
                            }

                            {prequalifier.credential.preapprovals.filter(item => item.status === 'VALID').map(item =>
                                <div className={'credentials__item cell__icon--right' + (prequalifier.prequalifier_subtype === 'CREDIT' ? ' creditbased' : '')} key={item.id}>
                                    <div className='credentials__item_text'>
                                        <div className='text--xs-medium'>{item.credential_name}</div>
                                        <div className='text--xs-regular'>{item.issuer_name}</div>
                                    </div>
                                    {prequalifier.prequalifier_subtype === 'CREDIT' &&
                                        <div className='credential__item__value'>
                                            <INPUT
                                                property='credential.preapprovals.credit_value'
                                                value={item.credit_value === null ? '' : String(item.credit_value)}
                                                onChange={(e, { value }) => onChange_credential_credit_value(item.id, value)}
                                                placeholder='1'
                                                disabled={prequalifier.credential.save_credit_value === 'NO'}
                                            />
                                        </div>
                                    }
                                    <Icon name='delete' className='color--primary-500' onClick={() => onDelete_credential(item.id)}/>
                                </div>
                            )}
                        </div>
                        {var_errors.some(item => item.property === 'credential.preapprovals') &&
                            <div className='field__feedback error'>
                                <div className='description text--sm-regular'>{var_errors.find(item => item.property === 'credential.preapprovals').description}</div>
                            </div>
                        }
                    </div>
                </div>

                {prequalifier.prequalifier_subtype === 'CREDIT' &&
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'></div>
                        <div className='field detailsgroup__value'>
                            <CHECKBOX
                                property='credential.save_credit_value'
                                label={
                                    <label>
                                        <span className='text--sm-bold'>{t('Save credit values')}: </span>
                                        <span>{t('Future changes to a credential’s credit value by the issuer will not automatically update the values specified here.')}</span>
                                    </label>
                                }
                                checked={prequalifier.credential.save_credit_value === 'YES'}
                                onChange={onChange_save_credit_value}
                            />
                        </div>
                    </div>
                }

                {prequalifier.prequalifier_subtype !== 'CREDIT' &&
                    <FORM_TOGGLE
                        label={t('Other credentials')}
                        description={t('Allow submission of credentials outside of the approved list. This will require an administrator to manually verify and approve each submission.')}
                        value={prequalifier.prequalifier_subtype}
                        options={[{ value: 'OPEN LIST', text: t('Yes') }, { value: 'SET LIST', text: t('No') }]}
                        onChange={value => onChange_input(null, { name: 'prequalifier_subtype', value })}
                        disabled={!!prequalifier.id}
                    />
                }

                {prequalifier.prequalifier_subtype === 'OPEN LIST' &&
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>{t('Rejected credentials')}</div>
                        <div className='field detailsgroup__value'>
                            <div className='detailsgroup__description text--sm-regular'>
                                {t('Add credentials that are not approved for this pre-qualification. These credentials will be automatically rejected by the system.')}
                            </div>
                            <div className='dropzone__fileupload credentials__wrapper'>
                                <Icon name='credential2' className='icon__upload' />
                                <div>
                                    <Button id='btn_addcredential' type='button' className='tertiary button__browse' onClick={() => onClick_addcredential('INVALID')}>
                                        {t('Add rejected credentials')}
                                    </Button>
                                </div>

                                {prequalifier.credential.preapprovals.filter(item => item.status === 'INVALID').map(item =>
                                    <div className='credentials__item cell__icon--right' key={item.id}>
                                        <div className='credentials__item_text'>
                                            <div className='text--xs-medium'>{item.credential_name}</div>
                                            <div className='text--xs-regular'>{item.issuer_name}</div>
                                        </div>
                                        <Icon name='delete' className='color--primary-500' onClick={() => onDelete_credential(item.id)}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }

                <CMP_CONFIRMATION
                    display={var_deletecredential_open}
                    title={t('Remove credential')}
                    message={
                        <div style={{flexDirection: 'column'}}>
                            <div style={{marginBottom: '0.5rem'}}>{t('Are you sure you want to remove this credential.')}</div>
                        </div>
                    }
                    positive_option={t('Remove')}
                    negative_option={t('Cancel')}
                    onConfirm={onConfirm_delete}
                    onCancel={() => set_deletecredential_open(false)}
                />

                <MDL_ADD_CREDENTIAL
                    display={var_addcredential_open}
                    prequalifier={prequalifier}
                    status={var_addcredential_status}
                    onCredentialAdded={onCredentialAdded}
                    onClose={() => set_addcredential_open(false)}
                />
            </div>
        );
    }

    function render_document() {
        return (
            <div className='content__wrapper content_document'>

                <div className='content__description text--sm-medium'>{t('This pre-qualification enables verification that an individual has read and accepted the contents of a document.')}</div>

                {render_name_description()}

                <FORM_FILEUPLOAD
                    label={t('Document')}
                    property='document.filename'
                    onChange={onChange_file}
                    onChange_upload={onChange_fileselect}
                    single_or_multiple='SINGLE'
                    var_fileupload_status={prequalifier.document.fileupload_status}
                    set_fileupload_status={onChange_fileupload_status}
                    uploaddirectory='prequalifier2document'
                    allowed_fileextensions={['pdf']}
                    instructions={t('Only PDF file type is accepted')}
                    errors={var_errors}
                />

            </div>
        );
    }

    function render_survey() {
        return (
            <div className='content__wrapper content_survey'>

                <div className='content__description text--sm-medium'>{t('This pre-qualification enables completion of a survey by an individual.')}</div>

                {render_name_description()}

                <FORM_SELECT
                    label={t('Survey')}
                    property='survey.survey_id'
                    value={prequalifier.survey.survey_id}
                    placeholder={t('Select')}
                    options={var_select_options}
                    description={t('Choose a survey for the user to complete.')}
                    onChange={onChange_input}
                    errors={var_errors}
                />

                <FORM_INPUT
                    label={t('Title')}
                    property='survey.title'
                    value={prequalifier.survey.title}
                    placeholder={t('Title')}
                    description={t('The title of the modal window displaying the survey.')}
                    onChange={onChange_input}
                    maxLength={200}
                    errors={var_errors}
                />

            </div>
        );
    }

    function render_verifiable_presentation() {
        return (
            <div className='content__wrapper content_verifiable_presentation'>

                <div className='content__description text--sm-medium'>{t('This pre-qualification enables the presentation by an individual of verifiable credentials.')}</div>

                {render_name_description()}

                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Verification template')}</div>
                    <div className='field detailsgroup__value'>
                        <div className='detailsgroup__description text--sm-regular'>
                            {t('A verification template is a structured set of criteria used to define the requirements for verifying a verifiable credential. It is presented as a webpage with a QR code, allowing users to scan and present their credential. ')}
                        </div>
                        {prequalifier.verifiable_presentation.has_verification_template_feature &&
                            <TOGGLE
                                property='verifiable_presentation.template_type'
                                options={[{ value: 'LIST', text: t('Select from list') }, { value: 'URL', text: t('Enter URL') }]}
                                value={prequalifier.verifiable_presentation.template_type}
                                onChange={value => onChange_input(null, { name: 'verifiable_presentation.template_type', value })}
                            />
                        }
                        <div className='template_container'>
                            {prequalifier.verifiable_presentation.template_type === 'LIST'
                                ? <>
                                    <div className='text--sm-regular inline'>
                                        {t('Select a verification template from a list of those you’ve previously created. Don’t have one?') + ' '}
                                        <button className='tertiary inline'>{t('Create a verification template now.')}</button>
                                    </div>
                                    <SELECT
                                        property='verifiable_presentation.verification_template_id'
                                        value={prequalifier.verifiable_presentation.verification_template_id}
                                        placeholder={t('Select')}
                                        options={var_select_options}
                                        onChange={onChange_input}
                                        errors={var_errors}
                                    />
                                </>
                                : <>
                                    <div className='text--sm-regular'>{t('Provide the verification template URL.')}</div>
                                    <INPUT
                                        property='verifiable_presentation.verification_template_url'
                                        value={prequalifier.verifiable_presentation.verification_template_url}
                                        placeholder='https://www.url.com'
                                        onChange={onChange_input}
                                        errors={var_errors}
                                    />
                                </>}
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    function render_name_description() {
        return (
            <>
                <FORM_INPUT
                    property='name'
                    label={t('Name')}
                    value={prequalifier.name}
                    onChange={onChange_input}
                    placeholder={t('Pre-qualification name')}
                    description={t('Used for internal reference within the system.')}
                    maxLength={100}
                    errors={var_errors}
                />
                
                <FORM_TEXTAREA
                    property='description'
                    label={t('Description')}
                    value={prequalifier.description}
                    onChange={onChange_input}
                    placeholder={t('Description')}
                    maxLength={200}
                    disabled={false}
                    errors={var_errors}
                    description={t('The description is displayed to the user upon claiming the requirement. It serves as a guide, providing context and clear instructions on how to fulfill the qualifying requirement successfully.')}
                />
            </>
        );
    }
}

CMP_EDIT_PREQUALIFIER.propTypes = {
    prequalifier: propTypes.object.isRequired,
    validate: propTypes.bool.isRequired,
    onChange: propTypes.func.isRequired,
    onValidate: propTypes.func.isRequired
};

export default CMP_EDIT_PREQUALIFIER;
