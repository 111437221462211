import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { Button, Form, Message } from 'semantic-ui-react';
import { FORM_DATEPICKER, INPUT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'i18n';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';

import MDL_ADD_INDIVIDUALS from './mdl_add_individuals/mdl_add_individuals';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import './crd_batch_credits.css';


function CRD_BATCH_CREDITS({ credential_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_credit_task_list, set_credit_task_list ] = useState([]);
    const [ var_activity_date, set_activity_date ] = useState(null);
    const [ var_selected_individual_credentials, set_selected_individual_credentials ] = useState([]);
    const [ var_data, set_data ] = useState([]);
    const [ var_table_data, set_table_data ] = useState([]);
    const [ var_add_individuals_open, set_add_individuals_open ] = useState(false);
    const [ var_remove_individual_credential_id, set_remove_individual_credential_id ] = useState(null);
    const [ var_confirmation_type, set_confirmation_type ] = useState(null);
    const [ var_confirmation_open, set_confirmation_open ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_active_cell, set_active_cell ] = useState(null);
    const [ var_focus_element, set_focus_element ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_credit_task_list()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // keyboard navigation
        let onKeyDown = e => {
            let move_active = (e, vertical_direction, horizontal_direction) => {
                if (!var_active_cell) return;

                e.preventDefault();
                let active_cell = document.querySelector(`#crd_batch_credits #batch_credit_entry #${var_active_cell}`);
                let active_row = active_cell.closest('tr');
                let table_body = active_row.closest('tbody');
                let cell_index = active_cell.cellIndex;
                let row_index = active_row.rowIndex - 1;
                if (vertical_direction !== 0) {
                    row_index += vertical_direction;
                    while (row_index >= 0 && row_index < table_body.rows.length) {
                        let active_row = table_body.rows.item(row_index);
                        if (active_row.querySelector('input')) {
                            let new_active_cell = active_row.cells.item(cell_index);
                            scroll_cell_into_view(new_active_cell);
                            set_active_cell(new_active_cell.id);
                            return;
                        }
                        row_index += vertical_direction;
                    }
                } else {
                    cell_index += horizontal_direction;
                    if (cell_index === 0 || cell_index === active_row.cells.length) return;
                    let new_active_cell = active_row.cells.item(cell_index);
                    scroll_cell_into_view(new_active_cell);
                    set_active_cell(new_active_cell.id);
                }
            }

            switch (e.key) {
                case 'Enter':
                    let active_input = document.querySelector('#crd_batch_credits #batch_credit_entry input:focus');
                    if (active_input) {
                        e.preventDefault();
                        active_input.blur();
                        set_active_cell(active_input.closest('td').id);
                    } else if (var_active_cell) {
                        document.querySelector(`#crd_batch_credits #batch_credit_entry #${var_active_cell} input`).focus();
                        e.preventDefault();
                        set_active_cell(null);
                    }
                    break;
                case 'ArrowUp':
                    move_active(e, -1, 0);
                    break;
                case 'ArrowRight':
                    move_active(e, 0, 1);
                    break;
                case 'ArrowDown':
                    move_active(e, 1, 0);
                    break;
                case 'ArrowLeft':
                    move_active(e, 0, -1);
                    break;
                default:
                    break;
            }
        };
        document.addEventListener('keydown', onKeyDown);

        let onFocusIn = e => {
            if (var_active_cell && e.target.id !== 'batch_credit_entry') {
                set_active_cell(null);
            }
        };
        document.addEventListener('focusin', onFocusIn);

        /**
         * @param {Element} cell 
         */
        function scroll_cell_into_view(cell) {
            // Reset any manual user scrolling on the table
            cell.closest('div.lock-header').scrollTo(0, 0);
            cell.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
        }

        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('focusin', onFocusIn);
        }
    }, [ var_active_cell ]);

    useEffect(() => {
        if (!var_add_individuals_open && !var_confirmation_open && !!var_focus_element) {
            var_focus_element.focus();
        }
    }, [ var_add_individuals_open, var_confirmation_open, var_focus_element ]);


    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_credit_task_list() {
        set_processing(true);
        try {
            let results = await API_get_credential_credit_list();
            let task_list = [];
            let add_tasks_to_list = (parent_id) => {
                let tasks = results.filter(item => item.parent_credential_credit_id === parent_id).sort((a, b) => a.sort_order - b.sort_order);
                for (let task of tasks) {
                    task_list.push(task);
                    add_tasks_to_list(task.id);
                }
            }
            add_tasks_to_list(null);
            if (results.filter(item => item.allow_credit_entry === 'YES').length > 1) {
                task_list.push({ type: 'total' });
            }
            set_credit_task_list(task_list);
            build_table_data(task_list);
        } catch (e) {
            set_errors([{ description: t('There was a problem fetching data.  Please try again later') }]);
        }
        set_processing(false);
    }

    async function save_batch_credits(credit_activity_entries) {
        set_processing(true);
        try {
            await API_post_credential_credit_activities_batch(credit_activity_entries);
            navigate(`/org/credentials/${credential_id}/holders?action=batchcreditcomplete`);
        } catch (e) {
            set_errors([{ description: t('There was a problem saving.  Please try again later.') }]);
            set_processing(false);
        }
    }

    function build_table_data(credit_task_list, selected_individual_credentials, data) {
        credit_task_list = credit_task_list || var_credit_task_list;
        selected_individual_credentials = selected_individual_credentials || var_selected_individual_credentials;
        data = data || var_data;

        let table_data = [];
        let row;
        for (let task of credit_task_list) {
            row = { type: task.type, allow_credit_entry: task.allow_credit_entry, task_id: task.id, task_description: task.description };
            if (task.type === 'total') {
                for (let index = 0; index < selected_individual_credentials.length; index++) {
                    let individual_credential = selected_individual_credentials[index];
                    let total = data.find(item => item.individual_credential_id === individual_credential.individual_credential_id).activities.reduce((total, { credits }) => total + ([null, undefined, ''].includes(credits) ? 0 : Number(credits)), 0);
                    row[`credits_${index}`] = total.toFixed(2).toString();
                }
            } else {
                for (let index = 0; index < selected_individual_credentials.length; index++) {
                    let individual_credential = selected_individual_credentials[index];
                    let credits = data.find(item => item.individual_credential_id === individual_credential.individual_credential_id).activities.find(item => item.task_id === task.id)?.credits ?? '';
                    row[`credits_${index}`] = credits;
                }
            }
            table_data.push(row);
        }
        set_table_data(table_data);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_credit_list() {
        return API.get('credentials', `/get-credential-credit-list/${credential_id}`);
    }

    function API_post_credential_credit_activities_batch(credit_activity_entries) {
        return API.post('credentials', '/post-credential-credit-activities-batch/', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: credit_activity_entries
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_activity_date(e, { value }) {
        set_activity_date(value);
        set_errors(var_errors.filter(item => ![undefined, 'activity_date'].includes(item.property)));
    }

    async function onClick_add_individuals() {
        set_add_individuals_open(true);
        set_focus_element(document.activeElement);
        set_errors(var_errors.filter(item => !!item.property));
    }

    function onChange_add_individuals(new_individuals) {
        let individuals = var_selected_individual_credentials.map(item => ({ ...item })).concat(new_individuals);
        set_selected_individual_credentials(individuals);
        let data = _.cloneDeep(var_data);
        data = data.concat(new_individuals.map(item => ({ individual_credential_id: item.individual_credential_id, activities: [] })));
        set_data(data);
        build_table_data(null, individuals, data);
        set_add_individuals_open(false);
    }

    function onClick_remove_individual(individual_credential_id) {
        set_remove_individual_credential_id(individual_credential_id);
        set_confirmation_type('remove individual');
        set_confirmation_open(true);
        set_focus_element(document.activeElement);
    }

    function onChange_credit(task_id, individual_credential_id, value) {
        value = form_helper.format_decimal_string(value, 4, 2);
        let data = _.cloneDeep(var_data);
        let individual_activities = data.find(item => item.individual_credential_id === individual_credential_id).activities;
        if (individual_activities.some(item => item.task_id === task_id)) {
            individual_activities.find(item => item.task_id === task_id).credits = value;
        } else {
            individual_activities.push({ task_id, credits: value });
        }
        set_data(data);
        build_table_data(null, null, data);
        set_errors(var_errors.filter(item => !!item.property));
    }

    function onBlur_credit(task_id, individual_credential_id) {
        let data = _.cloneDeep(var_data);
        let individual_activities = data.find(item => item.individual_credential_id === individual_credential_id).activities;
        if (individual_activities.some(item => item.task_id === task_id)) {
            individual_activities.find(item => item.task_id === task_id).credits = form_helper.pad_decimal_string(individual_activities.find(item => item.task_id === task_id).credits, 2);;
        }
        set_data(data);
        build_table_data(null, null, data);
        // as user may spend several minutes filling out credits, reset timer
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        auth.iam.get_access_token();
    }

    function onClick_save() {
        if (var_processing) return;

        // validation
        let errors = [];
        if (!var_activity_date) {
            errors.push({ property: 'activity_date', description: t('Date is required.' )});
        }
        if (var_data.length === 0) {
            errors.push({ description: t('Individuals and credits must be added before saving.') });
        } else if (var_data.every(item => item.activities.every(activity => [null, undefined, 0, '', '0.00'].includes(activity.credits)))) {
            errors.push({ description: t('No tasks have assigned credit values. Please add credits before saving.') });
        }
        set_errors(errors);
        if (errors.length > 0) {
            window.scrollTo(0, 0);
            return;
        }

        // validation passed, transform the data (remove empty entries)
        let save_data = {
            activity_date: datelib.localdate_to_utc_midnight_epoch(var_activity_date),
            individual_credentials: _.cloneDeep(var_data)
        };
        for (let individual_credential_index = 0; individual_credential_index < save_data.individual_credentials.length; individual_credential_index++) {
            let individual_credential = save_data.individual_credentials[individual_credential_index];
            if (individual_credential.activities.every(item => [null, undefined, 0, '', '0.00'].includes(item.credits))) {
                save_data.individual_credentials.splice(individual_credential_index, 1);
                individual_credential_index--;
            } else {
                for (let activity_index = 0; activity_index < individual_credential.activities.length; activity_index++) {
                    if ([null, undefined, 0, '', '0.00'].includes(individual_credential.activities[activity_index].credits)) {
                        individual_credential.activities.splice(activity_index, 1);
                        activity_index--;
                    } else {
                        individual_credential.activities[activity_index].credits = Number(individual_credential.activities[activity_index].credits);
                    }
                }
            }
        }

        // save now
        save_batch_credits(save_data);
    }

    function onClick_cancel() {
        // check if any data has been entered, if so confirm with the user otherwise just navigate back to the holders page
        if (var_data.some(item => item.activities.some(activity => ![null, undefined, 0, '', '0.00'].includes(activity.credits)))) {
            set_confirmation_type('cancel');
            set_confirmation_open(true);
            set_focus_element(document.activeElement);
        } else {
            navigate(`/org/credentials/${credential_id}/holders`);
        }
    }

    function onConfirm() {
        if (var_confirmation_type === 'cancel') {
            // navigate back to the holders page
            navigate(`/org/credentials/${credential_id}/holders`);
        } else { // remove individual
            let individual_credential_id = var_remove_individual_credential_id;
            let selected_individual_credentials = _.cloneDeep(var_selected_individual_credentials).filter(item => item.individual_credential_id !== individual_credential_id);
            let data = _.cloneDeep(var_data).filter(item => item.individual_credential_id !== individual_credential_id);
            set_selected_individual_credentials(selected_individual_credentials);
            set_data(data);
            build_table_data(null, selected_individual_credentials, data);
            set_confirmation_open(false);
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_batch_credits' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Batch credits')}</div>
                    </div>
                </div>

                {var_errors.some(item => item.property === undefined) &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors.find(item => item.property === undefined).description} />
                }

                <Form className='modal__content' id='form_batch_credits' aria-labelledby='hdr_batch_credits' tabIndex='0'>
                    <div className='activity_date_container'>
                        <div>
                            <FORM_DATEPICKER
                                property='activity_date'
                                label={t('Date')}
                                value={var_activity_date || ''}
                                onChange={onChange_activity_date}
                                placeholder={t('YYYY-MM-DD')}
                                errors={var_errors}
                            />
                        </div>
                        <div>
                            <Button id='btn_add_individuals' className='secondary' onClick={onClick_add_individuals}>{t('Add individuals')}</Button>
                        </div>
                    </div>

                    <Table id='batch_credit_entry' loading={false} ready={true} loadingerror={false} lockcolumns={1} refresh={false} totalrows={var_credit_task_list.length} lockheader={true}
                        populateconfig={{ ...default_populateconfig, sortby: 'na' }} populatefilterfunction={() => {}}
                        downloadname={''} downloadfunction={() => {}}
                        onChange={() => {}} hide_tablecontrols={true} tabIndex='0'
                        >

                        <Table.Header>
                            <Table.Row>
                                {(() => {
                                    let header_cells = [<Table.HeaderCell key='header_task' field='task'>{t('TASK')}</Table.HeaderCell>];
                                    var_selected_individual_credentials.forEach(item => {
                                        header_cells.push(<Table.HeaderCell key={`header_${item.individual_credential_id}`} field={'field_' + item.individual_credential_id.replace(/-/g, '')}>
                                            <span>{item.individual_name.toUpperCase()}</span>
                                            <Button type='button' onClick={() => onClick_remove_individual(item.individual_credential_id)}>
                                                <Icon name='xicon_nocircle' className='remove_individual_icon' />
                                            </Button>
                                        </Table.HeaderCell>);
                                    });
                                    return header_cells;
                                })()}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {var_table_data.map(item =>
                                <Table.Row key={`row_${item.task_id}`} className={item.type === 'total' ? 'row_total' : item.allow_credit_entry === 'YES' ? 'row_allow_credit_entry' : 'row_no_credit_entry'}>
                                    {(() => {
                                        let cells = [<Table.Cell key={`cell_task_${item.id}`}>{item.type === 'total' ? t('Total') : item.task_description}</Table.Cell>];
                                        for (let index = 0;index < var_selected_individual_credentials.length; index++) {
                                            if (item.type === 'total') {
                                                cells.push(<Table.Cell key={`total_${index}`}>{item[`credits_${index}`]}</Table.Cell>);
                                            } else if (item.allow_credit_entry === 'YES') {
                                                let id = `cell_${item.task_id.replace(/-/g, '')}_${index}`;
                                                cells.push(<Table.Cell key={id} id={id} className={var_active_cell === id ? 'active' : undefined}>
                                                    <INPUT
                                                        property={`cell_${item.task_id}_${index}`}
                                                        value={item[`credits_${index}`]}
                                                        onChange={(e, { value }) => onChange_credit(item.task_id, var_selected_individual_credentials[index].individual_credential_id, value)}
                                                        onBlur={() => onBlur_credit(item.task_id, var_selected_individual_credentials[index].individual_credential_id)}
                                                    />
                                                </Table.Cell>);
                                            } else {
                                                cells.push(<Table.Cell key={`cell_${item.task_id}_${index}`} />);
                                            }
                                        }

                                        return cells;
                                    })()}
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>

                    <div className='card__header__left footer__btns'>
                        <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                    </div>
                </Form>

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>

            <MDL_ADD_INDIVIDUALS
                is_open={var_add_individuals_open}
                onClose={() => set_add_individuals_open(false)}
                credential_id={credential_id}
                existing_individuals={var_selected_individual_credentials.map(item => item.individual_credential_id)}
                onChange={onChange_add_individuals}
            />

            <CMP_CONFIRMATION
                display={var_confirmation_open}
                title={var_confirmation_type === 'cancel' ? t('Discard data') : t('Remove individual and credits')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{var_confirmation_type === 'cancel' ? t('Are you sure you want to discard all entered data?') : t('Removing this individual will permanently delete them and any associated inputted credits.')}</div>
                        <div>{t('This action cannot be undone.')}</div>
                    </div>}
                positive_option={var_confirmation_type === 'cancel' ? t('Discard') : t('Remove')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm}
                onCancel={() => set_confirmation_open(false)}
            />
        </>
    );
}

CRD_BATCH_CREDITS.propTypes = {
    credential_id: propTypes.string.isRequired
};

export default CRD_BATCH_CREDITS;