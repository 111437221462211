import React, { useEffect } from 'react';

import CRD_INDIVIDUALS from './crd_individuals/crd_individuals';



export default function TAB_INDIVIDUALS({ requirement }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <CRD_INDIVIDUALS requirement={requirement} />
    );
};
