import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TAB_DETAILS from './tab_details/tab_details';
import TAB_PREQUALIFIERS from './tab_prequalifiers/tab_prequalifiers';
import TAB_ACTIVITY from './tab_activity/tab_activity';
import TAB_CREDENTIAL_SHARING from './tab_sharing/tab_credential_sharing';
import TAB_ACCESS from './tab_access/tab_access';
import TAB_CREDIT_DETAILS from './tab_credit_details/tab_credit_details';
import Icon from 'components/cmp_icon';
import TABS from 'components/cmp_tabs/cmp_tabs';
import CMP_UNLOCK_WALLET from 'components/cmp_unlock_wallet/cmp_unlock_wallet';
import CMP_CREDENTIAL_CARD from 'components/cmp_credential_card/cmp_credential_card';


export default function CREDENTIAL(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_credential, set_credential ] = useState({});
    const [ var_verifiable_credential, set_verifiable_credential ] = useState({});

    const [ var_unlock_wallet_open, set_unlock_wallet_open ] = useState(false);

    const category = params.category.toUpperCase();


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!Window.$is_unlocked) {
            Window.$is_unlocked = (!auth.has_orgaccess('INDV-VCWALLET', 'wallet') || !auth.passphrase_parameters);
        }
        set_unlock_wallet_open(!Window.$is_unlocked);
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        } else {
            set_tab('DETAILS');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.individualcredentialid]);

    useEffect(() => {
        if (Window.$is_unlocked) {
            populate_credential();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Window.$is_unlocked, params.individualcredentialid]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credential() {
        let { results, linked_vc } = await API_get_credential();
        set_credential(results);
        set_verifiable_credential(linked_vc);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential() {
        return API.get('credentials', '/get-credential/' + params.individualcredentialid, { queryStringParameters: { tz: datelib.timezone }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    function onClose_unlock_wallet() {
        set_unlock_wallet_open(false);
        if (!Window.$is_unlocked) {
            onClick_back();
        }
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='assignments'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: '/credentials/all', text: t('Credentials') }, ...category !== 'ALL' ? [{ path: '/credentials/' + category, text: t(category) }] : []]}
                current_page_text={var_credential.credential_name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header'>
                    <h1 className='display--sm-regular'>{var_credential.credential_name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                    {var_credential.id &&
                        <CMP_CREDENTIAL_CARD
                            credential={{
                                ...var_credential,
                                background_color: var_credential.card_background_color,
                                text_color: var_credential.card_text_color,
                                logo: var_credential.card_logo
                            }}
                        />
                    }
                </div>

                {var_credential.verification_status === 'INVALID' && var_credential.confirmation_status !== 'EXPIRED' &&
                    <Message
                        error
                        icon={<Icon name='error' className='icon' />}
                        header={t('This credential has been rejected') + (var_credential.comment ? `: ${var_credential.comment}` : '')}
                    />
                }
                <TABS activetab={var_tab} details_card={true} onClick={onClick_tab}>
                    <TABS.TAB name='DETAILS' label={t('Details')} />
                    <TABS.TAB name='CREDITDETAILS' label={t('Credit details')} display={![undefined, null].includes(var_credential.credits_required)} />
                    <TABS.TAB name='PREQUALIFIERS' label={t('Pre-qualifications')} display={![undefined, 'N/A'].includes(var_credential.prequalifier_status)} />
                    <TABS.TAB name='SHARING' label={t('Sharing')} />
                    <TABS.TAB name='ACTIVITY' label={t('Activity')} />
                    <TABS.TAB name='ACCESS' label={t('Access')} />
                </TABS>

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS
                        credential={var_credential}
                        onChange={populate_credential}
                        activetab={var_tab}
                        verifiable_credential={var_verifiable_credential}
                        onChange_tab={set_tab}
                />
                }

                {var_tab === 'CREDITDETAILS' &&
                    <TAB_CREDIT_DETAILS credential={var_credential} onChange={populate_credential} activetab={var_tab} />
                }

                {var_tab === 'PREQUALIFIERS' &&
                    <TAB_PREQUALIFIERS credential={var_credential} onChange={populate_credential} activetab={var_tab} />
                }

                {var_tab === 'SHARING' &&
                    <TAB_CREDENTIAL_SHARING credential={var_credential} activetab={var_tab} />
                }

                {var_tab === 'ACTIVITY' &&
                    <TAB_ACTIVITY credential={var_credential} activetab={var_tab} />
                }

                {var_tab === 'ACCESS' &&
                    <TAB_ACCESS credential={var_credential} activetab={var_tab} />
                }

                <CMP_UNLOCK_WALLET
                    display={var_unlock_wallet_open}
                    reset_authentication={appProps.reset_authentication}
                    onClose={onClose_unlock_wallet}
                />
            </div>
        </div>
    )
};