import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import 'i18n';

import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';


function MDL_ASSIGN_REQUIREMENT({ display, onClose, requirement_name, requirement_id, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_selected_individuals, set_selected_individuals ] = useState([]);
    const [ var_error, set_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display && requirement_id) {
            // reset and populate options
            set_focuselement(document.activeElement);
            set_modal(document.querySelector('#mdl_add_individual'));
            set_individuals([]);
            set_selected_individuals([]);
            set_error(null);
            set_processing(false);
            populate_individuals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, requirement_id]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals() {
        try {
            let individuals = await API_get_org_available_requirement_individuals();
            let transformed = individuals.map(item => ({
                value: item.id,
                primary_text:
                            <CMP_VERIFIED_IDENTITY_DISPLAY
                                individual_name={item.individual_name}
                                verified_identity_credential_id={item.identity_verification_credential_id}
                                display_nonclickable_icon={true}
                            />,
                secondary_text: item.email,
                disabled: item.has_requirement === 'YES'
            }));
            set_individuals(transformed);
        } catch (e) {
            console.log(e);
            set_error(t('There was a problem loading.  Please try again later'));
        }
    }

    async function assign_requirement() {
        if (var_processing) return;

        set_error(null);
        // validation
        if (var_selected_individuals.length === 0) {
            set_error(t('Please select at least one individual to add'));
            return;
        }
        set_processing(true);
        try {
            await API_post_requirement_to_org_individuals();
            onChange && onChange(var_focuselement?.id);
        } catch (e) {
            set_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_available_requirement_individuals() {
        return API.get('requirements2', '/get-org-available-requirement-individuals/' + requirement_id);
    }

    function API_post_requirement_to_org_individuals() {
        return API.post('requirements2', '/post-requirement-to-org-individuals', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                requirement_id: requirement_id,
                individual_ids: var_selected_individuals
            }});
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_individuals(value) {
        set_error(null);
        set_selected_individuals(value);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_individual'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_requirements_addindiv'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_requirements_addindiv'>{t('Add individual')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_requirements_addindiv' aria-labelledby='hdr_requirements_addindiv' tabIndex='0'>
                {var_error &&
                    <Message error
                        header={var_error}
                    />
                }
                <FORM_SELECT_RADIO
                    name='holders'
                    property='holders'
                    label={t('Individuals')}
                    value={var_selected_individuals}
                    options={var_individuals}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_individuals}
                    placeholder={t('Search')}
                    errors={[]}
                    aria_label={t('Select the individuals you want to add')}
                />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={assign_requirement} disabled={var_individuals.length === 0}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_ASSIGN_REQUIREMENT;