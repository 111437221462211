import React, { useEffect, useRef, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { FORM_FILEUPLOAD, DATEPICKER } from 'components/cmp_form/cmp_form';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './mdl_credit_activity_upload_document.css';

import CMP_CREDIT_ACTIVITY_LIST from 'components/cmp_credit_activity_list/cmp_credit_activity_list';


export default function MDL_CREDIT_ACTIVITY_UPLOAD_DOCUMENT({ is_open, holder_name, onClose, onChange, individual_credential_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_credit_activity_entries, set_credit_activity_entries ] = useState([]);
    const [ var_credit_total, set_credit_total ] = useState(0);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('UPLOAD');
    const [ var_processing, set_processing ] = useState(false);
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_base64_document_url, set_base64_document_url ] = useState('');
    const [ var_api_error, set_api_error ] = useState(null);
    const [ var_activity_date, set_activity_date ] = useState(null);

    const modal_header_ref = useRef();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal, var_mode]);

    useEffect(() => {
        if (is_open) {
            // reset
            set_mode('UPLOAD');
            set_modal(document.querySelector('#mdl_upload'));
            set_fileupload_status(null);
            set_processing(false);
            set_api_error(null);
            set_activity_date(null);
            set_credit_activity_entries([]);
            set_credit_total(0);
            set_save_error(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        const initial_value = 0;
        let total_credits = var_credit_activity_entries.reduce((accumulator, current_value) => accumulator + Number(current_value?.credits ?? 0), initial_value);
        set_credit_total(total_credits);
    }, [var_credit_activity_entries])

    useEffect(()=> {
        var_save_error && modal_header_ref?.current.scrollIntoView();
    },[var_save_error])



    //  async functions ------------------------------------------------------------------------------------------------

    async function upload_document() {
        set_processing(true);
        try {
            let results = await API_post_ocr_document();
            results.forEach(item => item.credits = form_helper.pad_decimal_string(item.credits, 2));
            set_credit_activity_entries(results);
            set_mode('VERIFY');
        } catch (e) {
            console.log(e);
            set_api_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }

    async function save_details(activities) {
        if (var_processing) return;
        set_processing(true);
        document.getElementById('mdl_upload').scrollTo(0, 0);
        try {
            await API_post_credit_activity_entries(activities);
            onChange();
        } catch (e) {
            console.log(e);
            set_api_error(t('An error has occurred'));
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_ocr_document() {
        return API.post('integrations', '/post-ocr-document/' + individual_credential_id,
            {
                body: { base64_document_url: var_base64_document_url }
            });
    }

    function API_post_credit_activity_entries(activities) {
        return API.post('credentials', '/post-credential-credit-activities/',
            {
                queryStringParameters: { tz: datelib.timezone },
                body: { individual_credential_id, activities }
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_save() {
        if (!var_credit_total > 0) {
            set_save_error(t('No tasks have assigned credit values. Please enter credit values to save.'));
            return;
        }

        let credit_activity_entries = _.cloneDeep(var_credit_activity_entries);

        if (credit_activity_entries.some(item => item.credits && !item.activity_date)) {
            set_save_error(t('Please select a date for all tasks with credit values.'));
            return;
        }

        for (let credit_activity of credit_activity_entries) {
            credit_activity.activity_date = credit_activity.activity_date ? datelib.localdate_to_utc_midnight_epoch(credit_activity.activity_date) : null;
        }

        save_details(credit_activity_entries);
    }

    function onClick_upload() {
        upload_document();
    }

    async function onChange_upload(files) {
        let file = files[0];
        let base64_document_url = await new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
        set_base64_document_url(base64_document_url);
    }

    function onChange_activity(activity) {
        if (!activity) return;

        set_save_error(null);
        const updated_activities = var_credit_activity_entries.map(item => item.id === activity.id ? activity : item);
        set_credit_activity_entries(updated_activities);
    }

    function onChange_date(value) {
        set_save_error(null);
        set_activity_date(value);

        let updated_activities = _.cloneDeep(var_credit_activity_entries);
        for (let credit_activity of updated_activities) {
            credit_activity.activity_date = value;
        }
        set_credit_activity_entries(updated_activities);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_upload'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_upload'
        >

            <div className='modal__header' ref={modal_header_ref}>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_upload'>{var_mode === 'UPLOAD' ? t('Upload document') : holder_name}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_upload' aria-labelledby='hdr_upload' tabIndex='0'>

                {var_api_error &&
                    <Message error header={var_api_error} />
                }

                {var_save_error &&
                    <Message
                        error
                        icon={<Icon name='error' className='icon' />}
                        header={var_save_error}
                    />
                }


                {var_mode === 'UPLOAD' ?
                    <FORM_FILEUPLOAD
                        label={t('Document')}
                        property='document'
                        onChange_upload={onChange_upload}
                        single_or_multiple='SINGLE'
                        var_fileupload_status={var_fileupload_status}
                        set_fileupload_status={set_fileupload_status}
                        uploaddirectory='credit_activity'
                        allowed_fileextensions={['pdf', 'png', 'jpg', 'jpeg']}
                        instructions={t('Only PDF, JPG, and PNG file types are accepted.')}
                        errors={[]}
                    />

                    :
                    <>
                        {var_credit_activity_entries.filter(item => item.allow_credit_entry === 'YES').length > 1 &&
                            <div className='date_wrapper'>
                                <div className='date_label text--sm-medium'>{t('Default date')}</div>
                                <DATEPICKER
                                    property='activity_date'
                                    value={var_activity_date || ''}
                                    onChange={(_e, {value}) => onChange_date(value)}
                                    placeholder={t('YYYY-MM-DD')}
                                    errors={[]}
                                />
                            </div>
                        }
                        <CMP_CREDIT_ACTIVITY_LIST activities={var_credit_activity_entries} onChange_activity={onChange_activity}/>

                        <div className='trackablecredits_total_wrapper'>
                            <Icon name='warning_circle' className='icon trackablecredits_total_icon' />
                            <div className='text--md-medium'>{t('Total entered credits')}</div>
                            <div className='trackablecredits_total_value text--md-medium'>{var_credit_total.toFixed(2)}</div>
                        </div>
                    </>
}
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {var_mode === 'UPLOAD'
                        ? <Button className='primary' onClick={onClick_upload}>{t('Upload')}</Button>
                        : <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    }
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}
