import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';

import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import { DATEPICKER, SELECT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';

import './credit_tracking_by_holder.css'

export default function CREDIT_TRACKING_BY_HOLDER() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    let [ searchParams, setSearchParams ] = useSearchParams();

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(false);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name', sortorder: 'ascending' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    
    const [ var_results, set_results ] = useState([]);
    const [ var_enddate, set_enddate ] = useState(new Date());
    const [ var_errors, set_errors ] = useState([]);
    const [ var_startdate, set_startdate ] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));
    const [ var_credentials_list, set_credentials_list ] = useState([]);
    const [ var_selected_credential, set_selected_credential ] = useState(null);
    const [ var_processing, set_processing ] = useState(true);
    

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);

        if (searchParams.has('startdate')) {
            set_startdate(datelib.isodatestring_to_date(searchParams.get('startdate')));
            searchParams.delete('startdate');
            setSearchParams(searchParams);
        }

        if (searchParams.has('enddate')) {
            set_enddate(datelib.isodatestring_to_date(searchParams.get('enddate')));
            searchParams.delete('enddate');
            setSearchParams(searchParams);
        }

        if (searchParams.has('credential_id')) {
            set_selected_credential(searchParams.get('credential_id'));
            searchParams.delete('credential_id');
            setSearchParams(searchParams);
        }

        populate_credentials_list();
    }, []);

    useEffect(() => {
        let errors = var_errors.filter(item =>  !['startdate', 'enddate'].includes(item.property));;

        if (var_startdate && var_enddate && datelib.iso_to_date_string(var_enddate, true) < datelib.iso_to_date_string(var_startdate, true)) {
            errors.push({property: 'enddate', description: t(`To date cannot be before From date`)});
        }
        if (!var_startdate) {
            errors.push({property: 'startdate', description: t(`From date is required`)});
        }
        if (!var_enddate) {
            errors.push({property: 'enddate', description: t(`To date is required`)});
        }

        set_errors(errors);

        if (errors.length === 0 && var_selected_credential) {
            set_refreshtable(!var_refreshtable);
            populate_records({ ...var_populateconfig, offset: 0, filter: {} });
        } else {
            set_totalrows(0);
            set_results([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_startdate, var_enddate]);

    useEffect(() => {
        if(var_ready) {
            let errors = var_errors.filter(item => item.property !== 'credential');

            if (!var_selected_credential) {
                errors.push({property: 'credential', description: t('Credential is required')})
            }

            set_errors(errors);

            if (errors.length === 0) {
                set_refreshtable(!var_refreshtable);
                populate_records({ ...var_populateconfig, offset: 0, filter: {} });
            } else {
                set_totalrows(0);
                set_results([]);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_selected_credential ])


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, startdate = var_startdate, enddate = var_enddate, selected_credential = var_selected_credential) {
        if (var_errors.length > 0 || !selected_credential) return;

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_post_credit_tracking_by_holder(limit, offset, sortby, sortorder, filter, null, startdate, enddate, selected_credential);
            set_totalrows(results.totalrows);
            set_results(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        if (var_errors.length > 0) return [];

        try {
            return (await API_post_credit_tracking_by_holder(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername, var_startdate, var_enddate, var_selected_credential)).results;
        } catch (e) {
            console.log(e);
        }

    }

    async function download_records(limit, offset) {
        if (var_errors.length > 0) return [];

        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_post_credit_tracking_by_holder(limit, offset, sortby, sortorder, filter, null, var_startdate, var_enddate, var_selected_credential)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function populate_credentials_list() {
        set_processing(true);

        try {
            let results = await API_get_credit_tracking_credentials_list();
            set_credentials_list(results.map(item => ({ value: item.id, text: item.name })));
        } catch (e) {
            console.log(e);
        } finally {
            set_processing(false);
            set_ready(true);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_credit_tracking_by_holder(limit, offset, sortby, sortorder, filter, filtername, startdate, enddate, credential_id ) {
        return API.post('reports', `/post-credit-tracking-by-holder`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone,
                    startdate: datelib.iso_to_date_string(startdate),
                    enddate: datelib.iso_to_date_string(enddate),
                    credential_id
                },
                body: filter
            }
        );
    }

    function API_get_credit_tracking_credentials_list() {
        return API.get(`credentials`, `/get-credit-tracking-credentials-list`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onChange_credential(e, { value }) {
        set_selected_credential(value);
    }

    function onChange_enddate(e, { value }) {
        set_enddate(value);
    }

    function onChange_startdate(e, { value }) {
        set_startdate(value);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Reports') }]}
                current_page_text={t('Credit tracking by holder')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular' id='hdr_reports_credit_tracking_by_holder'>{t('Credit tracking by holder')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>
                <div className='card rounded-lg shadow-sm' id='crd_reports_credit_tracking_by_holder'>
                    <Table id='credit_tracking_by_holder' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Credit tracking by holder')} downloadfunction={download_records}
                        onChange={populate_records}>
                        <Table.OptionsButtons>
                            <div className='options_group_wrapper' role='form' id='opt_credit_tracking_by_holder' aria-labelledby='hdr_credit_tracking_by_holder'>
                                <div className='option_wrapper'>
                                    <div className='option_label text--sm-medium'>{t('From')}</div>
                                    <DATEPICKER
                                        property='startdate'
                                        label={t('From')}
                                        value={var_startdate}
                                        onChange={onChange_startdate}
                                        placeholder={t('YYYY-MM-DD')}
                                        minproperty={null}
                                        maxproperty={new Date()}
                                        errors={var_errors}
                                    />
                                </div>

                                <div className='option_wrapper'>
                                    <div className='option_label text--sm-medium'>{t('To')}</div>
                                    <DATEPICKER
                                        property='enddate'
                                        label={t('To')}
                                        value={var_enddate}
                                        onChange={onChange_enddate}
                                        placeholder={t('YYYY-MM-DD')}
                                        minproperty={null}
                                        maxproperty={new Date()}
                                        errors={var_errors}
                                    />
                                </div>

                                <div className='option_wrapper'>
                                    <div className='option_label text--sm-medium'>{t('Credential')}</div>
                                    <SELECT 
                                        property='credential'
                                        value={var_selected_credential || ''}
                                        onChange={(e, {value}) => onChange_credential(e, {value})}
                                        placeholder={t('Select')}
                                        options={var_credentials_list}
                                        errors={var_errors}
                                    />
                                </div>
                            </div>
                        </Table.OptionsButtons>

                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('HOLDER')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='total_credits' datatype='number' filtertype='number'>{t('CREDITS')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {var_results.map(item =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        <Table.CellContentTwoLine primary_text={item.individual_name} secondary_text={item.email} />
                                    </Table.Cell>
                                    <Table.Cell>
                                    <div className={`badge text--sm-medium ${
                                        item.individual_status === 'Active' ? 'badge--green'
                                        : item.individual_status === 'Inactive' ? 'badge--gray'
                                        : item.individual_status === 'Pending' ? 'badge--yellow'
                                        : (item.individual_status === 'Expired' || item.individual_status === 'Declined') ? 'badge--red'
                                        : 'badge--gray'}`}
                                    >
                                        {item.individual_status_display}
                                    </div>
                                    </Table.Cell>
                                    <Table.Cell >{item.credential_name}</Table.Cell>
                                    <Table.Cell>{form_helper.pad_decimal_string(item.total_credits, 2)}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    <Processing display={var_processing} processingtext='' />
                </div>
            </div>
        </div>
    )
};

