import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import config from 'config';

import './mdl_onboarding.css';

import CONTENT_ONBOARD_1 from './content_onboard_1/content_onboard_1';
import CONTENT_ONBOARD_2 from './content_onboard_2/content_onboard_2';
import CONTENT_ONBOARD_3 from './content_onboard_3/content_onboard_3';



export default function MDL_ONBOARDING({ display, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_content, set_content ] = useState('ONBOARD_1');
    const [ var_modal, set_modal ] = useState(null);

    const preload_images = [
        `${config.images.url}${config.images.assets.onboarding_1}${config.images.stage}`,
        `${config.images.url}${config.images.assets.onboarding_2}${config.images.stage}`,
        `${config.images.url}${config.images.assets.onboarding_3}${config.images.stage}`
    ];


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            preload_images.forEach((value) => preload_image(value));
            set_content('ONBOARD_1');
            set_modal(document.querySelector('#mdl_onboarding'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);


    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal, var_content]);


    //  async functions ------------------------------------------------------------------------------------------------

    const preload_image = (url) => {
        let img = new Image();
        img.src = url
    }

    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={false}
            id='mdl_onboarding'
            aria-modal='true'
            role='dialog'
            aria-label={t('Welcome to Credivera')}
        >

            {var_content === 'ONBOARD_1' &&
                <CONTENT_ONBOARD_1 onChangeContent={set_content} onClose={onClose} />
            }

            {var_content === 'ONBOARD_2' &&
                <CONTENT_ONBOARD_2 onChangeContent={set_content} onClose={onClose} />
            }

            {var_content === 'ONBOARD_3' &&
                <CONTENT_ONBOARD_3 onClose={onClose} />
            }

        </Modal>
    );
}