import React, { useState } from 'react';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';



export default function CRD_DETAILS({ prequalification, onChange_prequalification }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_updated_prequalification, set_updated_prequalification ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_prequalification(updated_prequalification) {
        set_processing(true);
        try {
            await API_put_prequalifier_details(updated_prequalification);
        } catch(e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
        onChange_prequalification();
        set_mode('VIEW');
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_prequalifier_details(updated_prequalification) {
        return API.put('requirements2', '/put-org-prequalifier/' + prequalification.id,
            {
                body: updated_prequalification
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_prequalification({...prequalification});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    function onClick_save() {
        if (var_processing) return;

        let updated_prequalification = {...var_updated_prequalification};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(updated_prequalification.name)) {
            errors.push({ property: 'name', description: t('Name is required') });
        }

        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_prequalification, 'name');
        form_helper.cleanse_string_property(updated_prequalification, 'description');

        set_updated_prequalification(updated_prequalification);
        update_prequalification(updated_prequalification);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_prequalification_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_prequalification_details'>{t('Pre-qualification details')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'EDIT'
                ?
                <>
                    <Form className='card__content' id='form_prequalification_details' aria-labelledby='hdr_prequalification_details'>

                        {var_save_error &&
                            <Message error header={var_save_error} />
                        }

                        {var_errors.length > 0 &&
                            <Message
                                error icon={<Icon name='error' className='icon' />}
                                header={t('There are some errors with your inputs')}
                            />
                        }

                    </Form>

                    <div className='card__footer'>
                        <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                    </div>

                </>
                :
                <>
                    <div className='card__content'>
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                </>

            }

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}