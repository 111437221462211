import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import 'i18n';

import CMP_MDL_ADD_PREQUALIFIER from 'components/cmp_mdl_add_prequalifier/cmp_mdl_add_prequalifier';

export default function CRD_PREQUALIFICATIONS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_prequalifiers, set_prequalifiers ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_mdl_add_prequalifier_open, set_mdl_add_prequalifier_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_status, set_status ] = useState('ACTIVE');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_prequalifiers({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);

    useEffect(() => {
        if (!var_mdl_add_prequalifier_open && ['btn_addprequalifier', 'btn_mobile_addprequalifier'].includes(var_focuselement?.id)) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_mdl_add_prequalifier_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_prequalifiers(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_prequalifiers(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_prequalifiers(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_prequalifiers(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_prequalifiers(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_prequalifiers(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_prequalifiers(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements2', '/get-org-prequalifiers',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone,
                    status: var_status
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_add_prequalifier() {
        set_focuselement(document.activeElement);
        set_mdl_add_prequalifier_open(true);
    }

    function onChange_add_prequalifier() {
        populate_prequalifiers();
        set_mdl_add_prequalifier_open(false);
    }

    function onClick_status(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_status(status);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_reqdetails_prequalifiers' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('All pre-qualifications')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button id='btn_addprequalifier' className='primary' onClick={onClick_add_prequalifier}>{t('Create pre-qualification')}</Button>
                </div>
            </div>

            <Table id='prequalifiers' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={t('Pre-qualifications')} downloadfunction={download_prequalifiers}
                    onChange={populate_prequalifiers}>

                <Table.OptionsButtons>
                    <TOGGLE value={var_status} onChange={onClick_status} options={[{ value: 'ACTIVE', text: t('Active') }, { value: 'ARCHVIE', text: t('Inactive') }]} />
                </Table.OptionsButtons>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('PRE-QUALIFICATION')}</Table.HeaderCell>
                        <Table.HeaderCell field='prequalifier_type_display' datatype='text' filtertype='option'>{t('TYPE')}</Table.HeaderCell>
                        <Table.HeaderCell field='last_updated' datatype='datetime' filtertype='date'>{t('LAST UPDATED')}</Table.HeaderCell>
                        <Table.HeaderCell field='requirements' datatype='number' filtertype='number'>{t('REQUIREMENTS')}</Table.HeaderCell>
                        <Table.HeaderCell field='individuals' datatype='number' filtertype='number'>{t('INDIVIDUALS')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_prequalifiers.map(item =>
                        <Table.Row key={item.id}>
                            <Table.Cell className='text--anchor'>
                                <Button className='tertiary' onClick={() => navigate(`/org/requirements2/prequalifications/${item.id}`)}>
                                    {item.name}
                                </Button>
                            </Table.Cell>
                            <Table.Cell>{item.prequalifier_type_display}</Table.Cell>
                            <Table.Cell>{datelib.epoch_to_datetime_string(item.last_updated, true)}</Table.Cell>
                            <Table.Cell className='text--anchor'>
                                <Button className='tertiary' onClick={() => navigate(`/org/requirements2/prequalifications/${item.id}/requirements`)}>
                                    {item.requirements}
                                </Button>
                            </Table.Cell>
                            <Table.Cell className='text--anchor'>
                                <Button className='tertiary' onClick={() => navigate(`/org/requirements2/prequalifications/${item.id}/individuals`)}>
                                    {item.individuals}
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <div className='card__footer--mobilebtns'>
                <Button id='btn_mobile_addprequalifier' className='primary' onClick={onClick_add_prequalifier}>{t('Create pre-qualification')}</Button>
            </div>

            <CMP_MDL_ADD_PREQUALIFIER
                display={var_mdl_add_prequalifier_open}
                onClose={() => set_mdl_add_prequalifier_open(false)}
                onChange={onChange_add_prequalifier}
            />
        </div>
    );

}
