import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Form, Modal } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { CHECKBOX, FORM_INPUT, FORM_SELECT, DATEPICKER } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';

function MDL_EDIT_QR_CODE({ display, onClose, credential_id, qr_code_id, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_qr_code, set_qr_code ] = useState(null);
    const [ var_task_options, set_task_options ] = useState([]);
    const [ var_no_expiry_date, set_no_expiry_date ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {
            let firstelement = var_modal.querySelector('.modal__content');
            let lastelement = Array.from(var_modal.querySelectorAll('button:not([disabled])')).at(-1);

            //  set focus to first element within the modal
            firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key !== 'Tab') return;

                if (e.shiftKey && document.activeElement === firstelement) /* shift + tab */ {
                    lastelement.focus();
                    e.preventDefault();
                } else if (!e.shiftKey && document.activeElement === lastelement) /* tab */ {
                    firstelement.focus();
                    var_modal.querySelector('.modal__header').scrollIntoView();
                    e.preventDefault();
                }
            });
        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_edit_qr_code'));
            set_qr_code(null);
            set_task_options([]);
            set_errors([]);
            set_processing(true);
            populate_qr_code();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ display ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_qr_code() {
        let data = await API_get_org_credential_credit_tracking_qr_code();
        if (qr_code_id) {
            data.qr_code.expiry_date_date = data.qr_code.expiry_date ? datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(data.qr_code.expiry_date)) : null;
            data.qr_code.tasks[0].credit_value = form_helper.pad_decimal_string(data.qr_code.tasks[0].credit_value, 2);
            set_qr_code(data.qr_code);
            set_no_expiry_date(!data.qr_code.expiry_date);
        } else {
            set_qr_code({
                credential_id,
                name: 'QR Code',
                expiry_date: null,
                tasks: [{
                    id: null,
                    use_current_date: 'YES',
                    activity_date: null,
                    credit_value: null
                }]
            });
            set_no_expiry_date(false);
        }
        set_task_options(data.credit_task_options.map(item => ({ value: item.id, text: !!item.parent_description ? `${item.parent_description} - ${item.description}` : item.description })));
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credential_credit_tracking_qr_code() {
        return API.get('credentials', `/get-org-credential-credit-tracking-qr-code/${credential_id}${qr_code_id ? '/' + qr_code_id : ''}`);
    }

    function API_post_org_credential_credit_tracking_qr_code() {
        return API.post('credentials', '/post-org-credential-credit-tracking-qr-code', {
            body: var_qr_code
        });
    }

    function API_put_org_credential_credit_tracking_qr_code() {
        return API.put('credentials', `/put-org-credential-credit-tracking-qr-code`, {
            body: var_qr_code
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_task_field(e, { name, value }) {
        let qr_code = { ...var_qr_code, tasks: var_qr_code.tasks.map(task => ({ ...task })) };
        switch (name) {
            case 'id':
                qr_code.tasks[0].id = value;
                break;
            case 'credit_value':
                qr_code.tasks[0].credit_value = form_helper.format_decimal_string(value, 4, 2);
                break;
            default:
                throw new Error('Invalid option');
        }
        set_qr_code(qr_code);
        set_errors(var_errors.filter(item => item.property !== name).map(item => ({ ...item })));
    }

    function onBlur_credits() {
        let qr_code = { ...var_qr_code, tasks: var_qr_code.tasks.map(task => ({ ...task })) };
        qr_code.tasks[0].credit_value = form_helper.pad_decimal_string(qr_code.tasks[0].credit_value, 2);
        set_qr_code(qr_code);
    }

    function onChange_expiry_date(e, { value }) {
        let qr_code = { ...var_qr_code, tasks: var_qr_code.tasks.map(task => ({ ...task })) };
        qr_code.expiry_date = value ? datelib.localdate_to_utc_midnight_moment(value).valueOf() / 1000 : null;
        qr_code.expiry_date_date = value;
        set_qr_code(qr_code);
        set_errors(var_errors.filter(item => item.property !== 'expiry_date').map(item => ({ ...item })));
    }

    function onChange_no_expiry_date() {
        let no_expiry_date = !var_no_expiry_date;
        set_no_expiry_date(no_expiry_date);
        if (no_expiry_date) {
            let qr_code = { ...var_qr_code, tasks: var_qr_code.tasks.map(task => ({ ...task })) };
            qr_code.expiry_date = null;
            set_qr_code(qr_code);
        }
        set_errors(var_errors.filter(item => item.property !== 'expiry_date').map(item => ({ ...item })));
    }

    async function onClick_save() {
        let qr_code = var_qr_code;
        // validation
        let errors = [];
        if (!qr_code.tasks[0].id) {
            errors.push({ property: 'id', description: t('Task is required') });
        }
        if (qr_code.tasks[0].credit_value === null || qr_code.tasks[0].credit_value === '') {
            errors.push({ property: 'credit_value', description: t('Issued credits is required') });
        } else if (Number(qr_code.tasks[0].credit_value) <= 0 || Number(qr_code.tasks[0].credit_value) > 9999.99) {
            errors.push({ property: 'credit_value', description: t('Issued credits is invalid') });
        }
        if (!var_no_expiry_date && !qr_code.expiry_date) {
            errors.push({ property: 'expiry_date', description: t('Expiry date is required if it expires') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // save now
        if (var_processing) return;
        set_processing(true);
        document.getElementById('mdl_edit_qr_code').scrollTo(0, 0);
        try {
            if (!!qr_code_id) {
                await API_put_org_credential_credit_tracking_qr_code();
            } else {
                await API_post_org_credential_credit_tracking_qr_code();
            }
            onChange && onChange();
        } catch {}
        set_processing(false);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_edit_qr_code'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_edit_qr_code'>{!!qr_code_id ? t('Edit QR code') : t('Create QR code')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_edit_qr_code' aria-labelledby='hdr_edit_qr_code' tabIndex='0'>
                <FORM_SELECT
                    property='id'
                    label={t('Task')}
                    value={var_qr_code?.tasks?.[0]?.id}
                    onChange={onChange_task_field}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('Select')}
                    options={var_task_options}
                    disabled={!!qr_code_id}
                    errors={var_errors}
                />
                <FORM_INPUT
                    property='credit_value'
                    label={t('Issued credits')}
                    value={var_qr_code?.tasks?.[0]?.credit_value?.toString()}
                    onChange={onChange_task_field}
                    onBlur={onBlur_credits}
                    placeholder={t('Credits')}
                    errors={var_errors}
                />
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Expiry date')}</div>
                    <Form.Field>
                        <DATEPICKER
                            property='expiry_date'
                            label={t('Expiry date')}
                            value={var_qr_code?.expiry_date_date}
                            minproperty={datelib.date_to_midnight_date(new Date())}
                            onChange={onChange_expiry_date}
                            placeholder={t('YYYY-MM-DD')}
                            onOpen={() => set_modal_close_on_escape(false)}
                            onClose={() => set_modal_close_on_escape(true)}
                            disabled={var_no_expiry_date}
                            errors={var_errors}
                        />
                        <CHECKBOX
                            label={t('Do not expire')}
                            onChange={onChange_no_expiry_date}
                            checked={var_no_expiry_date}
                            style={{ marginTop: '0.75rem' }}
                        />
                    </Form.Field>
                </div>
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_EDIT_QR_CODE;