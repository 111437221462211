import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import { Modal, Button, Form } from 'semantic-ui-react';
import 'i18n';
import Processing from 'components/cmp_processing';
import { FORM_SELECT } from 'components/cmp_form/cmp_form';
import form_helper from 'libs/form-lib';

import CONTENT_DOCUMENT from './content_document/content_document';
import CONTENT_ATTESTATION from './content_attestation/content_attestation';
import CONTENT_CREDENTIAL from './content_credential/content_credential';
import CONTENT_SURVEY from './content_survey/content_survey';

import './mdl_prequalifier.css';



function MDL_PREQUALIFIER({ display, onClose, onChange, credential_prequalifier_id, credential, has_verifiable_credential_feature }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_prequalifier, set_prequalifier ] = useState({});
    const [ var_add_prequalifier, set_add_prequalifier ] = useState(true);
    const [ var_survey_list, set_survey_list ] = useState([]);
    const [ var_systemerror, set_systemerror ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);

    const [ var_title, set_title ] = useState('');
    const var_prequalifier_types = [
        { value: 'ATTESTATION', text: t('Attestation') },
        { value: 'CREDENTIAL', text: t('Credential') },
        { value: 'DOCUMENT', text: t('Document acknowledgment') },
        ...(var_survey_list.length === 0 ? [] : [{ value: 'SURVEY', text: t('Survey') }])
    ];


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), textarea:not([disabled]), select:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            document.querySelector('.modal__header').scrollIntoView();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_focuselement(document.activeElement);
            set_modal(document.querySelector('#mdl__prequalifier'));
            set_systemerror(false);
            set_prequalifier({});
            set_survey_list([]);
            set_errors([]);
            set_processing(false);
            set_title('');
            populate_survey_list();
            if (credential_prequalifier_id) {
                set_add_prequalifier(false);
                populate_prequalifier();
            } else {
                set_add_prequalifier(true);
                set_prequalifier({ credential_id: credential.id, save_credit_value: 'NO' });
                set_title(t('Add pre-qualification'));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (var_prequalifier.fileupload_status === 'UPLOAD COMPLETE') {
            save_prequalifier(var_prequalifier);
        } else if (var_prequalifier.fileupload_status === 'UPLOAD FAIL') {
            set_systemerror(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_prequalifier.fileupload_status]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_prequalifier() {
        set_processing(true);
        try {
            let prequalifier = await API_get_prequalifer();

            if (prequalifier.expires_amount && prequalifier.expires_unit) {
                prequalifier.expires = 'YES';
            }

            set_prequalifier({ ...prequalifier, credential_id: credential.id });
            switch (prequalifier.prequalifier_type) {
                case 'ATTESTATION':
                    set_title(t('Attestation'));
                    break;
                case 'CREDENTIAL':
                    if (prequalifier.is_verifiable_presentation === 'YES') {
                        set_title(t('Credential with a VP'));
                    } else if (prequalifier.credits_required > 0) {
                        set_title(t('Credit-based credential'));
                    } else {
                        set_title(t('Credential'));
                    }
                    break;
                case 'DOCUMENT':
                    set_title(t('Document acknowledgment'));
                    break;
                case 'SURVEY':
                    set_title(t('Survey'));
                    break;
                default:
                    throw new Error('Unknown type');
            }
        } catch (exception) {
            console.log(exception);
            set_systemerror(true);
        }
        set_processing(false);
    }

    async function populate_survey_list() {
        try {
            set_survey_list(await API_get_prequalifier_survey_list());
        } catch (exception) {
            console.log(exception);
            set_systemerror(true);
        }
    }

    async function save_prequalifier(prequalifier) {
        try {
            if (prequalifier.prequalifier_id) {
                await API_put_prequalifier(prequalifier);
            } else {
                await API_post_prequalifier(prequalifier);
            }
            onChange && onChange(var_focuselement?.id);
            onClose();
        } catch (exception) {
            console.log(exception);
            set_systemerror(true);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_prequalifer() {
        return API.get('issuer', '/get-prequalifier/' + credential_prequalifier_id);
    }

    function API_get_prequalifier_survey_list() {
        return API.get('issuer', '/get-prequalifier-survey-list');
    }

    function API_post_prequalifier(prequalifier) {
        return API.post('issuer', '/post-prequalifier', { body: prequalifier });
    }

    function API_put_prequalifier(prequalifier) {
        return API.put('issuer', '/put-prequalifier', { body: prequalifier });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_save() {
        if (var_processing) return;

        let prequalifier = { ...var_prequalifier };
        // validation
        let errors = [];
        switch(prequalifier.prequalifier_type) {
            case 'ATTESTATION':
                if (!form_helper.validate_required_string(prequalifier.short_description)) {
                    errors.push({ property: 'short_description', description: t('Description is required') });
                }
                if (!form_helper.validate_required_string(prequalifier.long_description)) {
                    errors.push({ property: 'long_description', description: t('Detailed description is required') });
                }
                if (!form_helper.validate_required_string(prequalifier.attestation_text)) {
                    errors.push({ property: 'attestation_text', description: t('Checkbox label is required') });
                }
                // cleanse properties
                form_helper.cleanse_string_property(prequalifier, 'short_description');
                form_helper.cleanse_string_property(prequalifier, 'long_description');
                form_helper.cleanse_string_property(prequalifier, 'attestation_text');
                break;
            case 'CREDENTIAL':
                // Cleanse these properties first so that credit_threshold checks evaluate properly
                form_helper.cleanse_decimal_property(prequalifier, 'credits_required');
                if (prequalifier.preapprovals.length > 0) {
                    for (let index = 0; index < prequalifier.preapprovals.length; index++) {
                        form_helper.cleanse_decimal_property(prequalifier.preapprovals[index], 'credit_value');
                    }
                }

                if (prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES' && !form_helper.validate_positive_number(prequalifier.credits_required)) {
                    errors.push({ property: 'credits_required', description: t('The number of required credits must be set as a value greater than zero.') });
                }
                if (prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES' && prequalifier.save_credit_value === 'YES' && prequalifier.preapprovals.some(item => !form_helper.validate_positive_number(item.credit_value))) {
                    errors.push({ property: 'credit_value', description: t('The credit value of a credential must be set as a value greater than zero.') });
                }
                if (prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES' && prequalifier.save_credit_value === 'NO' && prequalifier.preapprovals.length > 0 && prequalifier.preapprovals.reduce((acc, curr) => acc + curr.footprint_credit_value, 0) < prequalifier.credits_required) {
                    errors.push({ property: 'credit_threshold', description: t('The total value of the approved credentials falls below the threshold required to fulfill the pre-qualifier requirement. If you would like to override the default credit value, check the \'Save credit values\' checkbox.') });
                }
                if (prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES' && prequalifier.save_credit_value === 'YES' && prequalifier.preapprovals.reduce((acc, curr) => acc + curr.credit_value, 0) < prequalifier.credits_required) {
                    errors.push({ property: 'credit_threshold', description: t('The total value of the approved credentials falls below the threshold required to fulfill the pre-qualifier requirement.') });
                }
                if (!form_helper.validate_required_string(prequalifier.title)) {
                    errors.push({ property: 'title', description: t('Description is required') });
                }
                if (prequalifier.preapprovals.length === 0) {
                    errors.push({ property: 'preapprovals', description: prequalifier.is_verifiable_presentation === 'YES' ? t('A verifiable credential must be selected') : t('At least one credential must be selected') });
                }
                if (errors.length === 0) {
                    // cleanse properties
                    form_helper.cleanse_string_property(prequalifier, 'title');
                    if (prequalifier.is_verifiable_presentation === 'YES') {
                        prequalifier.creditbased = 'NO';
                    }
                }
                break;
            case 'DOCUMENT':
                if (!form_helper.validate_required_string(prequalifier.description)) {
                    errors.push({ property: 'description', description: t('Description is required') });
                }
                if (prequalifier.prequalifier_id === undefined && prequalifier.fileupload_status !== 'UPLOAD READY') {
                    errors.push({ property: 'filename', description: t('Document is required') });
                }

                if (prequalifier.expires === 'YES') {
                    if (!form_helper.validate_positive_number(prequalifier.expires_amount)) {
                        errors.push({ property: 'expires_amount', description: t('A valid expiration value is required') });
                    }
                    if (!form_helper.validate_option(prequalifier.expires_unit, ['day', 'month', 'year'])) {
                        errors.push({ property: 'expires_unit', description: t('Duration is required') });
                    }
                }


                // cleanse properties
                form_helper.cleanse_string_property(prequalifier, 'description');
                break;
            case 'SURVEY':
                if (!form_helper.validate_required_string(prequalifier.organization_survey_id)) {
                    errors.push({ property: 'organization_survey_id', description: t('Survey is required') });
                }
                if (!form_helper.validate_required_string(prequalifier.title)) {
                    errors.push({ property: 'title', description: t('Title is required') });
                }
                if (!form_helper.validate_required_string(prequalifier.description)) {
                    errors.push({ property: 'description', description: t('Description is required') });
                }
                // cleanse properties
                form_helper.cleanse_string_property(prequalifier, 'title');
                form_helper.cleanse_string_property(prequalifier, 'description');
                break;
            default:
                throw new Error('Unknown prequalifier type');
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // save
        set_processing(true);
        document.getElementById('mdl__prequalifier').scrollTo(0, 0);
        if (prequalifier.prequalifier_type === 'DOCUMENT' && prequalifier.fileupload_status === 'UPLOAD READY') {
            prequalifier.fileupload_status = 'START UPLOAD';
            set_prequalifier(prequalifier);
        } else {
            set_prequalifier(prequalifier);
            save_prequalifier(prequalifier);
        }
    }

    function onChange_select(event, { name, value }) {
        let prequalifier = { credential_id: credential.id, save_credit_value: 'NO' };
        prequalifier.prequalifier_type = value;
        if (prequalifier.prequalifier_type === 'CREDENTIAL') {
            prequalifier.is_verifiable_presentation = 'NO';
            prequalifier.preapprovals = [];
        }
        set_prequalifier(prequalifier);
    }

    function onChange_prequalifier(updated_prequalifier, updated_property) {
        set_prequalifier(updated_prequalifier);
        if (updated_property) {
            if (Array.isArray(updated_property)) {
                set_errors(var_errors.filter(item => !updated_property.includes(item.property)));
            } else {
                set_errors(var_errors.filter(item => item.property !== updated_property));
            }
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            className='mdl__prequalifier'
            id='mdl__prequalifier'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_add_prequalifier'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_add_prequalifier'>{var_title}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' id='form_add_prequalifier' aria-labelledby='hdr_add_prequalifier'>
                {var_add_prequalifier &&
                    <div className='qualifiertype__wrapper'>
                        <FORM_SELECT
                            property='prequalifier_type'
                            label={t('Qualifier type')}
                            value={var_prequalifier.prequalifier_type}
                            onChange={onChange_select}
                            onOpen={() => set_modal_close_on_escape(false)}
                            onClose={() => set_modal_close_on_escape(true)}
                            placeholder={t('Select')}
                            options={var_prequalifier_types}
                            disabled={false}
                            errors={var_errors}
                        />
                    </div>
                }

                {var_prequalifier.prequalifier_type === 'ATTESTATION' &&
                    <CONTENT_ATTESTATION prequalifier={var_prequalifier} errors={var_errors} credentialstatus={credential.status} onChange={onChange_prequalifier} />
                }

                {var_prequalifier.prequalifier_type === 'CREDENTIAL' &&
                    <CONTENT_CREDENTIAL prequalifier={var_prequalifier} errors={var_errors} credentialstatus={credential.status} has_verifiable_credential_feature={has_verifiable_credential_feature} onChange={onChange_prequalifier} />
                }

                {var_prequalifier.prequalifier_type === 'DOCUMENT' &&
                    <CONTENT_DOCUMENT prequalifier={var_prequalifier} errors={var_errors} credentialstatus={credential.status} onChange={onChange_prequalifier} />
                }

                {var_prequalifier.prequalifier_type === 'SURVEY' &&
                    <CONTENT_SURVEY prequalifier={var_prequalifier} survey_list={var_survey_list} errors={var_errors} credentialstatus={credential.status} onChange={onChange_prequalifier} />
                }

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}

MDL_PREQUALIFIER.propTypes = {
    display: propTypes.bool.isRequired,
    onClose: propTypes.func,
    onChange: propTypes.func,
    credential_prequalifier_id: propTypes.string,
    credential: propTypes.object
};

export default MDL_PREQUALIFIER;