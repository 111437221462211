import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import 'i18n';

import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { DATEPICKER } from 'components/cmp_form/cmp_form';

import './crd_template_activity.css'
import { Button } from 'semantic-ui-react';
import MDL_ACTIVITY_DATA from './mdl_activity_data/mdl_activity_data';

function CRD_TEMPLATE_ACTIVITY({ activetab, template_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'presented_at', sortorder: 'descending' });
    const [ var_ready, set_ready ] = useState(false);
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_totalrows, set_totalrows ] = useState(0);
            
    const [ var_activity, set_activity ] = useState([]);
    const [ var_enddate, set_enddate ] = useState(new Date());
    const [ var_errors, set_errors ] = useState([]);
    const [ var_startdate, set_startdate ] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));

    const [ var_mdl_activity_data_open, set_mdl_activity_data_open ] = useState(false);
    const [ var_verifiable_credentials, set_verifiable_credentials ] = useState([]);
    

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        let errors = [];
        if (var_startdate && var_enddate && datelib.iso_to_date_string(var_enddate, true) < datelib.iso_to_date_string(var_startdate, true)) {
            errors.push({property: 'enddate', description: t(`To date cannot be before From date`)});
        }
        if (!var_startdate) {
            errors.push({property: 'startdate', description: t(`From date is required`)});
        }
        if (!var_enddate) {
            errors.push({property: 'enddate', description: t(`To date is required`)});
        }
        set_errors(errors);

        if (errors.length === 0) {
            set_refreshtable(!var_refreshtable);
            populate_records({ ...var_populateconfig, offset: 0, filter: {} }, var_startdate, var_enddate);
        } else {
            set_totalrows(0);
            set_activity([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_startdate, var_enddate ]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, startdate = var_startdate, enddate = var_enddate) {
        if (var_errors.length > 0) return;

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_verification_template_activity(limit, offset, sortby, sortorder, filter, null, startdate, enddate);
            set_totalrows(results.totalrows);
            set_activity(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        if (var_errors.length > 0) return [];

        try {
            return (await API_get_verification_template_activity(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername, var_startdate, var_enddate)).results;
        } catch (e) {
            console.log(e);
        }

    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_verification_template_activity(limit, offset, sortby, sortorder, filter, null, var_startdate, var_enddate)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verification_template_activity(limit, offset, sortby, sortorder, filter, filtername, startdate, enddate) {
        return API.post('verifiable-credentials', `/get-verification-template-activity/${template_id}`,
            {
                queryStringParameters: {
                    limit,
                    offset,
                    sortby,
                    sortorder,
                    filtername,
                    tz: datelib.timezone,
                    startdate: datelib.iso_to_date_string(startdate),
                    enddate: datelib.iso_to_date_string(enddate)
                },
                body: filter
            }
    );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_enddate(e, { value }) {
        set_enddate(value);
    }

    function onChange_startdate(e, { value }) {
        set_startdate(value);
    }

    function onClick_data(verifiable_credentials) {
        set_mdl_activity_data_open(true);
        set_verifiable_credentials(verifiable_credentials && JSON.parse(verifiable_credentials));
    }

    function onClose_activityData() {
        set_mdl_activity_data_open(false);
        set_verifiable_credentials([]);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_template_activity' tabIndex='0' >
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_template_activity' className='text--xl-medium' >{t('Verification activity')}</div>
                    </div>
                </div>

                <Table
                    id='tbl_template_activity'
                    downloadfunction={download_records}
                    downloadname={t('template activity')}
                    loading={var_loading}
                    loadingerror={var_loadingerror}
                    lockcolumns={0}
                    onChange={populate_records}
                    populateconfig={var_populateconfig}
                    populatefilterfunction={populate_filters}
                    ready={var_ready}
                    refresh={var_refreshtable}
                    totalrows={var_totalrows}
                >
                    <Table.OptionsButtons>
                        <div className='option_button_group' role='form' id='opt_template_activity' aria-labelledby='hdr_template_activity'>
                            <div className='datepicker_wrapper'>
                                <div className='datepicker_label text--sm-medium'>{t('From')}</div>
                                <DATEPICKER
                                    property='startdate'
                                    label={t('From')}
                                    value={var_startdate}
                                    onChange={onChange_startdate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>
                            <div className='datepicker_wrapper'>
                                <div className='datepicker_label text--sm-medium'>{t('To')}</div>
                                <DATEPICKER
                                    property='enddate'
                                    label={t('To')}
                                    value={var_enddate}
                                    onChange={onChange_enddate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>
                        </div>

                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='subject_did' datatype='text' filtertype='text'>{t('WALLET IDENTIFIER')}</Table.HeaderCell>
                            <Table.HeaderCell field='presented_at' datatype='datetime' filtertype='date'>{t('PRESENTATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='verifiable_credential' datatype='text' filtertype='text'>{t('DATA')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_activity.map((item) =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <div className={'badge text--sm-medium  ' +
                                        ( item.status === 'PASS' ? 'badge--green'
                                        : item.status === 'FAIL' ? 'badge--red'
                                        : item.status === 'IN PROGRESS' ? 'badge--yellow'
                                        : 'badge--gray')}
                                    >
                                        {item.status_display}
                                    </div>
                                </Table.Cell>
                                <Table.Cell style={{ overflow: 'hidden', 'text-overflow': 'ellipsis', 'maxWidth': '15rem'}}>{item.subject_did}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_datetime_string(item.presented_at, true)}</Table.Cell>
                                <Table.Cell>
                                    <div className='text--anchor'>
                                        <Button id={`btn_data_${item.id}`} className='tertiary' disabled={!item.verifiable_credential} aria-disabled={!item.verifiable_credential} onClick={() => onClick_data(item.verifiable_credential)} >
                                            {item.verifiable_credential && t('View')}
                                        </Button>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>

            <MDL_ACTIVITY_DATA
                display={var_mdl_activity_data_open}
                onClose={onClose_activityData}
                verifiable_credentials={var_verifiable_credentials}
            />
        </>


    );


}

CRD_TEMPLATE_ACTIVITY.propTypes = {
    activetab: propTypes.string.isRequired,
    template_id: propTypes.string.isRequired,
};

export default CRD_TEMPLATE_ACTIVITY;
