import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Modal, Button, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import ReactJson from 'react-json-view';
import Icon from 'components/cmp_icon';

import './mdl_activity_data.css'

function MDL_ACTIVITY_DATA({ display, onClose, verifiable_credentials }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_message, set_message ] = useState({});
    const [ var_selected_index, set_selected_index ] = useState(-1);
    
    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            // reset variables
            set_message({});

            if (verifiable_credentials && verifiable_credentials.length > 0) {
                set_selected_index(0);
            } else {
                set_message({type: 'ERROR', content: t('An unexpected error occurred. Please try again.')});
            }

            // focus trapping
            let modal = document.getElementById('mdl_activity_data');
            modal.querySelector('.modal__content').focus();
            modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    let elements = modal.querySelectorAll('.modal__content, button');
                    let firstelement = elements[0];
                    let lastelement = elements[elements.length - 1];
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_item(index) {
        set_selected_index(index === var_selected_index ? -1 : index);
    }

    function onKeyDown_item(e, index) {
        if (e.key === 'Enter') {
            onClick_item(index);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_activity_data'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_activity_data'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_activity_data'>{t('Verification data')}</div>
                </div>
            </div>

            <div id='mdl_activity_data_content' className='modal__content' tabIndex='0'>
                <div className='content_header'>
                    {Object.keys(var_message).length > 0 &&
                            <Message
                                error={var_message.type === 'ERROR'}
                                warning={var_message.type === 'WARNING'}
                                success={var_message.type === 'SUCCESS'}
                                icon={<Icon name={var_message.type.toLowerCase()} className='icon' />}
                                header={var_message.header}
                                content={var_message.content}
                            />
                    }

                    <div className='text--sm-regular'>
                        {t('This is a subset of the verifiable credential data, containing only the attributes selected for verification, not the full credential.')}
                    </div>
                </div>

                {verifiable_credentials?.map((item, index) => 
                    <div key={index} className='activity_item_wrapper' aria-expanded={index === var_selected_index} >
                        <div className='activity_item' onClick={() => onClick_item(index)} onKeyDown={(e) => onKeyDown_item(e, index)} tabIndex='0' >
                            <div className='activity_item_label' ></div>
                            {index === var_selected_index
                                ? <Icon name='chevron_up' className='accordion__chevron--right color--primary-500' />
                                : <Icon name='chevron_down' className='accordion__chevron--right color--primary-500' />
                            }
                        </div>

                        {index === var_selected_index &&
                        <div className='json__wrapper'>
                            <ReactJson
                                style={{ display: 'block' }}
                                name={false}
                                enableClipboard={false}
                                quotesOnKeys={false}
                                displayDataTypes={false}
                                theme='bright:inverted'
                                displayObjectSize={false}
                                collapsed={5}
                                src={item.vc?.credentialSubject}
                            />
                        </div>
                    }
                    </div>
                )}
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button id='btn_activity_data_close' className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>
        </Modal>
    );
}

MDL_ACTIVITY_DATA.propTypes = {
    display: propTypes.bool.isRequired,
    onClose: propTypes.func,
    verifiable_credentials: propTypes.array
};

export default MDL_ACTIVITY_DATA;
