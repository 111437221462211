import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';


export default function MDL_CREDIT_CREDENTIAL({ display, onClose, individualcredential_id, prequalifier_id, prequalifier_name, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_has_existing_credentials, set_has_existing_credentials ] = useState(false);
    const [ var_credits_required, set_credits_required ] = useState(null);
    const [ var_credits_obtained, set_credits_obtained ] = useState(null);
    const [ var_initial_selected_credentials, set_initial_selected_credentials ] = useState([]);
    const [ var_selected_credentials, set_selected_credentials ] = useState([]);
    const [ var_credential_list, set_credential_list ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_error_description, set_error_description ] = useState(null);
    const [ var_warning_description, set_warning_description ] = useState(null);
    const [ var_warning_pii, set_warning_pii ] = useState(null);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_has_approved_credential, set_has_approved_credential ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="search"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_has_existing_credentials(false);
            set_credits_required(null);
            set_credits_obtained(null);
            set_initial_selected_credentials([]);
            set_selected_credentials([]);
            set_credential_list([]);
            set_processing(false);
            set_error_description(null);
            set_warning_description(null);
            set_warning_pii(null);
            set_loading(true);
            load_credentials();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (display && !var_loading) {
            set_modal(document.querySelector('#mdl_changecred'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, var_loading, var_has_approved_credential]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function load_credentials() {
        try {
            let results = await API_get_credential_prequalifier_credit_list();
            set_credits_required(results.info.credits_required);
            set_has_existing_credentials(results.credential_list.some(item => item.individual_credential_prequalifier_credential_id !== null));
            set_selected_credentials(results.credential_list.filter(item => item.individual_credential_prequalifier_credential_id !== null).map(item => item.id));
            set_initial_selected_credentials(results.credential_list.filter(item => item.individual_credential_prequalifier_credential_id !== null).map(item => item.id));
            let credits_obtained = 0;
            results.credential_list.filter(item => item.individual_credential_prequalifier_credential_id !== null && item.verification_status === 'VALID').forEach(item => credits_obtained += item.credit_value);
            set_credits_obtained(credits_obtained);
            let transformed = results.credential_list.map((item, index) => ({
                value: item.id ?? index.toString(),
                primary_text: item.credit_value === 1 ? `${item.credential} (1 ${t('credit')})` : `${item.credential} (${item.credit_value} ${t('credits')})`,
                secondary_text: item.issuer,
                secondary_text_link: item.website,
                disabled: item.verification_status !== 'VALID',
                credit_value: item.credit_value,
                has_attachment: item.has_attachment
            }));
            set_credential_list(transformed);
            if (results.credential_list.some(item => item.verification_status === 'VALID')) {
                set_has_approved_credential(true);
                set_warning_description(t("Modifying the credentials may result in the pre-qualifier becoming incomplete. As a result, the status of your credential may change from 'Valid' to 'In progress' until the pre-qualifier is completed. This action cannot be undone."));
            } else {
                set_has_approved_credential(false);
                set_warning_description(t('You do not have an approved credential to submit.'));
            }
        } catch (e) {
            console.log(e);
            set_error_description(t('An unexpected error occurred. Please try again.'));
        }
        set_loading(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_prequalifier_credit_list() {
        return API.get('credentials', '/get-credential-prequalifier-credit-list/' + individualcredential_id + '/' + prequalifier_id, {
            queryStringParameters: { tz: datelib.timezone }}
        );
    }

    function API_put_credential_prequalifier_credentials() {
        return API.put('credentials', '/put-credential-prequalifier-credentials', {
            queryStringParameters: { tz: datelib.timezone },
            body: { individualcredential_id, prequalifier_id, selected_individualcredential_ids: var_selected_credentials }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_credentials(value) {
        set_error_description(null);
        set_warning_pii(null);
        set_selected_credentials(value);
        let credits_obtained = 0;
        var_credential_list.filter(item => value.includes(item.value) && !item.disabled).forEach(item => credits_obtained += item.credit_value);
        set_credits_obtained(credits_obtained);

        let new_selected_credentials = value.filter(credential => !var_initial_selected_credentials.includes(credential));

        for (let credential of new_selected_credentials) {
            if (var_credential_list.some(item => item.value === credential && item.has_attachment === 'YES')) {
                set_warning_pii(t('Credentials with attachments may include personally identifiable information, which will be visible to the issuer when the credential is submitted to the pre-qualifier.'));
                break;
            }
        }
    }

    async function onClick_save() {
        if (var_processing) return;

        // validation
        if (var_selected_credentials.length === 0) {
            set_error_description(t('You must select a credential before submitting'));
            return;
        }

        set_processing(true);
        document.getElementById('mdl_changecred').scrollTo(0, 0);
        try {
            await API_put_credential_prequalifier_credentials();
            onChange && onChange();
        } catch (exception) {
            console.log(exception);
            set_error_description(t('An unexpected error occurred. Please try again.'));
        }
        set_processing(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='mdl__individualcredential_submit_prequalifiercredential'
            id='mdl_changecred'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_changecred'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_changecred'>{var_has_existing_credentials ? t('Change credential') : t('Submit credential')}</div>
                </div>
            </div>
            <Form className='modal__content center padding--lg' tabIndex='0'>
                {var_error_description &&
                    <Message error
                        icon={<Icon name='error' className='icon' alt={t('alert icon')} />}
                        header={var_error_description} />
                }

                {!var_loading && var_warning_description &&
                    <Message warning
                        icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                        header={var_warning_description} />
                }

                {!var_loading && var_warning_pii &&
                    <Message warning
                        icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                        header={var_warning_pii} />
                }

                <div style={{ marginBottom: '1rem' }}>{prequalifier_name}</div>

                <div>
                    <FORM_SELECT_RADIO
                        name='credentials'
                        label={t('Approved credentials')}
                        value={var_selected_credentials}
                        options={var_credential_list}
                        single_or_multiple='MULTIPLE'
                        onChange={onChange_credentials} />
                </div>

                {var_credits_required !== null &&
                    <div className='detailsgroup' style={{ paddingBottom: '0' }}>
                        <div className='detailsgroup__value' style={{ marginLeft: '11.25rem' }}>
                            <div className={`badge text--sm-medium ${var_credits_obtained >= var_credits_required ? 'badge--green' : 'badge--yellow'}`}>
                                {`${var_credits_obtained}/${var_credits_required} ${var_credits_required === 1 ? t('credit') : t('credits')}`}
                            </div>
                        </div>
                    </div>
                }

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {(!var_loading && var_has_approved_credential)
                        ?
                            <>
                                <Button className='primary' onClick={onClick_save}>{t('Submit')}</Button>
                                <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                            </>
                        :   <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                    }
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );
}