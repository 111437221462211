import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import { CHECKBOX, FORM_FILEUPLOAD, FORM_INPUT, FORM_SELECT, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';
import Icon from 'components/cmp_icon';
import * as XLSX from 'xlsx';
import config from 'config';
import FileSaver from 'file-saver';
import 'i18n';

import './cmp_mdl_individual_invite.css';

export default function CMP_MDL_INDIVIDUAL_INVITE({ display, onClose, onChange, onChangeBulk, assignment = {} }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_custom_fields, set_custom_fields ] = useState(null);
    const [ var_custom_field_data, set_custom_field_data ] = useState({});
    const [ var_permissions, set_permissions ] = useState([]);
    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_invitemethod, set_invitemethod ] = useState('SINGLE');
    const [ var_invite, set_invite ] = useState({});
    const [ var_individual_type, set_individual_type ] = useState('USER');
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_api_error, set_api_error ] = useState(null);
    const [ var_processing_batchid, set_processing_batchid ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);

    const language_options = Object.keys(config.language).map(key => ({ key, text: config.language[key].text, value: key }));

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), .text--anchor, input[type="checkbox"]:not([disabled]), select:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_invite({ email_language: [assignment.location_id ? assignment.default_language : auth.organization_default_language('ORG-INDV-ADMIN', 'invite')], suppress_invite: false });
            set_individual_type('USER');
            set_invitemethod('SINGLE');
            set_errors([]);
            set_api_error(null);
            set_fileupload_status('RESET');
            set_processing(false);
            set_processing_batchid(null);
            set_custom_fields(null);
            set_custom_field_data({});
            populate_permissions();
            populate_assignments();
            populate_custom_fields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);


    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_invite_individual'));
        }
    }, [display, var_invitemethod]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_permissions() {
        try {
            let results = await API_get_permission_list();
            let transformed = results.map(item => ({
                value: item.id,
                text: t(item.permission)
            }));
            set_permissions(transformed);
        } catch(e) {
            console.log(e);
        }
    }

    async function populate_assignments() {
        if (!assignment.location_id) {
            try {
                let results = await API_get_assignment_list_for_org();
                if (results && results.length > 0) {
                    set_invite({ email_language: [auth.organization_default_language('ORG-INDV-ADMIN', 'invite')], location_id: results[0].value, suppress_invite: false });
                    set_assignments(results);
                }
            } catch(e) {
                console.log(e);
            }
        } else {
            set_assignments([{ value: assignment.location_id, text: assignment.location_name }]);
            set_invite({ email_language: [assignment.default_language], location_id: assignment.location_id, suppress_invite: false });
        }
    }

    async function populate_custom_fields() {
        set_custom_fields(await API_get_invite_custom_fields());
    }

    async function checkinviteindividual(invite, custom_field_data) {
        set_api_error(null);
        set_processing(true);
        document.getElementById('mdl_invite_individual').scrollTo(0, 0);

        try {
            let result = await API_get_is_individual_invited(invite);
            if (result) {
                set_api_error(t('Individual has already been invited'));
                set_processing(false);
            } else {
                invite_individual(invite, custom_field_data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function invite_individual(invite, custom_field_data) {
        try {
            let result = await API_post_individual_invite(invite, custom_field_data);
            if (result === 'IS SUPERADMIN IN ANOTHER ORGANIZATION') {
                set_api_error(invite.firstname + ' ' + invite.lastname + ' ' + t('is already an admin for another organization'));
                set_processing(false);
                return;
            }
            onChange(var_invite.suppress_invite);
            onClose();
        } catch (e) {
            console.log(e);
            set_api_error(t('An unknown error has occurred. Please try again.'));
            set_processing(false);
        }
    }

    async function bulkinvite(data) {
        let file_data;
        try {
            let workbook = XLSX.read(data, { type: 'binary' });
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            file_data = XLSX.utils.sheet_to_json(worksheet, { header: 'A' });
        } catch {
            set_errors([{ property: 'file', description: t('File is invalid') }]);
            set_processing(false);
            return;
        }
        if (file_data.length <= 1) {
            set_errors([{ property: 'file', description: t('File contains no records') }]);
            set_processing(false);
            return;
        }

        // break up into 20,000 record chunks if necessary.  otherwise we could hit the 6MB limit of the lambdas
        const BATCH_SIZE = 20000;
        let start_counter = 1; // start at 1 as the first record is the header
        let batch_id = null;
        while (start_counter < file_data.length) {
            let batch_data = {
                batch_id,
                permission_id: var_invite.permission_id,
                location_id: var_invite.location_id,
                suppress_invite: var_invite.suppress_invite,
                individual_type: var_individual_type,
                email_language: var_invite.email_language,
                tz: datelib.timezone,
                individuals: file_data.slice(start_counter, start_counter + BATCH_SIZE)
            };
            batch_data.individuals = batch_data.individuals.map(item => {
                let single = { firstname: item.A?.toString(), lastname: item.B?.toString(), email: item.C?.toString() };
                if (var_custom_fields) {
                    single.custom_field_data = {};
                    for (let index = 0; index < var_custom_fields.length; index++) {
                        let property = var_custom_fields.sort(field => field.order)[index].property;
                        // Assumption that there will never be more than 23 custom fields which would cause excel column to go from Z to AA
                        single.custom_field_data[property] = form_helper.cleanse_string(item[String.fromCharCode(68 + index)]); // 68 = D
                    }
                }
                return single;
            });
            batch_id = await API_post_individual_invite_bulk(batch_data);
            start_counter += BATCH_SIZE;
        }
        set_processing_batchid(batch_id);
    }

    async function onComplete_processing(total_count, failure_count) {
        set_processing(false);
        onChangeBulk && onChangeBulk(var_processing_batchid, total_count, failure_count);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_invite_custom_fields() {
        return API.get('org-indv', `/get-invite-custom-fields${assignment.location_id ? `/${assignment.location_id}` : ``}`);
    }

    function API_get_is_individual_invited(invite) {
        return API.get('org-indv', `/get-is-individual-invited${assignment.location_id ? `/${assignment.location_id}` : ``}`, {
            queryStringParameters: {
                email: invite.email
            }
        });
    }

    function API_get_permission_list() {
        return API.get('org-indv', `/get-permission-list${assignment.location_id ? `/${assignment.location_id}` : ``}`);
    }

    function API_get_assignment_list_for_org() {
        return API.get('location', '/get-assignment-list-for-org');
    }

    function API_post_individual_invite(invite, custom_field_data) {
        return API.post('org-indv', `/post-individual-invite${assignment.location_id ? `/${assignment.location_id}` : ``}`,
            { body: { ...invite, individual_type: var_individual_type, custom_field_data, tz: datelib.timezone }});
    }

    function API_post_individual_invite_bulk(invite) {
        return API.post('org-indv', `/post-individual-invite-bulk${assignment.location_id ? `/${assignment.location_id}` : ``}`, { body: invite });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_invitemethod(value) {
        set_invitemethod(value);
        set_api_error(null);
        set_errors([]);
        set_fileupload_status('RESET');
        set_invite({...var_invite, file: false});
    }

    function onChange_input(event, { name, value }) {
        set_api_error(null);
        let invite = {...var_invite};
        invite[name] = value;
        set_invite(invite);
        if (var_errors.length > 0) {
            let errors = var_errors.filter(error => error.property !== name)
            set_errors(errors);
        }
    }

    function onChange_language(checked, value) {
        let new_value;
        if (checked) {
            new_value = [...var_invite.email_language];
            new_value.push(value);
        } else {
            new_value = var_invite.email_language.filter(item => item !== value);
        }
        set_invite({...var_invite, email_language: new_value});
        set_errors([]);
    }

    function onChange_individual_type(value) {
        set_api_error(null);
        set_individual_type(value);
        if (value === 'GUEST') {
            let invite = {...var_invite};
            invite.permission = var_permissions.filter(item => item.text === 'User').value;
            set_invite(invite);
        }
        if (var_errors.length > 0) {
            let errors = var_errors.filter(error => error.property !== 'individual_type')
            set_errors(errors);
        }
    }

    function onChange_custom_field(event, { name, value }) {
        set_api_error(null);
        let custom_field_data = { ...var_custom_field_data };
        custom_field_data[name] = value;
        set_custom_field_data(custom_field_data);
        set_errors(var_errors.filter(error => error.property !== name));
    }

    function onClick_downloadtemplate() {
        let workbook = XLSX.utils.book_new();
        let headers = [t('FIRST NAME (Required)'), t('LAST NAME (Required)'), t('EMAIL ADDRESS (Required)')];
        if (var_custom_fields) {
            var_custom_fields.sort(item => item.order).forEach(item => {
                headers.push((item.translate ? t(item.label) : item.label) + (item.required ? ` (${t('Required')})` : ''));
            });
        }
        let worksheet = XLSX.utils.aoa_to_sheet([headers]);
        XLSX.utils.book_append_sheet(workbook, worksheet, t('Invite Template'));
        let excel_buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let data = new Blob([excel_buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, t('Bulk invite template.xlsx'));
    }

    function onClick_invite() {
        let errors = [];
        if (var_invite.email_language.length === 0) {
            errors.push({property: 'email_language', description: t('Please select a language')});
        }
        if (!var_individual_type) {
            errors.push({property: 'individual_type', description: t('Please select a user type')});
        }
        if (!var_invite.permission_id) {
            errors.push({property: 'permission_id', description: t('Please select a permission level')});
        }

        if (var_invitemethod === 'SINGLE') {
            let invite = { ...var_invite };
            let custom_field_data = { ...var_custom_field_data };
            // validation
            if (!form_helper.validate_required_string(invite.firstname)) {
                errors.push({ property: 'firstname', description: t('First name is required') });
            }
            if (!form_helper.validate_required_string(invite.lastname)) {
                errors.push({ property: 'lastname', description: t('Last name is required') });
            }
            if (!invite.email || !form_helper.validate_email_address(invite.email)) {
                errors.push({property: 'email', description: t('Please provide a valid email address')});
            }
            if (var_custom_fields) {
                var_custom_fields.filter(item => item.required).forEach(item => {
                    if (!form_helper.validate_required_string(custom_field_data[item.property])) {
                        errors.push({ property: item.property, description: t('Please enter a value') });
                    }
                });
            }

            set_errors(errors);
            if (errors.length > 0) return;

            // cleanse properties
            form_helper.cleanse_string_property(invite, 'firstname');
            form_helper.cleanse_string_property(invite, 'lastname');
            form_helper.cleanse_string_property(invite, 'job_title');
            form_helper.cleanse_string_property(invite, 'email');
            if (var_custom_fields) {
                var_custom_fields.forEach(item => form_helper.cleanse_string_property(custom_field_data, item.property));
            }
            set_invite(invite);
            set_custom_field_data(custom_field_data);
            checkinviteindividual(invite, var_custom_fields ? custom_field_data : null);
        } else { // Bulk
            // validation
            if (var_fileupload_status !== 'UPLOAD READY') {
                errors.push({ property: 'file', description: t('Please select a file to upload') });
            }

            set_errors(errors);
            if (errors.length > 0) return;

            // start processing
            set_processing(true);
            document.getElementById('mdl_invite_individual').scrollTo(0, 0);
            set_fileupload_status('START PROCESSING');
        }
    }

    function onKeyDown_downloadtemplate(e) {
        if (e.key === 'Enter') {
            onClick_downloadtemplate();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            id='mdl_invite_individual'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_individuals_invite'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_individuals_invite'>{t('Invite individual')}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' id='form_invite_individual' aria-labelledby='hdr_individuals_invite'>

                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={t('There are some errors with your inputs.')}
                    />
                }

                {var_api_error &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_api_error}
                    />
                }

                <div className='method_selector'>
                    <FORM_TOGGLE
                        label={t('Method')}
                        value={var_invitemethod}
                        options={[{value: 'SINGLE', text: t('Single')}, {value: 'BULK',text: t('Bulk') }]}
                        onChange={onChange_invitemethod}
                        description={t('Invite a single individual or bulk invite multiple individuals at the same time.')}
                    />
                </div>

                {var_invitemethod === 'SINGLE' ?
                    <>
                        <FORM_INPUT
                            property='firstname'
                            label={t('First name')}
                            value={var_invite.firstname}
                            onChange={onChange_input}
                            placeholder={t('First name')}
                            maxLength={100}
                            disabled={false}
                            errors={var_errors}
                        />

                        <FORM_INPUT
                            property='lastname'
                            label={t('Last name')}
                            value={var_invite.lastname}
                            onChange={onChange_input}
                            placeholder={t('Last name')}
                            maxLength={100}
                            disabled={false}
                            errors={var_errors}
                        />

                        <FORM_INPUT
                            property='job_title'
                            label={t('Job title (optional)')}
                            value={var_invite.job_title}
                            onChange={onChange_input}
                            placeholder={t('Job title')}
                            maxLength={100}
                            disabled={false}
                            errors={var_errors}
                        />

                        <FORM_INPUT
                            property='email'
                            label={t('Email address')}
                            value={var_invite.email}
                            onChange={onChange_input}
                            placeholder={t('example@email.com')}
                            maxLength={100}
                            errors={var_errors}
                            type='email'
                        />

                        <div className='detailsgroup detailsgroup_supressinvite'>
                            <div className='detailsgroup__label'></div>
                            <CHECKBOX name='suppress_invite' label={t('Do not send an invite email to this individual')} checked={var_invite.suppress_invite} onChange={(e) => onChange_input(e, { name:'suppress_invite', value: !var_invite.suppress_invite })} />
                        </div>

                        <div className='detailsgroup'>
                            <div className='detailsgroup__label text--sm-medium'>{t('Email language')}</div>
                            <Form.Field>
                                <div className='detailsgroup__description text--sm-regular'>{t('Choose the language for the invite email. This selection may be overridden if the individual has already specified a preferred language in their profile.')}</div>
                                {language_options.map((item, index) =>
                                    <CHECKBOX
                                        key={index}
                                        property='email_language'
                                        label={item.text}
                                        checked={var_invite.email_language?.includes(item.value)}
                                        onChange={(e, data) => onChange_language(data.checked, item.value)}
                                        errors={var_errors}
                                    />
                                )}
                            </Form.Field>
                        </div>

                        {auth.has_orgaccess('ORG-GUEST', 'guest') &&
                            <FORM_TOGGLE
                                label={t('Type')}
                                value={var_individual_type}
                                options={[{value: 'USER', text: t('User')}, {value: 'GUEST',text: t('Guest') }]}
                                onChange={onChange_individual_type}
                            />
                        }

                        <FORM_SELECT
                            property='permission_id'
                            label={t('Permission level')}
                            value={var_invite.permission_id || ''}
                            onChange={onChange_input}
                            onOpen={() => set_modal_close_on_escape(false)}
                            onClose={() => set_modal_close_on_escape(true)}
                            placeholder={t('Select')}
                            options={var_individual_type !== 'GUEST' ? var_permissions : var_permissions.filter(item => item.text === 'User')}
                            disabled={false}
                            errors={var_errors}
                        />

                        {var_assignments.length > 0 &&
                            <FORM_SELECT
                                property='location_id'
                                label={t('Assignment')}
                                value={var_invite.location_id || ''}
                                onChange={onChange_input}
                                onOpen={() => set_modal_close_on_escape(false)}
                                onClose={() => set_modal_close_on_escape(true)}
                                placeholder={t('Select')}
                                options={var_assignments}
                                disabled={!!assignment.location_id}
                                errors={var_errors}
                            />
                        }

                        {var_custom_fields &&
                            var_custom_fields.map(item =>
                                <FORM_INPUT
                                    key={`customfield-${item.property}`}
                                    property={item.property}
                                    label={item.translate ? t(item.label) : item.label}
                                    value={var_custom_field_data[item.property]}
                                    onChange={onChange_custom_field}
                                    placeholder={(item.translate && item.placeholder) ? t(item.placeholder) : item.placeholder}
                                    maxLength={100}
                                    errors={var_errors}
                                />)
                        }

                    </>

                    :

                    <>

                        <div className='upload_instructions'>
                            <p>{t('Invite multiple individuals to the organization by following these steps:')}</p>
                            <p>{t('1. Download and complete the Excel template.')}</p>
                            <p>{t('2. Upload the completed Excel template.')}</p>
                            <p>{t('3. Select the type, permission level, and assignment. All individuals will have the same selections applied to them.')}</p>
                            <p>{t('4. Choose if you want to send an invitation email or not.')}</p>
                        </div>
                        <div className='detailsgroup'>
                            <div className='detailsgroup__label text--sm-medium'>{t('Excel template')}</div>
                            <div className='excel_download text--anchor' tabIndex='0' role='button' onClick={onClick_downloadtemplate} onKeyDown={onKeyDown_downloadtemplate}>
                                <Icon name='document' className='' alt='' />{
                                t('Bulk invite template.xlsx')}
                            </div>
                        </div>

                        <FORM_FILEUPLOAD
                            label={t('Data file')}
                            property='file'
                            single_or_multiple='SINGLE'
                            var_fileupload_status={var_fileupload_status}
                            set_fileupload_status={set_fileupload_status}
                            uploaddirectory=''
                            allowed_fileextensions={['xlsx', 'xls', 'csv']}
                            description={t('Upload the completed Excel template.')}
                            instructions=''
                            errors={var_errors}
                            onProcess={bulkinvite}
                        />

                        {auth.has_orgaccess('ORG-GUEST', 'guest') &&
                            <FORM_TOGGLE
                                label={t('Type')}
                                value={var_individual_type}
                                options={[{value: 'USER', text: t('User')}, {value: 'GUEST',text: t('Guest') }]}
                                onChange={onChange_individual_type}
                            />
                        }

                        <FORM_SELECT
                            property='permission_id'
                            label={t('Permission level')}
                            value={var_invite.permission_id || ''}
                            onChange={onChange_input}
                            onOpen={() => set_modal_close_on_escape(false)}
                            onClose={() => set_modal_close_on_escape(true)}
                            placeholder={t('Select')}
                            options={var_individual_type !== 'GUEST' ? var_permissions : var_permissions.filter(item => item.text === 'User')}
                            disabled={false}
                            errors={var_errors}
                        />

                        {var_assignments.length > 0 &&
                            <FORM_SELECT
                                property='location_id'
                                label={t('Assignment')}
                                value={var_invite.location_id || ''}
                                onChange={onChange_input}
                                onOpen={() => set_modal_close_on_escape(false)}
                                onClose={() => set_modal_close_on_escape(true)}
                                placeholder={t('Select')}
                                options={var_assignments}
                                disabled={!!assignment.location_id}
                                errors={var_errors}
                            />
                        }

                        <div className='detailsgroup'>
                            <div className='detailsgroup__label text--sm-medium'>{t('Email language')}</div>
                            <Form.Field>
                                <div className='detailsgroup__description text--sm-regular'>{t('Choose the language for the invite email. This selection may be overridden if the individual has already specified a preferred language in their profile.')}</div>
                                {language_options.map((item, index) =>
                                    <CHECKBOX
                                        key={index}
                                        property='email_language'
                                        label={item.text}
                                        checked={var_invite.email_language.includes(item.value)}
                                        onChange={(e, data) => onChange_language(data.checked, item.value)}
                                        errors={var_errors}
                                    />
                                )}
                            </Form.Field>
                        </div>

                        <div className='detailsgroup detailsgroup_supressinvite'>
                            <div className='detailsgroup__label'></div>
                            <CHECKBOX name='suppress_invite' label={t('Do not send an invite email to these individuals')} checked={var_invite.suppress_invite} onChange={(e) => onChange_input(e, { name:'suppress_invite', value: !var_invite.suppress_invite })} />
                        </div>

                    </>
                }
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_invite}>{var_invite.suppress_invite ? t('Add') : t('Invite')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} batch_id={var_processing_batchid} onComplete={onComplete_processing} />

        </Modal>
    );

}