import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';



export default function TAB_CREDENTIALS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    /** @type {ReturnType<typeof useState<'ACTIVE'|'DRAFT'|'PENDING'>>} */
    const [ var_credentialstatus, set_credentialstatus ] = useState('ACTIVE');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_credentials({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_credentialstatus]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_credentials(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_cred_catalogue').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_credentials(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_credentials(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_credentials(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credentials(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-org-credentials',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    status: var_credentialstatus
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    /**
     * @param {'ACTIVE'|'DRAFT'|'PENDING'} status 
     * @param {String} toggle_id 
     */
    function onClick_credentialstatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_credentialstatus(status);
    }
    function onClick_createcredential(){
        navigate('/org/credentials/create');
    }

    function onClick_credential(id) {
        navigate('/org/credentials/' + id);
    }

    function onClick_holders(id) {
        navigate('/org/credentials/' + id + '/holders');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_cred_catalogue' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_catalogue' className='text--xl-medium'>{t('Credential catalogue')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_createcredential}>{t('Create credential')}</Button>
                    </div>
                </div>

                <Table id='credentials' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('credentials')} downloadfunction={download_credentials}
                        onChange={populate_credentials}>
                    <Table.OptionsButtons>
                        <TOGGLE 
                            value={var_credentialstatus} 
                            onChange={onClick_credentialstatus} 
                            options={[{value: 'ACTIVE', text: t('Published')}, {value: 'DRAFT', text: t('Draft')}, {value: 'PENDING', text: t('Unpublished')}]}
                        />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_category' datatype='text' filtertype='option'>{t('CATEGORY')}</Table.HeaderCell>
                            <Table.HeaderCell field='expires' datatype='text' filtertype='option'>{t('EXPIRES')}</Table.HeaderCell>
                            <Table.HeaderCell field='duration' datatype='text' filtertype='text'>{t('DURATION')}</Table.HeaderCell>
                            {var_credentialstatus !== 'DRAFT' && 
                                <Table.HeaderCell field='holders' datatype='text' filtertype='number'>{t('HOLDERS')}</Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credentials.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <Button className='tertiary'  onClick={item.canaccess !== 'NO' ? () => onClick_credential(item.id) : null}>
                                        <Table.CellContentTwoLine primary_text={<div className={item.canaccess !== 'NO' ? ' text--anchor' : ''}>{item.credential_name} {item.restrictedaccess === 'YES' ? <Icon name='lock' className='icon__lock color--gray-400' alt={t('Credential has security restrictions')} /> : ''} </div>} secondary_text={item.code} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.credential_category}</Table.Cell>
                                <Table.Cell>{item.expires}</Table.Cell>
                                <Table.Cell>{item.duration}</Table.Cell>
                                {var_credentialstatus !== 'DRAFT' &&
                                    <Table.Cell className={item.canaccess !== 'NO' ? 'text--anchor' : ''}>
                                        <Button className='tertiary' onClick={item.canaccess !== 'NO' ? () => onClick_holders(item.id) : null} aria-disabled={(item.canaccess === 'NO') && 'true'}>{item.holders}</Button>
                                    </Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
        </>
    )

}
