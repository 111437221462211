import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Checkbox, Form } from 'semantic-ui-react';

function CHECKBOX({ property, label, checked = false, onChange, disabled = false, errors = [], ...props }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_tab, set_tab ] = useState(false);

    //  event functions ------------------------------------------------------------------------------------------------
    let error = errors.find(item => item.property === property);

    window.addEventListener('keydown', onTabPress);
    function onTabPress(e) {
        if (e.key === 'Tab') {
            set_tab(true);
            window.removeEventListener('keydown', onTabPress);
        }
    }



    // RENDER ==========================================================================================================
    return (
        <Form.Field>
            <Checkbox
                name={property}
                label={label}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
                className={(var_tab ? 'focus' : '') + (!!error ? ' error' : '')}
                {...props}
            />
            {error &&
                <div className='field__feedback error'>
                    <div className='description text--sm-regular'>{error.description}</div>
                </div>
            }
        </Form.Field>
    );
}

CHECKBOX.propTypes = {
    property: propTypes.string,
    label: propTypes.oneOfType([ propTypes.string, propTypes.element ]),
    checked: propTypes.bool,
    onChange: propTypes.func,
    disabled: propTypes.bool,
    errors: propTypes.array,
};

export default CHECKBOX;