import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';

import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';
import MDL_ASSIGN_REQUIREMENT from './mdl_assign_requirement/mdl_assign_requirement';
import CMP_MDL_PREQUALIFIERS_DETAILS from 'components/cmp_mdl_prequalifiers_details/cmp_mdl_prequalifiers_details';

export default function CRD_INDIVIDUALS({ requirement }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_mdl_assignrequirement_open, set_mdl_assignrequirement_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_requirement_status, set_requirement_status ] = useState('ALL');
    const [ var_individual_name, set_individual_name ] = useState(null);
    const [ var_prequalifiers_details_open, set_prequalifiers_details_open ] = useState(false);
    const [ var_individual_requirement_id, set_individual_requirement_id ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_individuals({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_requirement_status]);

    useEffect(() => {
        if (!var_mdl_assignrequirement_open && var_focuselement?.id === 'btn_addindividual') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_assignrequirement_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_requirement_individuals(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_individuals(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_requirement_individuals(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_individuals(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_requirement_individuals(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_requirement_individuals(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements2', `/get-org-requirement-individuals/${requirement.id}/${var_requirement_status}`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_add_individual() {
        set_focuselement(document.activeElement);
        set_mdl_assignrequirement_open(true);
    }

    function onClick_individual(id) {
        navigate('/individuals/' + id);
    }

    function onChange_assign_requirement() {
        set_mdl_assignrequirement_open(false);
        populate_individuals();
    }

    function onDelete_requirement_individual(id) {
        console.warn('Not implemented');
    }

    function onClick_prequalifiers(id, name) {
        set_focuselement(document.activeElement);
        set_individual_requirement_id(id);
        set_individual_name(name);
        set_prequalifiers_details_open(true);
    }

    function onClick_requirement_status(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_requirement_status(status);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_reqdetails_individuals' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('All individuals')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button id='btn_addindividual' className='primary' onClick={onClick_add_individual}>{t('Add individual')}</Button>
                </div>
            </div>

            {requirement.status === 'DRAFT' &&
                <Message warning
                    icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                    header={t('The requirement will remain hidden from the individual until it is published.')}
                />
            }

            <Table id='individuals' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={t('Individuals')} downloadfunction={download_individuals}
                    onChange={populate_individuals}>


                <Table.OptionsButtons>
                    <TOGGLE
                        value={var_requirement_status}
                        onChange={onClick_requirement_status}
                        options={[
                                { value: 'ALL', text: t('All') },
                                { value: 'VALID', text: t('Valid') },
                                { value: 'PENDING', text: t('Pending') },
                                { value: 'IN_PROGRESS', text: t('In-progress') },
                                { value: 'INVALID', text: t('Invalid') }
                            ]}
                    />
                </Table.OptionsButtons>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                        <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('INDIVIDUAL')}</Table.HeaderCell>
                        <Table.HeaderCell field='email' datatype='text' filtertype='text'>{t('EMAIL')}</Table.HeaderCell>
                        <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        <Table.HeaderCell field='completed_prequalifiers' datatype='number' filtertype='number'>{t('PRE-QUALIFIERS')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_individuals.map(item =>
                        <Table.Row key={item.id}>
                            <Table.Cell>
                                <div className={'badge text--sm-medium  ' +
                                    (   item.status === 'VALID'
                                        ? 'badge--green'
                                        : item.status === 'INVALID'
                                        ? 'badge--red'
                                        : 'badge--yellow')}>
                                    {item.status_display}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                            <Table.Cell>{item.email}</Table.Cell>
                            <Table.Cell>{item.organization_name}</Table.Cell>
                            <Table.Cell className='cell__icon--right'>
                                <Button className='tertiary text--anchor' id={`btn_completed_prequalifiers_${item.id}`} onClick={() => onClick_prequalifiers(item.id, item.individual_name)}>
                                    {item.completed_prequalifiers}
                                </Button>
                                <Button className='tertiary' disabled={item.can_delete !== 'YES'} onClick={() => onDelete_requirement_individual(item.individualrequirement_id)}>
                                    <Icon name='delete' className='icon__delete__table'/>
                                </Button>

                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <div className='card__footer--mobilebtns'>
                <Button id='btn_addindividual' className='primary' onClick={onClick_add_individual}>{t('Add individual')}</Button>
            </div>

            <MDL_ASSIGN_REQUIREMENT
                display={var_mdl_assignrequirement_open}
                onClose={() => set_mdl_assignrequirement_open(false)}
                requirement_name={requirement?.name}
                requirement_id={requirement?.id}
                onChange={onChange_assign_requirement}
            />

            <CMP_MDL_PREQUALIFIERS_DETAILS
                is_open={var_prequalifiers_details_open}
                individual_requirement_id={var_individual_requirement_id}
                holder_name={var_individual_name}
                onClose={() => set_prequalifiers_details_open(false)}
            />
        </div>
    );

}
