import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';
import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_REQUIREMENTS from './tab_requirements/tab_requirements';
import TAB_PREQUALIFICATIONS from './tab_prequalifications/tab_prequalifications';


export default function REQUIREMENTS({ tab }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_tab, set_tab ] = useState('REQUIREMENTS');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        if (tab) {
            set_tab(tab.toUpperCase());
        } else {
            set_tab('REQUIREMENTS');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }]}
                current_page_text={t('Requirements')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('Requirements')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Requirements')} id='tabs_requirements'>
                    <TABS.TAB name='REQUIREMENTS' label={t('Requirements')} />
                    <TABS.TAB name='PREQUALIFICATIONS' label={t('Pre-qualifications')} />
                </TABS>


                {var_tab === 'REQUIREMENTS' &&
                    <TAB_REQUIREMENTS/>
                }

                {var_tab === 'PREQUALIFICATIONS' &&
                    <TAB_PREQUALIFICATIONS/>
                }

            </div>
        </div>
    )
};
