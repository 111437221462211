import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { FORM_SELECT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import auth from 'libs/auth-lib';
import Icon from 'components/cmp_icon';

import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';


function MDL_ADD_INDIVIDUALS({ is_open, onClose, credential_id, existing_individuals, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_individual_options, set_individual_options ] = useState([]);
    const [ var_selected_individuals, set_selected_individuals ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_selected_location, set_selected_location ] = useState('ALL');
    const [ var_location_options, set_location_options ] = useState([]);
    const [ var_disable_add, set_disable_add ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {
            let firstelement = var_modal.querySelector('.modal__content');
            let lastelement = Array.from(var_modal.querySelectorAll('button:not([disabled])')).at(-1);

            //  set focus to first element within the modal
            firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key !== 'Tab') return;

                if (e.shiftKey && document.activeElement === firstelement) /* shift + tab */ {
                    lastelement.focus();
                    e.preventDefault();
                } else if (!e.shiftKey && document.activeElement === lastelement) /* tab */ {
                    firstelement.focus();
                    var_modal.querySelector('.modal__header').scrollIntoView();
                    e.preventDefault();
                }
            });
        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_batch_credit_add_individuals'));
            set_individuals([]);
            set_individual_options([]);
            set_selected_individuals([]);
            set_errors([]);
            set_processing(false);
            set_selected_location('ALL');
            set_disable_add(false);
            if (auth.has_orgaccess('ORG-LOC-ADMIN', 'read')) {
                populate_locations();
            }
            populate_individuals('ALL');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ is_open ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(location_id) {
        try {
            let results = await API_get_individuals_to_add_to_credit_tracking_credential(location_id);
            set_individuals(results);
            let transformed = results.map(item => ({
                value: item.individual_credential_id,
                primary_text:
                    <CMP_VERIFIED_IDENTITY_DISPLAY
                        individual_name={item.individual_name}
                        verified_identity_credential_id={item.identity_verification_credential_id}
                        display_nonclickable_icon={true}
                    />,
                secondary_text: existing_individuals.includes(item.individual_credential_id) ? t('Already added') : item.email,
                disabled: existing_individuals.includes(item.individual_credential_id),
                additional_search_values: [item.individual_name]
            }));
            set_individual_options(transformed);
            if (transformed.length === 0) {
                set_disable_add(true);
                set_errors([{ property: 'no_individuals', description: t('There are no individuals to add.') }]);
            } else {
                set_disable_add(false);
            }
        } catch (e) {
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
        }
    }

    async function populate_locations() {
        try {
            let results = await API_get_assignment_list_for_org();
            results.unshift({ value: 'ALL', text: t('All assignments') });
            set_location_options(results);
        } catch (e) {
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals_to_add_to_credit_tracking_credential(location_id) {
        return API.get('org-indv', `/get-individuals-to-add-to-credit-tracking-credential/${credential_id}` + (location_id === 'ALL' ? '' : `/${location_id}`));
    }

    function API_get_assignment_list_for_org() {
        return API.get('location', '/get-assignment-list-for-org');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_location(e, { name, value }) {
        set_selected_location(value);
        set_errors([]);
        set_selected_individuals([]);
        populate_individuals(value);
    }

    function onChange_individuals(value) {
        set_selected_individuals(value);
        set_errors([]);
    }

    function onChange_all_individuals() {
        set_errors([]);
        let all_available_individuals = var_individual_options.filter(item => !item.disabled).map(item => item.value);
        if (var_selected_individuals.length !== all_available_individuals.length) {
            set_selected_individuals(all_available_individuals);
        } else {
            set_selected_individuals([]);
        }
    }

    async function onClick_add() {
        let errors = [];
        if (var_selected_individuals.length === 0) {
            errors.push({property: 'individuals', description: t('Please select at least one individual to add')});
        }
        set_errors(errors);
        if (errors.length > 0) { return; }

        onChange(var_individuals.filter(item => var_selected_individuals.includes(item.individual_credential_id)).map(item => ({ individual_id: item.id, individual_name: item.individual_name, individual_credential_id: item.individual_credential_id })));
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_batch_credit_add_individuals'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_creddetails_addholder'>{t('Add individuals')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_batch_credit_add_individuals' aria-labelledby='form_batch_credit_add_individuals' tabIndex='0'>
                {var_errors.find(item => !!item.property) &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors.find(item => !!item.property).description} />
                }
                {auth.has_orgaccess('ORG-LOC-ADMIN', 'read') &&
                <div>
                    <FORM_SELECT
                        property='location'
                        label={t('Assignment')}
                        value={var_selected_location || ''}
                        onChange={onChange_location}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('Select')}
                        options={var_location_options}
                        disabled={false}
                        errors={var_errors}
                        description={t('Choose an assignment to select individuals from.')}
                    />
                </div>
                }
                <FORM_SELECT_RADIO
                    name='individuals'
                    property='individuals'
                    label={t('Individuals')}
                    value={var_selected_individuals}
                    options={var_individual_options}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_individuals}
                    errors={var_errors}
                    description={t('Select individuals to receive credits.')}
                    aria_label={t('Select individuals to receive credits.')}
                    has_select_all={var_individual_options.length > 0}
                    select_all_text={var_selected_individuals.length !== var_individual_options.filter(item => !item.disabled).length ? t('Select all') : t('Deselect all')}
                    onChange_select_all={onChange_all_individuals}
                />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_disable_add}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

MDL_ADD_INDIVIDUALS.propTypes = {
    is_open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    credential_id: propTypes.string.isRequired,
    existing_individuals: propTypes.arrayOf(propTypes.string).isRequired,
    onChange: propTypes.func.isRequired
};

export default MDL_ADD_INDIVIDUALS;