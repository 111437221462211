import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';

export default function CRD_REQUIREMENTS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_delete_requirement_open, set_delete_requirement_open ] = useState(false);
    const [ var_delete_requirement_id, set_delete_requirement_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_requirementstatus, set_requirementstatus ] = useState('ACTIVE');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_requirements({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_requirementstatus]);

    useEffect(() => {
        if (!var_delete_requirement_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_requirements_all').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_delete_requirement_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);
        set_message_text(null);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_requirements(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_requirements(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_requirements_all').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_requirements_all').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_requirements(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_requirements(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_requirements(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function onConfirm_delete() {
        set_delete_requirement_open(false);
        set_processing(true);
        try {
            await API_delete_org_requirement();
            populate_requirements();
        } catch (e) {
            set_message_text(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_requirements(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements2', '/get-org-requirements/' + var_requirementstatus,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }

    function API_delete_org_requirement() {
        return API.del('requirements2', '/delete-org-requirement/' + var_delete_requirement_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_createrequirement() {
        navigate('/org/requirements2/create');
    }

    function onClick_requirementstatus(status, toggle_id) {
        set_message_text(null);
        set_focuselement(document.getElementById(toggle_id));
        set_requirementstatus(status);
    }

    function onClick_requirement(id) {
        navigate('/org/requirements2/' + id);
    }

    function onClick_count(column_name, id) {
        if (column_name === 'INDIVIDUALS') {
            navigate(`/org/requirements2/${id}/INDIVIDUALS`);
        } else {
            navigate(`/org/requirements2/${id}/ASSIGNMENTS`);
        }
    }

    function onClick_delete_requirement(requirement_id) {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_delete_requirement_id(requirement_id);
        set_delete_requirement_open(true);
    }

    function onCancel_delete_requirement() {
        set_delete_requirement_open(false);
        set_delete_requirement_id(null);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_requirements_all' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_requirements' className='text--xl-medium'>{t('Requirements')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_createrequirement}>{t('Create requirement')}</Button>
                    </div>
                </div>

                {var_message_text &&
                    <Message
                        error
                        icon={<Icon name='error' className='icon' alt={t('error icon')} />}
                        header={var_message_text}
                    />
                }

                <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('Requirements')} downloadfunction={download_requirements}
                       onChange={populate_requirements}>
                    <Table.OptionsButtons>
                        <TOGGLE value={var_requirementstatus} onChange={onClick_requirementstatus} options={[{value: 'ACTIVE', text: t('Published')}, {value: 'DRAFT', text: t('Draft')}, {value: 'ARCHIVE', text: t('Unpublished')}]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='assigned_count' datatype='number' filtertype='number'>{t('INDIVIDUALS')}</Table.HeaderCell>
                            <Table.HeaderCell field='location_count' datatype='number' filtertype='number'>{t('ASSIGNMENTS')}</Table.HeaderCell>
                            <Table.HeaderCell />

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_requirements.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor' >
                                    <Button className='tertiary' onClick={() => onClick_requirement(item.id)} >
                                        {item.name}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className='text--anchor' >
                                        <Button id={`btn_individuals_${item.id}`} className='tertiary' onClick={() => onClick_count('INDIVIDUALS', item.id)} >
                                            {item.assigned_count}
                                        </Button>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className='text--anchor' >
                                        <Button id={`btn_assignments_${item.id}`} className='tertiary' onClick={() => onClick_count('ASSIGNMENTS', item.id)} >
                                            {item.location_count}
                                        </Button>
                                    </div>
                                </Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    {var_requirementstatus === 'DRAFT' &&
                                        <Button className='tertiary' onClick={() => onClick_delete_requirement(item.id)}>
                                            <Icon name='delete' className='icon__delete__table'/>
                                        </Button>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_createrequirement}>{t('Create requirement')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>

            <CMP_CONFIRMATION
                display={var_delete_requirement_open}
                title={t('Delete requirement')}
                positive_option={t('Delete')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_delete}
                onCancel={onCancel_delete_requirement}
                message={t('Are you sure you want to delete this requirement? This action cannot be undone.')}
            />
        </>
    )

}