import React from 'react';
import { DATEPICKER, INPUT } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import form_helper from 'libs/form-lib';


import './cmp_credit_activity_list.css';


export default function CMP_CREDIT_ACTIVITY_LIST({ activities, onChange_activity, parent_id = null, display_date = true }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    //  event listeners ------------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onBlur_credit_value(activity) {
        activity.credits = form_helper.pad_decimal_string(activity.credits, 2);
        onChange_activity(activity);
    }

    function onChange_credit_value(activity, value) {
        activity.credits = form_helper.format_decimal_string(value, 4, 2);
        onChange_activity(activity);
    }

    function onChange_date(activity, value) {
        activity.activity_date = value;
        onChange_activity(activity);
    }

    // RENDER APP ======================================================================================================

    if (!activities || activities.length === 0) return;

    return (
    <>
        {activities.filter(item => item.parent_credential_credit_id === parent_id).sort((a, b) => a.sort_order - b.sort_order).map((activity) => (
        <React.Fragment key={activity.id}>
            {parent_id === null ? (
                <div id='cmp_credit_activity_list'>
                    <div className='category_wrapper'>
                        <div className='category_header text--sm-medium'>
                            <div className='category_title'>{t('Task')}</div>
                            {display_date && <div className='category_date'>{t('Date')}</div>}
                            <div className='category_summary'>{t('Credits')}</div>
                        </div>

                        <div className='item_wrapper'>
                            <div style={{ paddingRight: display_date ? '1rem' : '4rem' }} className={'item_description' + (activity.allow_credit_entry !== 'YES' ? ' text--md-medium' : '')}>
                                {activity.description}
                            </div>
                            {activity.allow_credit_entry === 'YES' && (
                                <>
                                    {display_date &&
                                        <div className='item_date'>
                                            <DATEPICKER
                                                property='activity_date'
                                                value={activity.activity_date ? new Date(activity.activity_date) : ''}
                                                placeholder={t('YYYY-MM-DD')}
                                                onChange={((e, {value}) => onChange_date(activity, value))}
                                            />
                                        </div>
                                    }
                                    <div className='item_credits'>
                                        <INPUT
                                            property='credits'
                                            value={activity.credits ? activity.credits : ''}
                                            onChange={((e, {value}) => onChange_credit_value(activity, value))}
                                            onBlur={((e) => onBlur_credit_value(activity))}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <CMP_CREDIT_ACTIVITY_LIST
                            parent_id={activity.id}
                            activities={activities}
                            onChange_activity={onChange_activity}
                            display_date={display_date}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className='item_wrapper'>
                        <div className='item_description' style={{ paddingRight: display_date ? '1rem' : '4rem' }}>
                            {activity.description}
                        </div>
                        {activity.allow_credit_entry === 'YES' && (
                            <>
                                {display_date &&
                                    <div className='item_date'>
                                        <DATEPICKER
                                            property='activity_date'
                                            value={activity.activity_date ? new Date(activity.activity_date) : ''}
                                            placeholder={t('YYYY-MM-DD')}
                                            onChange={((e, {value}) => onChange_date(activity, value))}
                                        />
                                    </div>
                                }
                                <div className='item_credits'>
                                    <INPUT
                                        property='credits'
                                        value={activity.credits ? activity.credits : ''}
                                        onChange={((e, {value}) => onChange_credit_value(activity, value))}
                                        onBlur={((e) => onBlur_credit_value(activity))}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <CMP_CREDIT_ACTIVITY_LIST
                        parent_id={activity.id}
                        activities={activities}
                        onChange_activity={onChange_activity}
                        display_date={display_date}
                    />
                </>
            )}
        </React.Fragment>
    ))}
    </>
);
}