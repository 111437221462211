import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';
import { Button } from 'semantic-ui-react';

import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import { DATEPICKER, SELECT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';

import './credit_tracking_by_credential.css'

export default function CREDIT_TRACKING_BY_CREDENTIAL() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential_name', sortorder: 'ascending' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    
    const [ var_results, set_results ] = useState([]);
    const [ var_enddate, set_enddate ] = useState(new Date());
    const [ var_errors, set_errors ] = useState([]);
    const [ var_startdate, set_startdate ] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));
    const [ var_holder_status, set_holder_status ] = useState('ACTIVE');
    

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let errors = [];
        if (var_startdate && var_enddate && datelib.iso_to_date_string(var_enddate, true) < datelib.iso_to_date_string(var_startdate, true)) {
            errors.push({property: 'enddate', description: t(`To date cannot be before From date`)});
        }
        if (!var_startdate) {
            errors.push({property: 'startdate', description: t(`From date is required`)});
        }
        if (!var_enddate) {
            errors.push({property: 'enddate', description: t(`To date is required`)});
        }
        set_errors(errors);

        if (errors.length === 0) {
            set_refreshtable(!var_refreshtable);
            populate_records({ ...var_populateconfig, offset: 0, filter: {} }, var_startdate, var_enddate, var_holder_status);
        } else {
            set_totalrows(0);
            set_results([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_startdate, var_enddate, var_holder_status ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, startdate = var_startdate, enddate = var_enddate, holder_status = var_holder_status) {
        if (var_errors.length > 0) return;

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_post_credit_tracking_by_credential(limit, offset, sortby, sortorder, filter, null, startdate, enddate, holder_status);
            set_totalrows(results.totalrows);
            set_results(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        if (var_errors.length > 0) return [];

        try {
            return (await API_post_credit_tracking_by_credential(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername, var_startdate, var_enddate, var_holder_status)).results;
        } catch (e) {
            console.log(e);
        }

    }

    async function download_records(limit, offset) {
        if (var_errors.length > 0) return [];

        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_post_credit_tracking_by_credential(limit, offset, sortby, sortorder, filter, null, var_startdate, var_enddate, var_holder_status)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_credit_tracking_by_credential(limit, offset, sortby, sortorder, filter, filtername, startdate, enddate, holder_status) {
        return API.post('reports', `/post-credit-tracking-by-credential`,
            {
                queryStringParameters: {
                    limit,
                    offset,
                    sortby,
                    sortorder,
                    filtername,
                    tz: datelib.timezone,
                    startdate: datelib.iso_to_date_string(startdate),
                    enddate: datelib.iso_to_date_string(enddate),
                    holder_status
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onChange_enddate(e, { value }) {
        set_enddate(value);
    }

    function onChange_startdate(e, { value }) {
        set_startdate(value);
    }

    function onChange_status(status) {
        set_holder_status(status);
    }

    function onClick_credential(id) {
        navigate(`/org/credentials/${id}`);
    }


    function onClick_num_individuals(id) {
        const startdate = datelib.iso_to_date_string(var_startdate);
        const enddate = datelib.iso_to_date_string(var_enddate);
        navigate(`/org/reports/credit_tracking_by_holder/?credential_id=${encodeURIComponent(id)}${startdate && `&startdate=${encodeURIComponent(startdate)}`}${enddate && `&enddate=${encodeURIComponent(enddate)}`}`);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Reports') }]}
                current_page_text={t('Credit tracking by credential')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular' id='hdr_reports_credit_tracking_by_credential'>{t('Credit tracking by credential')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>
                <div className='card rounded-lg shadow-sm' id='crd_reports_credit_tracking_by_credential'>
                <Table
                    id='credit_tracking_by_credential'
                    loading={var_loading}
                    ready={var_ready}
                    loadingerror={var_loadingerror}
                    lockcolumns={0}
                    refresh={var_refreshtable}
                    totalrows={var_totalrows}
                    populateconfig={var_populateconfig}
                    populatefilterfunction={populate_filters}
                    downloadname={t('Credit tracking by credential')}
                    downloadfunction={download_records}
                    onChange={populate_records}
                >
                    <Table.OptionsButtons>
                        <div className='options_group_wrapper' role='form' id='opt_credit_tracking_by_credential' aria-labelledby='hdr_reports_credit_tracking_by_credential'>
                            <div className='option_wrapper'>
                                <div className='option_label text--sm-medium'>{t('From')}</div>
                                <DATEPICKER
                                    property='startdate'
                                    label={t('From')}
                                    value={var_startdate}
                                    onChange={onChange_startdate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>
                            <div className='option_wrapper'>
                                <div className='option_label text--sm-medium'>{t('To')}</div>
                                <DATEPICKER
                                    property='enddate'
                                    label={t('To')}
                                    value={var_enddate}
                                    onChange={onChange_enddate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>

                            <div className='option_wrapper'>
                                <div className='option_label text--sm-medium'>{t('Holder status')}</div>
                                <SELECT 
                                    property='holder_status'
                                    value={var_holder_status || ''}
                                    onChange={(e, data) => onChange_status(data.value)}
                                    placeholder={t('Select')}
                                    options={[
                                        { value: 'ALL', text: t('All') },
                                        { value: 'ACTIVE', text: t('Active') },
                                        { value: 'INVITED', text: t('Invited') },
                                        { value: 'ARCHIVE', text: t('Inactive') },
                                    ]}
                                    errors={var_errors}
                                />
                            </div>
                        </div>

                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='num_individuals' datatype='number' filtertype='number'>{t('HOLDERS')}</Table.HeaderCell>
                            <Table.HeaderCell field='total_credits' datatype='number' filtertype='number'>{t('CREDITS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_results.map(item =>
                            <Table.Row key={item.credential_id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_credential(item.credential_id)}>
                                        {item.credential_name}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className={auth.has_report('/org/reports/credit_tracking_by_holder') && 'text--anchor'}>
                                    {auth.has_report('/org/reports/credit_tracking_by_holder') ?
                                        <Button className='tertiary' onClick={() => onClick_num_individuals(item.credential_id)}>
                                            {item.num_individuals}
                                        </Button>
                                    : <>{item.num_individuals}</>}
                                </Table.Cell>
                                <Table.Cell>{form_helper.pad_decimal_string(item.total_credits, 2)}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
            </div>
        </div>
    )
};

