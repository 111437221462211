import React from 'react';
import { Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import CRD_LINKEDIN from './crd_linkedin/crd_linkedin';
import CMP_CREDENTIAL_SHARING from 'components/cmp_credential_sharing/cmp_credential_sharing';
import CRD_VC_ISSUANCE from './crd_vc_issuance/crd_vc_issuance';
import CRD_OID4VC_ISSUANCE from './crd_oid4vc_issuance/crd_oid4vc_issuance';
import { useTranslation } from 'react-i18next';
import 'i18n';


export default function TAB_CREDENTIAL_SHARING({ credential, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================
    if (!credential) {
        return null;
    } else {
        return (
            <>
                {/* TODO: Do not show linkedin section on internal credentials based on ALPHA-2095 */}
                { (credential.has_customsecurity === 'YES' || credential.issued_external === 'YES') ?
                    <Message
                        warning
                        icon={<Icon name={'warning'} className='icon' />}
                        header={t('Sharing has been disabled due to the security restrictions applied to this credential.')}
                    />
                    :
                    <Message
                        warning
                        icon={<Icon name={'warning'} className='icon' />}
                        header={t('Sharing a credential may publicly reveal details about the credential and any associated pre-qualifications. Depending on the credential, this could include personal or sensitive information.')}
                    />
                }
                { credential.has_customsecurity !== 'YES' && credential.issued_external !== 'YES' && credential.verification_status === 'VALID' && <CRD_LINKEDIN credential={credential} /> }
                { credential.verification_status === 'VALID' && credential.verifiable_credential === 'YES' && credential.issued_external !== 'YES' && credential.proof_type === 'JWT' &&
                    <CRD_VC_ISSUANCE credential={credential} />
                }
                { credential.has_customsecurity !== 'YES' && credential.verification_status === 'VALID' && credential.verifiable_credential === 'YES' && credential.issued_external !== 'YES' &&
                    credential.display_share_to_midy === 'YES' &&
                    <CRD_OID4VC_ISSUANCE credential={credential} />
                }
                { credential.has_customsecurity !== 'YES' && credential.issued_external !== 'YES' &&
                    <CMP_CREDENTIAL_SHARING credential={credential} />
                }
            </>
        );
    }
}