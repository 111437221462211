import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import CRD_TEMPLATE_ACTIVITY from './crd_template_activitity/crd_template_activity';

function TAB_TEMPLATE_ACTIVITY({ activetab, template_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'ACTIVITY') {
            window.scrollTo(0, 0);
        }
    }, [ activetab ]);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <CRD_TEMPLATE_ACTIVITY activetab={activetab} template_id={template_id} />
    );
}

TAB_TEMPLATE_ACTIVITY.propTypes = {
    activetab: propTypes.string.isRequired,
    template_id: propTypes.string.isRequired,
};

export default TAB_TEMPLATE_ACTIVITY;
