import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DATEPICKER, FORM_INPUT, FORM_SELECT, FORM_TEXTAREA, INPUT, SELECT, SELECT_RADIO } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import { NIL as NIL_UUID } from 'uuid';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';
import auth from 'libs/auth-lib';

import CMP_FILEUPLOAD from 'components/cmp_fileupload/cmp_fileupload';

import './cmp_addcredential.css';

/************
 * NOTE: This is used by the individual on their credential page
 * as well as by an admin on the worker profile - credentials tab
 ************/
export default function CMP_ADDCREDENTIAL({ display, onClose, onChange, default_credential_category, assignment, individual }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_issuer_selectiontype, set_issuer_selectiontype ] = useState('SELECT');
    const [ var_credential_selectiontype, set_credential_selectiontype ] = useState('SELECT');
    const [ var_issuer_list, set_issuer_list ] = useState([]);
    const [ var_credential_list, set_credential_list ] = useState([]);
    const [ var_credential_category_list, set_credential_category_list ] = useState([]);
    const [ var_credential_disabled, set_credential_disabled ] = useState(true);
    const [ var_details_disabled, set_details_disabled ] = useState(true);
    const [ var_disabled, set_disabled ] = useState(false);
    const [ var_footprint, set_footprint ] = useState({ prequalifiers: [] });
    const [ var_single_issuance_block, set_single_issuance_block ] = useState(false);
    const [ var_credential, set_credential ] = useState({});
    const [ var_custom_workflow, set_custom_workflow ] = useState({});
    const [ var_fileupload_status, set_fileupload_status] = useState(null);
    const [ var_credential_to_save, set_credential_to_save ] = useState(null);
    const [ var_step, set_step ] = useState('1');
    const [ var_requirement_options, set_requirement_options ] = useState([]);
    const [ var_selected_requirements, set_selected_requirements ] = useState([]);
    const [ var_results, set_results ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_hide_self_verified, set_hide_self_verified ] = useState(false);

    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_modal, set_modal ] = useState(null);


    const SELF_VERIFIED_FOOTPRINT = {
        credential_id: NIL_UUID,
        issuedrequired: 'NO',
        expires: 'YES',
        expires_amount: null,
        expires_unit: null,
        expires_required: 'NO',
        has_credential_number: 'YES',
        credential_number_required: 'NO',
        credential_number_label: t('Credential number'),
        has_holder: 'NO',
        has_credential_category: 'YES',
        credits_required_for_issuance: 'NO',
        contains_pii: 'NO',
        prequalifiers: []
    };

    const DEFAULT_FOOTPRINT = {
        credential_id: NIL_UUID,
        issuedrequired: 'NO',
        expires: 'YES',
        expires_amount: null,
        expires_unit: null,
        expires_required: 'NO',
        has_credential_number: 'YES',
        credential_number_required: 'NO',
        credential_number_label: t('Credential number'),
        has_holder: 'NO',
        has_credential_category: 'NO',
        credits_required_for_issuance: 'NO',
        contains_pii: 'NO',
        prequalifiers: []
    };

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content,a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {

            set_modal(document.querySelector('#mdl__addcredential'));

            // reset and populate issuers
            set_issuer_selectiontype('SELECT');
            set_credential_selectiontype('SELECT');
            set_single_issuance_block(false);
            set_issuer_list([]);
            set_credential_list([]);
            set_credential_disabled(true);
            set_details_disabled(true);
            set_footprint(DEFAULT_FOOTPRINT);
            set_credential({ credential_category_id: default_credential_category.toUpperCase() === 'ALL' ? null : default_credential_category.toUpperCase() });
            set_custom_workflow({});
            set_hide_self_verified(false);
            set_fileupload_status('RESET');
            set_step('1');

            set_save_error(null);
            set_errors([]);
            populate_issuer_list();
            populate_credential_category_list();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            save_credential(var_credential_to_save);
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    useEffect(() => {
        populate_issuer_info();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_credential.issuer_id]);


    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_issuer_list() {
        set_processing(true);
        try {
            let results = await API_get_issuer_list();
            if (results.length === 1 && results[0].hide_other_issuers === 'YES') {
                set_hide_self_verified(true);
                onChange_input(null, { name: 'issuer_id', value: results[0].id });
            }
            set_issuer_list(results.map(item => ({ value: item.id, text: item.name, hide_other_issuers: item.hide_other_issuers })));
        } catch (e) {
            set_save_error(t('There was a problem loading.  Please try again later'));
        }
        set_processing(false);
    }

    async function populate_issuer_info() {
        set_credential_list([]);
        if (!var_credential.issuer_id) {
            set_custom_workflow({});
            return;
        }
        let results = await API_get_issuer_addcredential_info(var_credential.issuer_id);
        let { fields, preload } = (results.custom_workflow && results.custom_workflow.custom_workflow_fields) ? results.custom_workflow.custom_workflow_fields : {};
        results.custom_workflow.fields = fields;
        set_disabled(results.custom_workflow.custom_workflow_fields && results.custom_workflow.custom_workflow_fields.disabled ? results.custom_workflow.custom_workflow_fields.disabled : false);
        delete results.custom_workflow.custom_workflow_fields;
        set_custom_workflow(results.custom_workflow || {});
        let credential = var_credential;
        if (credential.credential_category_id === null) {
            credential.credential_category_id = results.custom_workflow.default_credential_category_id;
        }
        if (preload) {
            preload_custom_workflow(preload, credential, results.custom_workflow);
        }
        if (results.credential_list) {
            set_credential_list(results.credential_list.map(item => ({ value: item.id, text: item.name })));
        } else {
            set_credential_list([]);
        }
    }

    async function populate_credential_category_list() {
        let results = (await API_get_credential_category_list()).filter(item => item.id !== 'ASSET_INSPECTION_REPORT');
        let transformed = results.map(item => ({ value: item.id, text: t(item.id) }));
        transformed = transformed.sort((a, b) => a.text - b.text);
        set_credential_category_list(transformed);
    }

    async function populate_footprint(credential_id) {
        let footprint = await API_get_footprint(credential_id);
        footprint.has_holder = var_custom_workflow.subject_id ? 'YES' : 'NO';
        footprint.has_credential_category = 'NO';
        footprint.expires_required = footprint.expires;
        let block_issuance = footprint.has_credential === 'YES' && footprint.single_issuance === 'YES';
        set_single_issuance_block(block_issuance);
        set_details_disabled(block_issuance);
        set_footprint(footprint);
    }

    function preload_custom_workflow(type, credential, custom_workflow) {
        switch (type) {
            case 'preload_bis':
                credential.lastname = individual ? individual.lastname : auth.lastname;
                break;
            case 'preload_cannamm':
            case 'preload_wkt':
                let link_field = custom_workflow.fields.find(item => item.type === 'static text');
                if (link_field.translate) {
                    for (let i = 2; i < link_field.value.length; i++ ) {
                        link_field.value[i] = t(link_field.value[i]);
                    }
                    link_field.translate = false;
                }
                let new_link = <>{link_field.value[0]} <a href={link_field.value[1]} className='link' target='_blank' rel='noreferrer'>{link_field.value[2]}</a> {link_field.value[3]}</>
                link_field.value.splice(0, 4, new_link);
                set_custom_workflow(custom_workflow);
                break;
            default:
                throw new Error('Unknown type');
        }
    }

    async function save_credential(credential) {
        try {
            let result = await API_post_credential(credential);
            let individual_credential_id = result.individual_credential_id;
            if (result && result.verification_succeeded === false) {
                set_save_error(t('Unable to find your credential.  Please check your inputs and try again.'));
                return;
            } else if (result && result.credential_id) {
                credential.credential_id = result.credential_id;
            }
            let issuer_name = credential.dependantorg ? credential.dependantorg : var_issuer_list.find(item => item.value === credential.issuer_id).text;
            let credential_name = (result && result.credential_name) ? result.credential_name
                : credential.dependant_credential ? credential.dependant_credential : var_credential_list.find(item => item.value === credential.credential_id).text;
            set_results({ issuer_name, credential_name, credential_category_id: credential.credential_category_id });

            let expires = (result && result.expires !== undefined) ? result.expires : credential.expires;
            if (var_credential_selectiontype === 'SELECT' && individual && individual.id !== auth.id && (!expires || expires >= datelib.localdate_to_utc_midnight_epoch(new Date()))) {
                let requirements = await API_get_org_available_requirements(credential);
                if (requirements.can_add_requirements === 'YES') {
                    if (requirements.requirements.some(item => item.history_status === 'VALID' && item.already_assigned === 'NO')) {
                        set_requirement_options(requirements.requirements.map(item => ({
                            value: item.id,
                            primary_text: item.name,
                            secondary_text: item.history_status !== 'VALID' ? t('Not pre-approved') : item.already_assigned === 'YES' ? t('Already has requirement') : null,
                            disabled: item.history_status !== 'VALID' || item.already_assigned === 'YES'
                        })));
                        set_step('2 - ADD REQUIREMENT');
                        return;
                    } else {
                        set_requirement_options(requirements.requirements.map(item => ({
                            value: item.id,
                            primary_text: item.name,
                            secondary_text: item.history_status === 'VALID' ? t('Already pre-approved')
                                : item.history_status === 'INVALID' ? t('Not accepted') : item.already_assigned === 'YES' ? t('Already has requirement') : null,
                            disabled: item.history_status !== null || item.already_assigned === 'YES'
                        })));
                        set_step('2 - ADD HISTORICAL');
                        return;
                    }
                }
            }
            onChange && onChange(issuer_name, credential_name, credential.credential_category_id, individual_credential_id, credential.credential_id);
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_issuer_list() {
        return API.get('credentials', `/get-issuer-list${individual ? ('/' + individual.organization_id) : ''}`);
    }

    function API_get_issuer_addcredential_info(issuer_id) {
        return API.get('credentials', `/get-issuer-addcredential-info/${issuer_id}${individual ? ('/' + individual.id) : ''}`);
    }

    function API_get_footprint(credential_id) {
        return API.get('credentials', `/get-footprint/${credential_id}${individual ? '/' + individual.id : ''}${assignment ? '/' + assignment.location_id : ''}`,
            { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_get_credential_category_list() {
        return API.get('credentials', '/get-credential-category-list');
    }

    function API_post_credential(credential) {
        if (var_custom_workflow.API_post && (var_custom_workflow.fields || credential.credential_id)) {
            return API.post(var_custom_workflow.API_service, '/' + var_custom_workflow.API_post, {
                queryStringParameters: {
                    tz: datelib.timezone
                },
                body: credential
            });
        } else {
            return API.post('credentials', '/post-credential', {
                queryStringParameters: {
                    tz: datelib.timezone
                },
                body: credential
            });
        }
    }

    function API_get_org_available_requirements(credential) {
        return API.get('requirements', `/get-org-available-requirements/${individual.id}/${credential.credential_id}${assignment ? '/' + assignment.location_id : ''}`);
    }

    function API_post_requirement_historical() {
        return API.post('requirements', '/post-requirement-historical', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                location_id: assignment ? assignment.location_id : null,
                issuer_id: var_credential_to_save.issuer_id,
                credential_id: var_credential_to_save.credential_id,
                requirement_ids: var_selected_requirements
            }});
    }

    function API_post_org_requirement_assignments() {
        return API.post('requirements', '/post-org-requirement-assignments', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                location_id: assignment ? assignment.location_id : null,
                individual_id: individual.id,
                requirement_ids: var_selected_requirements
            }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(e, { name, value }) {
        set_errors([]);
        set_save_error(null);
        let credential = {...var_credential};
        switch (name) {
            case 'issuer_id':
                credential.issuer_id = value;
                credential.credential_id = null;
                set_credential_disabled(false);
                break;
            case 'credential_id':
                credential.credential_id = value;
                set_details_disabled(false);
                populate_footprint(value);
                break;
            case 'issued':
                if (value === credential.issued) return;
                credential.issued = value
                if (value !== null && var_footprint.expires === 'YES' && var_footprint.expires_unit) {
                    credential.expires =
                        datelib.utcmoment_to_local_midnight_date(
                            datelib.localdate_to_utc_midnight_moment(value).add(var_footprint.expires_amount, var_footprint.expires_unit + 's')
                        );
                }
                break;
            default:
                credential[name] = value;
                break;
        }
        set_credential(credential);
    }

    function onClick_toggle_issuer_selectiontype() {
        set_errors([]);
        set_save_error(null);
        let new_selectiontype = var_issuer_selectiontype === 'SELECT' ? 'INPUT' : 'SELECT';
        set_credential_selectiontype(new_selectiontype);
        set_issuer_selectiontype(new_selectiontype);
        if (new_selectiontype === 'INPUT') {
            set_footprint(SELF_VERIFIED_FOOTPRINT);
            set_credential_disabled(false);
            set_details_disabled(false);
            set_single_issuance_block(false);
            set_custom_workflow({});
        } else {
            set_footprint(DEFAULT_FOOTPRINT);
            set_credential_disabled(!var_credential.issuer_id);
            set_details_disabled(!var_credential.issuer_id || !var_credential.credential_id);
            if (var_credential.issuer_id) {
                populate_issuer_info();
                if (var_credential.credential_id) {
                    populate_footprint(var_credential.credential_id);
                }
            }
        }
    }

    function onClick_toggle_credential_selectiontype() {
        set_errors([]);
        set_save_error(null);
        let new_selectiontype = var_credential_selectiontype === 'SELECT' ? 'INPUT' : 'SELECT';
        set_credential_selectiontype(new_selectiontype);
        if (new_selectiontype === 'INPUT') {
            set_footprint(SELF_VERIFIED_FOOTPRINT);
            set_details_disabled(false);
            set_single_issuance_block(false);
            set_custom_workflow({});
        } else {
            set_footprint(DEFAULT_FOOTPRINT);
            set_details_disabled(!var_credential.credential_id);
            populate_issuer_info();
            if (var_credential.credential_id) {
                populate_footprint(var_credential.credential_id);
            }
        }
    }

    function onChange_document(details) {
        set_credential_to_save({...var_credential_to_save, filename: details.filename, submittedfilename: details.display_name});
    }

    async function onClick_save() {
        set_save_error(null);
        let errors = [];
        let credential = {...var_credential};
        // validation
        if (var_issuer_selectiontype === 'SELECT' && !credential.issuer_id) {
            errors.push({ property: 'issuer_id', description: t('Issuer is required') });
        } else if (var_issuer_selectiontype === 'INPUT' && !form_helper.validate_required_string(credential.dependantorg)) {
            errors.push({ property: 'dependantorg', description: t('Issuer is required') });
        }
        if (var_custom_workflow.fields) {
            var_custom_workflow.fields.forEach(item => {
                if (item.required && !form_helper.validate_required_string(credential[item.name])) {
                    errors.push({ property: item.name, description: (item.translate ? t(item.label) : item.label) + ' ' + t('is required') });
                }
            });
        } else {
            if (var_credential_selectiontype === 'SELECT' && (!credential.credential_id || credential.credential_id === NIL_UUID)) {
                errors.push({ property: 'credential_id', description: t('Credential is required') });
            } else if (var_credential_selectiontype === 'INPUT' && !form_helper.validate_required_string(credential.dependant_credential)) {
                errors.push({ property: 'dependant_credential', description: t('Credential is required') });
            }
            if (var_footprint.prequalifiers.length === 0 && var_footprint.credits_required_for_issuance !== 'YES') {
                if (var_footprint.has_credential_category === 'YES' && credential.credential_category_id === null) {
                    errors.push({ property: 'credential_category_id', description: t('Type is required') });
                }
                if (var_footprint.issuedrequired === 'YES' && !credential.issued) {
                    errors.push({ property: 'issued', description: t('Issued is required') });
                }
                if (var_footprint.expires_required === 'YES' && !credential.expires) {
                    errors.push({ property: 'expires', description: t('Expires is required') });
                }
                if (var_footprint.has_credential_number === 'YES' && var_footprint.credential_number_required === 'YES' && !form_helper.validate_required_string(credential.credential_number)) {
                    errors.push({ property: 'credential_number', description: var_footprint.credential_number_label + ' ' + t('is required') });
                }
                let date_validation;
                if (credential.issued) {
                    date_validation = form_helper.validate_date(credential.issued, null, credential.expires);
                    if (date_validation.is_less_than_minimum) {
                        errors.push({ property: 'issued', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
                    } else if (date_validation.is_greater_than_maximum) {
                        errors.push({ property: 'issued', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
                    }
                }
                if (credential.expires) {
                    date_validation = form_helper.validate_date(credential.expires, credential.issued);
                    if (date_validation.is_less_than_minimum) {
                        errors.push({ property: 'expires', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
                    } else if (date_validation.is_greater_than_maximum) {
                        errors.push({ property: 'expires', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
                    }
                }
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // create new object with only required properties
        let new_credential = {};
        if (var_issuer_selectiontype === 'SELECT' && var_credential_selectiontype === 'SELECT') {
            new_credential.issuer_id = credential.issuer_id;
        } else if (var_issuer_selectiontype === 'SELECT') {
            new_credential.dependantorg = var_issuer_list.find(item => item.value === credential.issuer_id).text;
        } else {
            new_credential.dependantorg = credential.dependantorg;
            form_helper.cleanse_string_property(new_credential, 'dependantorg');
        }
        if (var_custom_workflow.fields) {
            var_custom_workflow.fields.forEach((item) => {
                new_credential[item.name] = credential[item.name];
                form_helper.cleanse_string_property(new_credential, item.name);
            });
        } else {
            if (var_credential_selectiontype === 'SELECT') {
                new_credential.credential_id = credential.credential_id;
            } else {
                new_credential.dependant_credential = credential.dependant_credential;
                form_helper.cleanse_string_property(new_credential, 'dependant_credential');
            }
            if (var_footprint.prequalifiers.length === 0 && var_footprint.credits_required_for_issuance !== 'YES') {
                if (var_footprint.has_credential_category === 'YES') {
                    new_credential.credential_category_id = credential.credential_category_id;
                }
                new_credential.issued = credential.issued ? datelib.localdate_to_utc_midnight_epoch(credential.issued) : null;
                new_credential.expires = (var_footprint.expires === 'YES' && credential.expires) ? datelib.localdate_to_utc_midnight_epoch(credential.expires) : null;
                if (var_footprint.has_credential_number === 'YES') {
                    new_credential.credential_number = credential.credential_number;
                    form_helper.cleanse_string_property(new_credential, 'credential_number');
                }
                new_credential.subject_id = var_custom_workflow.subject_id;
                if (var_footprint.contains_pii === 'YES') {
                    new_credential.pii = credential.pii;
                    form_helper.cleanse_string_property(new_credential, 'pii');
                }
            }
        }

        // for admin entering, add the relevant fields
        if (assignment) {
            new_credential.location_id = assignment.location_id;
        }
        if (individual) {
            new_credential.individual_id = individual.id;
        }
        new_credential.credential_category_id = var_footprint.credential_category_id || credential.credential_category_id;
        set_credential_to_save(new_credential);

        // save
        set_processing(true);
        document.getElementById('mdl__addcredential').scrollTo(0, 0);
        try {
            if (var_fileupload_status === 'UPLOAD READY') {
                set_fileupload_status('START UPLOAD');
            } else {
                save_credential(new_credential);
            }
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
    }

    function onClick_step2yes() {
        set_step('3' + var_step.substring(1));
    }

    function onClick_step2no() {
        onChange && onChange(var_results.issuer_name, var_results.credential_name, var_results.credential_category_id);
    }

    function onChange_requirements(value) {
        set_selected_requirements(value);
        set_save_error(null);
        set_errors([]);
    }

    async function onClick_step3save() {
        set_save_error(null);
        let errors = [];
        // validation
        if (var_selected_requirements.length === 0) {
            errors.push({ property: 'requirements', description: t('Please select at least one requirement.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        set_processing(true);
        document.getElementById('mdl__addcredential').scrollTo(0, 0);
        try {
            if (var_step === '3 - ADD HISTORICAL') {
                await API_post_requirement_historical();
            }
            await API_post_org_requirement_assignments();
            onChange && onChange(var_results.issuer_name, var_results.credential_name, var_results.credential_category_id);
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }

    function onClick_step3cancel() {
        onChange && onChange(var_results.issuer_name, var_results.credential_name, var_results.credential_category_id);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            {var_step === '1'
                ? render_step_1()
                : (var_step === '2 - ADD REQUIREMENT' || var_step === '2 - ADD HISTORICAL')
                ? render_step_2()
                : render_step_3()
            }

        </>
    );

    function render_step_1() {
        return (
            <Modal
                dimmer='inverted'
                onClose={onClose}
                open={display}
                closeOnEscape={var_modal_close_on_escape}
                closeOnDimmerClick={false}
                id='mdl__addcredential'
                aria-modal='true'
                role='dialog'
                aria-labelledby='hdr_add_credential'>
                <div className='modal__header'>
                    <div className='modal__header__left'>
                        <div className='display--xs-medium' id='hdr_add_credential'>{t('Add a credential')}</div>
                    </div>
                </div>
                <Form className='modal__content' tabIndex='0' id='add_credential_form' aria-labelledby='hdr_add_credential'>
                    {var_save_error &&
                        <Message error header={var_save_error} />
                    }
                    {var_errors.length > 0 &&
                        <Message error
                                icon={<Icon name='error' className='icon' />}
                                header={t('There are some errors with your inputs')}
                        />
                    }
                    {!individual &&
                        <Message
                            info
                            icon={<Icon name='info' className='icon' />}
                            header={t("Organizations connected to you will, by default, have access to view this credential. To manage access, navigate to the 'Access' tab within the credential once it has been added to your wallet.")}
                        />
                    }

                    {
                        var_single_issuance_block &&
                        <Message
                            warning
                            icon={<Icon name='warning' className='icon' />}
                            content={t(`This credential cannot be added, as it is limited to a single issuance per individual.`)}
                        />

                    }

                    <div className='detailsgroup issuer_credential_row'>
                        <div className='detailsgroup__label text--sm-medium'>{t('Credential issuer')}</div>
                        <div>
                            {
                                var_issuer_selectiontype === 'SELECT'
                                ? <SELECT
                                    property='issuer_id'
                                    value={var_credential.issuer_id || ''}
                                    onChange={onChange_input}
                                    onOpen={() => set_modal_close_on_escape(false)}
                                    onClose={() => set_modal_close_on_escape(true)}
                                    placeholder={t('Select')}
                                    options={var_issuer_list}
                                    disabled={var_issuer_list.length === 1 && var_issuer_list[0].hide_other_issuers === 'YES'}
                                    errors={var_errors}
                                    aria_label='Search for the issuer of the credential'
                                />
                                : <INPUT
                                    property='dependantorg'
                                    value={var_credential.dependantorg || ''}
                                    onChange={onChange_input}
                                    placeholder={t('Issuer name')}
                                    maxLength={100}
                                    errors={var_errors} />
                            }
                            {   var_hide_self_verified
                                ? null
                                : var_issuer_selectiontype === 'SELECT'
                                    ? <Button type='button' className='tertiary' onClick={onClick_toggle_issuer_selectiontype}>{t('I can\'t find my issuer')}</Button>
                                    : <Button type='button' className='tertiary' onClick={onClick_toggle_issuer_selectiontype}>{t('See issuer list')}</Button>
                            }
                        </div>
                    </div>

                    {var_custom_workflow.fields
                        ? render_custom_fields()
                        : var_footprint.prequalifiers.length > 0
                        ? render_prequalifiers()
                        : var_footprint.credits_required_for_issuance === 'YES'
                        ? render_credits_required()
                        : render_standard_inputs()}
                </Form>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='primary' onClick={onClick_save} disabled={var_disabled || var_single_issuance_block}>{t('Add')}</Button>
                        <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                    </div>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </Modal>
        );
    }

    function render_credential_input() {
        return (
            <div className='detailsgroup issuer_credential_row'>
                <div className='detailsgroup__label text--sm-medium'>{t('Credential')}</div>
                <div>
                    {
                        var_credential_selectiontype === 'SELECT'
                        ? <SELECT
                            property='credential_id'
                            value={var_credential.credential_id || ''}
                            onChange={onChange_input}
                            onOpen={() => set_modal_close_on_escape(false)}
                            onClose={() => set_modal_close_on_escape(true)}
                            placeholder={t('Select')}
                            options={var_credential_list}
                            disabled={var_credential_disabled}
                            errors={var_errors}
                            aria_label='Search for the name of the credential'
                        />
                        : <INPUT
                            property='dependant_credential'
                            value={var_credential.dependant_credential || ''}
                            onChange={onChange_input}
                            placeholder={t('Credential name')}
                            maxLength={100}
                            disabled={var_credential_disabled}
                            errors={var_errors} />
                    }
                    {   var_hide_self_verified
                        ? null
                        : (var_issuer_selectiontype === 'SELECT' && var_credential_selectiontype === 'SELECT')
                            ? <Button type='button' className='tertiary' disabled={var_credential_disabled} onClick={onClick_toggle_credential_selectiontype}>{t('I can\'t find my credential')}</Button>
                            : var_issuer_selectiontype === 'SELECT'
                            ? <Button type='button' className='tertiary' disabled={var_credential_disabled} onClick={onClick_toggle_credential_selectiontype}>{t('See credential list')}</Button>
                            : null
                    }
                </div>
            </div>
        );
    }

    function render_standard_inputs() {
        return (
            <>
                {render_credential_input()}
                {var_footprint.has_credential_category === 'YES' &&
                    <FORM_SELECT
                        property='credential_category_id'
                        label={t('Type')}
                        value={var_credential.credential_category_id || ''}
                        onChange={onChange_input}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('Select')}
                        options={var_credential_category_list}
                        disabled={var_details_disabled}
                        errors={var_errors} />
                }

                <FORM_DATEPICKER
                    property='issued'
                    label={t('Issued')}
                    value={var_credential.issued || ''}
                    onChange={(e, { value }) => onChange_input(e, {name: 'issued', value })}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('YYYY-MM-DD')}
                    minproperty={null}
                    maxproperty={var_credential.expires}
                    disabled={var_details_disabled}
                    errors={var_errors}
                />

                {var_footprint.expires === 'YES' &&
                    <FORM_DATEPICKER
                        property='expires'
                        label={t('Expires')}
                        value={var_credential.expires}
                        onChange={(e, { value }) => onChange_input(e, {name: 'expires', value })}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('YYYY-MM-DD')}
                        minproperty={var_credential.issued}
                        maxproperty={null}
                        disabled={var_details_disabled}
                        errors={var_errors}
                    />
                }

                {var_footprint.has_credential_number === 'YES' &&
                    <FORM_INPUT
                        property='credential_number'
                        label={t(var_footprint.credential_number_label)}
                        value={var_credential.credential_number || ''}
                        placeholder={var_footprint.credential_number_label}
                        onChange={onChange_input}
                        maxLength={100}
                        disabled={var_details_disabled}
                        errors={var_errors}
                    />
                }

                {var_footprint.has_holder === 'YES' &&
                    <FORM_INPUT
                        property='subject_id'
                        label={t('Holder ID')}
                        value={var_custom_workflow.subject_id || ''}
                        placeholder={t('Name')}
                        onChange={onChange_input}
                        maxLength={100}
                        disabled={true}
                        errors={var_errors}
                    />
                }

                {var_footprint.contains_pii === 'YES' &&
                    <FORM_TEXTAREA
                        property='pii'
                        label={t('PII')}
                        value={var_credential.pii || ''}
                        placeholder={t('PII')}
                        onChange={onChange_input}
                        maxLength={1000}
                        errors={var_errors}
                    />
                }

                {render_fileupload()}
            </>
        );
    }

    function render_custom_fields() {
        return (
            <div className='custom_fields'>
                {var_custom_workflow.fields.sort((a, b) => a.order - b.order).map((item, index) => {
                    if (item.type === 'static text') {
                        return (
                            <div className='detailsgroup' key={'custom_field_' + index}>
                                <div className='detailsgroup__label text--sm-medium'>
                                    {item.translate ? t(item.label) : item.label}
                                </div>
                                <div className='text_wrapper'>
                                    {item.value.map((value, valueindex) =>
                                        <div className='text__property' key={'value_' + valueindex}>
                                            {item.translate ? t(value) : value}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    } else if (item.type === 'image') {
                        return (
                            <div className='detailsgroup' key={'custom_field_' + index}>
                                <div className='detailsgroup__label text--sm-medium'>
                                    {item.translate ? t(item.label) : item.label}
                                </div>
                                <img style={item.style ? item.style : null} src={item.value} alt={item.alt} />
                            </div>
                        )
                    } else if (item.type === 'dropdown') {
                        return (
                            <FORM_SELECT
                                key={'custom_field_' + index}
                                property={item.name}
                                value={var_credential[item.name] || ''}
                                label={item.translate ? t(item.label) : item.label}
                                onChange={onChange_input}
                                onOpen={() => set_modal_close_on_escape(false)}
                                onClose={() => set_modal_close_on_escape(true)}
                                placeholder={item.translate ? t(item.placeholder) : item.placeholder}
                                options={item.options.map(option => ({ value: option.value, text: (item.translate_options) ? t(option.text) : option.text }))}
                                errors={var_errors}
                            />
                        )
                    } else {
                        return (
                            <FORM_INPUT
                                key={'custom_field_' + index}
                                property={item.name}
                                label={item.translate ? t(item.label) : item.label}
                                value={var_credential[item.name] || ''}
                                placeholder={item.translate ? t(item.placeholder) : item.placeholder}
                                onChange={onChange_input}
                                errors={var_errors}
                            />
                        );
                    }
                })}
            </div>
        );
      }

    function render_fileupload() {
        return (
            <div className='detailsgroup'>
                <div className='detailsgroup__label text--sm-medium'>{t('Document')}</div>
                <CMP_FILEUPLOAD
                    var_status={var_fileupload_status}
                    set_status={set_fileupload_status}
                    onChange_details={onChange_document}
                    single_or_multiple='MULTIPLE'
                    uploaddirectory='certificate' max_size={10} allowed_fileextensions={['pdf', 'png', 'jpg', 'jpeg']}
                    instructions={t('Upload any documents you may have to support your credential claim. Only PDF, JPG, and PNG file types are accepted.')} />
            </div>
        );
    }

    function render_prequalifiers() {
        return (
            <>
                {render_credential_input()}
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Qualifiers')}</div>
                    <div className='addcredential__prequalifiers_wrapper'>
                        <div className='addcredential__prequalifiers_header text--sm-medium'>{t('This credential has pre-qualification requirements that must be met, and maintained, for it to be considered valid.')}</div>
                        {var_footprint.prequalifiers.map(item =>
                            <div className='addcredential__prequalifiers_item' key={item.id}>
                                {item.status === 'VALID'
                                    ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                                    : <div><Icon name='warning_circle' className='prequalifiers_icon color--yellow-500' alt={t('Warning')} /></div>
                                }
                                <div className='text--sm-regular'>
                                    {item.short_description}
                                </div>
                            </div>
                        )}
                        {var_footprint.prequalifiers.some(item => item.status !== 'VALID') &&
                            <div className='text--sm-regular'>{t('Add this credential now and then complete any outstanding pre-qualification requirements.')}</div>
                        }
                    </div>
                </div>
            </>
        );
    }

    function render_credits_required() {
        return (
            <>
                {render_credential_input()}
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Credits')}</div>
                    <div className='flex-col'>
                        <div className='text--sm-medium'>{t('This credential requires credits for it to be considered valid.')}</div>
                        <div className='text--sm-regular'>{t('Add this credential now and then complete the credit requirements.')}</div>
                    </div>
                </div>
            </>
        )
    }

    function render_step_2() {
        let credential_name = var_credential_list.find(item => item.value === var_credential_to_save.credential_id).text;
        return (
            <Modal
                dimmer='inverted'
                onClose={onClose}
                open={display}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                id='mdl__addcredential'
                aria-modal='true'
                role='dialog'
                aria-labelledby='hdr_add_credential'>
                <div className="modal__header">
                    <div className='modal__header__left'>
                        <div className='display--xs-medium' id='hdr_add_credential'>{t('Add a credential')}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
                <div className='modal__content'>
                    <div className='text--md-regular'>
                        {var_step === '2 - ADD REQUIREMENT'
                            ? `${credential_name} ${t(`is a pre-approved credential for a requirement(s). Do you want to assign the requirement(s) to the individual`)}?`
                            : `${credential_name} ${t(`is not a pre-approved credential for any requirement. Do you want to add the credential to a requirement's pre-approved list and assign the requirement to the individual?`)}`
                        }
                    </div>
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='primary' onClick={onClick_step2yes}>{t('Yes')}</Button>
                        <Button className='secondary' onClick={onClick_step2no}>{t('No')}</Button>
                    </div>
                </div>

            </Modal>
        );
    }

    function render_step_3() {
        let credential_name = var_credential_list.find(item => item.value === var_credential_to_save.credential_id).text;
        return (
            <Modal
                dimmer='inverted'
                onClose={onClose}
                open={display}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                id='mdl__addcredential'
                aria-modal='true'
                role='dialog'
                aria-labelledby='hdr_add_credential'
                >
                <div className="modal__header">
                    <div className='modal__header__left'>
                        <div className='display--xs-medium' id='hdr_add_credential'>{t('Add a credential')}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
                <Form className='modal__content'>
                        {var_save_error &&
                            <Message error header={var_save_error} />
                        }
                        {var_errors.length > 0 &&
                            <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                            />
                        }
                    <div className='text--md-regular'>
                        {var_step === '3 - ADD REQUIREMENT'
                            ? t('Select requirement(s) you want to assign to this individual')
                            : `${t('Select requirement(s) you want to add')} ${credential_name}
                               ${t('as a pre-approved credential and assign the requirements to the individual')}`
                        }
                    </div>
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>{t('Requirements')}</div>
                        <Form.Field>
                            <SELECT_RADIO name='requirements' value={var_selected_requirements} options={var_requirement_options} single_or_multiple='MULTIPLE' onChange={onChange_requirements} errors={var_errors} />
                        </Form.Field>
                    </div>
                </Form>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='primary' onClick={onClick_step3save}>{t('Add')}</Button>
                        <Button className='secondary' onClick={onClick_step3cancel}>{t('Cancel')}</Button>
                    </div>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </Modal>
        );
    }

}