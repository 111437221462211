import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_DETAILS from './tab_details/tab_details';
import TAB_PREQUALIFICATIONS from './tab_prequalifications/tab_prequalifications';
import TAB_CREDIT_TASKS from './tab_credit_tasks/tab_credit_tasks';
import TAB_HOLDERS from './tab_holders/tab_holders';
import TAB_SECURITY from './tab_security/tab_security';



export default function CREDENTIALS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_credential_details, set_credential_details ] = useState({});
    const [ var_credential_settings, set_credential_settings ] = useState({});


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        populate_credential_details();
        populate_credential_settings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        } else {
            set_tab('DETAILS');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.credentialid]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credential_details() {
        try {
            let results = await API_get_org_credential_details();
            set_credential_details(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_credential_settings() {
        try {
            let results = await API_get_credential_settings();
            set_credential_settings(results);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credential_details() {
        return API.get('credentials', '/get-org-credential-details/' + params.credentialid);
    }

    function API_get_credential_settings() {
        return API.get('credentials', '/get-org-credential-settings/' + params.credentialid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    function onChange_settings() {
        populate_credential_details();
        populate_credential_settings();
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/credentials', text: t('Credentials') }]}
                current_page_text={var_credential_details.name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{var_credential_details.name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Credential')} id='tabs_credential_detail'>
                    <TABS.TAB name='DETAILS' label={t('Details')} />
                    <TABS.TAB name='PREQUALIFICATIONS' label={t('Pre-qualifications')} display={var_credential_details?.has_prequalifier === 'YES'} />
                    <TABS.TAB name='CREDIT_TASKS' label={t('Pre-qualifications')} display={var_credential_details?.credits_required_for_issuance === 'YES'} />
                    <TABS.TAB name='HOLDERS' label={t('Holders')} />
                    <TABS.TAB name='SECURITY' label={t('Security')} />
                </TABS>

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS credential_details={var_credential_details} credential_settings={var_credential_settings} onChange_details={populate_credential_details} onChange_settings={onChange_settings} />
                }

                {var_tab === 'PREQUALIFICATIONS' &&
                    <TAB_PREQUALIFICATIONS activetab={var_tab} credential={var_credential_details} has_verifiable_credential_feature={var_credential_settings.has_verifiable_credential_feature} onChange={populate_credential_details} />
                }

                {var_tab === 'CREDIT_TASKS' &&
                    <TAB_CREDIT_TASKS activetab={var_tab} credential={var_credential_details} onChange={populate_credential_details} />
                }

                {var_tab === 'HOLDERS' &&
                    <TAB_HOLDERS credential_id={params.credentialid} credential_details={var_credential_details} credential_settings={var_credential_settings} />
                }

                {var_tab === 'SECURITY' &&
                    <TAB_SECURITY credential_id={params.credentialid}/>
                }
            </div>
        </div>
    )
};