import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { CHECKBOX, SELECT } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';

import CMP_EDIT_PREQUALIFIER from 'components/requirements/cmp_edit_prequalifier/cmp_edit_prequalifier';

import './cmp_mdl_add_prequalifier.css';

function CMP_MDL_ADD_PREQUALIFIER({ display, onClose, onChange, requirement_id, requirement_is_active }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_step_no, set_set_no ] = useState('SELECT PREQUALIFIER TYPE');
    const [ var_prequalifier_type, set_prequalifier_type ] = useState(null);
    const [ var_prequalifier_type_options, set_prequalifier_type_options ] = useState([]);
    const [ var_existing_or_new, set_existing_or_new ] = useState(null);
    const [ var_existing_prequalifier_id, set_existing_prequalifier_id ] = useState(null);
    const [ var_existing_prequalifier_options, set_existing_prequalifier_options ] = useState([]);
    const [ var_new_prequalifier, set_new_prequalifier ] = useState({});
    const [ var_validate_new_prequalifier, set_validate_new_prequalifier ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {
            let lastelement = Array.from(var_modal.querySelectorAll('button:not([disabled])')).at(-1);

            //  set focus to first element within the modal
            var_modal.querySelector('.modal__content').focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key !== 'Tab') return;

                let firstelement = var_modal.querySelector('.modal__content');
                if (e.shiftKey && document.activeElement === firstelement) /* shift + tab */ {
                    lastelement.focus();
                    e.preventDefault();
                } else if (!e.shiftKey && document.activeElement === lastelement) /* tab */ {
                    firstelement.focus();
                    var_modal.querySelector('.modal__header').scrollIntoView();
                    e.preventDefault();
                }
            });
        }
    }, [var_modal]);

    useEffect(() => {
        if (display && var_modal) {
            //  set focus to first element within the modal
            var_modal.querySelector('.modal__content').focus();
            var_modal.scrollTo(0, 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_step_no ]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_requirement_add_prequalifier'));
            // reset state variables
            set_set_no('SELECT PREQUALIFIER TYPE');
            set_prequalifier_type(null);
            set_prequalifier_type_options([]);
            set_existing_or_new(null);
            set_existing_prequalifier_id(null);
            set_existing_prequalifier_options([]);
            set_new_prequalifier({});
            set_validate_new_prequalifier(false);
            set_errors([]);
            set_processing(false);
            set_modal_close_on_escape(true);
            get_prequalifier_type_options();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ display ]);

    useEffect(() => {
        if (var_new_prequalifier.document?.fileupload_status === 'UPLOAD COMPLETE') {
            save_new_prequalifier(var_new_prequalifier);
        } else if (var_new_prequalifier.document?.fileupload_status === 'UPLOAD FAIL') {
            set_errors([ { description: t('An unexpected error occurred. Please try again.') }]);
            set_processing(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_new_prequalifier.document?.fileupload_status ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function get_prequalifier_type_options() {
        set_prequalifier_type_options(await API_get_org_prequalifier_types());
    }

    async function get_existing_prequalifiers() {
        let data = requirement_id ? await API_get_org_available_requirement_prequalifiers() : [];
        if (data.length === 0) {
            let new_prequalifier = { requirement_id, prequalifier_type: var_prequalifier_type };
            switch (var_prequalifier_type) {
                case 'ATTESTATION':
                    new_prequalifier.attestation = {};
                    break;
                case 'CREDENTIAL/LIST':
                    new_prequalifier.prequalifier_type = 'CREDENTIAL';
                    new_prequalifier.prequalifier_subtype = 'SET LIST';
                    new_prequalifier.credential = { preapprovals: [] };
                    break;
                case 'CREDENTIAL/CREDIT':
                    new_prequalifier.prequalifier_type = 'CREDENTIAL';
                    new_prequalifier.prequalifier_subtype = 'CREDIT';
                    new_prequalifier.credential = { save_credit_value: 'NO', preapprovals: [] };
                    break;
                case 'DOCUMENT':
                    new_prequalifier.document = { fileupload_status: '' };
                    break;
                case 'SURVEY':
                    new_prequalifier.survey = {};
                    break;
                case 'VERIFIABLE_PRESENTATION':
                    let has_verification_template_feature = var_prequalifier_type_options.find(item => item.prequalifier_type === 'VERIFIABLE_PRESENTATION').additional_data === 'YES';
                    new_prequalifier.verifiable_presentation = {
                        has_verification_template_feature,
                        template_type: has_verification_template_feature ? 'LIST' : 'URL'
                    };
                    break;
                default:
                    break;
            }
            set_new_prequalifier(new_prequalifier);
            set_set_no('ENTER NEW');
        } else {
            set_existing_prequalifier_options(data.map(item => ({ value: item.id, text: item.name })));
            set_set_no('SELECT EXISTING OR NEW');
        }
    }

    async function save_existing_prequalifier() {
        set_processing(true);
        try {
            await API_post_org_requirement_existing_prequalifier();
            onChange();
        } catch {
            set_errors([ { description: t('An unexpected error occurred. Please try again.') }]);
        }
    }

    async function save_new_prequalifier(new_prequalifier) {
        try {
            await API_post_org_requirement_new_prequalifier(new_prequalifier);
            onChange();
        } catch {
            set_errors([ { description: t('An unexpected error occurred. Please try again.') }]);
        }
        set_processing(false);
    }

    function get_add_prequalifier_warning_text() {
        switch (var_prequalifier_type) {
            case 'ATTESTATION':
                return t('Adding this pre-qualification will require all holders to complete the new attestation. All ‘valid’ credentials will change to ‘In-progress’ until the attestation has been completed. This action can not be undone.')
            case 'CREDENTIAL/LIST':
            case 'CREDENTIAL/CREDIT':
                return t(`Adding this pre-qualification will require holders to submit a credential. 'Valid' pre-qualifications will change to 'In-progress' for those until a credential has been submitted. This action can not be undone.`);
            case 'DOCUMENT':
                return t('Adding this pre-qualification will require all holders to acknowledge the new document. All ‘valid’ pre-qualifications will change to ‘In-progress’ until the acknowledgement has been completed. This action can not be undone.');
            case 'SURVEY':
                return t('Adding this pre-qualification will require all holders to complete the new survey. All ‘valid’ pre-qualifications will change to ‘In-progress’ until the survey has been completed. This action can not be undone.');
            case 'VERIFIABLE_PRESENTATION':
                return t('Adding this pre-qualification will require all holders to present new verifiable credentials. All ‘valid’ pre-qualifications will change to ‘In-progress’ until the presentation has been completed. This action can not be undone.');
            default:
                return null;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_prequalifier_types() {
        return API.get('requirements2', '/get-org-prequalifier-types');
    }

    function API_get_org_available_requirement_prequalifiers() {
        return API.get('requirements2', `/get-org-available-requirement-prequalifiers/${requirement_id}/${var_prequalifier_type}`);
    }

    function API_post_org_requirement_existing_prequalifier() {
        return API.post('requirements2', '/post-org-requirement-existing-prequalifier', {
            body: {
                requirement_id,
                prequalifier_id: var_existing_prequalifier_id
            }
        });
    }

    function API_post_org_requirement_new_prequalifier(prequalifier) {
        return API.post('requirements2', '/post-org-requirement-new-prequalifier', { body: prequalifier });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_prequalifier_type(name) {
        set_prequalifier_type(name);
        set_errors([]);
    }

    function onChange_existing_or_new(type) {
        set_existing_or_new(type);
        set_errors([]);
    }

    function onChange_existing_prequalifier(e, { value }) {
        set_existing_prequalifier_id(value);
        set_errors([]);
    }

    function onChange_new_prequalifier(new_prequalifier) {
        set_new_prequalifier(new_prequalifier);
    }

    function onValidate_new_prequalifier(successful, new_prequalifier) {
        set_validate_new_prequalifier(false);
        if (successful) {
            set_processing(true);
            if (var_new_prequalifier.document?.fileupload_status === 'UPLOAD READY') {
                new_prequalifier.document.fileupload_status = 'START UPLOAD';
                set_new_prequalifier(new_prequalifier);
            } else {
                save_new_prequalifier(new_prequalifier);
            }
        }
    }

    function onClick_save() {
        let errors = [];
        switch (var_step_no) {
            case 'SELECT PREQUALIFIER TYPE':
                if (!var_prequalifier_type) {
                    errors.push({ description: t('Please select a pre-qualification type.') });
                }
                break;
            case 'SELECT EXISTING OR NEW':
                if (!var_existing_or_new) {
                    errors.push({ description: t('Please select either existing or new.') });
                } else if (var_existing_or_new === 'EXISTING' && !var_existing_prequalifier_id) {
                    errors.push({ property: 'existing_prequalifier_id', description: t('Pre-qualification is required') });
                }
                break;
            default:
                // invalid step
                break;
        }
        set_errors(errors);
        if (errors.length > 0) return;

        switch (var_step_no) {
            case 'SELECT PREQUALIFIER TYPE':
                get_existing_prequalifiers();
                break;
            case 'SELECT EXISTING OR NEW':
                if (var_existing_or_new === 'EXISTING') {
                    save_existing_prequalifier();
                } else {
                    set_set_no('CREATE PREQUALIFIER');
                }
                break;
            case 'ENTER NEW':
                set_validate_new_prequalifier(true);
                break;
            default:
                // invalid step
                break;
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_requirement_add_prequalifier'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_add_prequalifier'>{t('Add pre-qualification')}</div>
                </div>
            </div>

            {var_errors.some(item => !item.property) &&
                <Message error
                    icon={<Icon name='error' className='icon' />}
                    header={var_errors.find(item => !item.property).description}
                />
            }

            {var_step_no === 'SELECT PREQUALIFIER TYPE'
                ? display_select_prequalifier_type()
                : var_step_no === 'SELECT EXISTING OR NEW'
                ? display_select_existing_or_new()
                : var_step_no === 'ENTER NEW'
                ? display_new_prequalifier()
                : null
            }

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>
                        {var_step_no === 'SELECT PREQUALIFIER TYPE' ? t('Continue') : t('Save')}
                    </Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

    function display_select_prequalifier_type() {
        return (
            <Form className='modal__content' aria-labelledby='hdr_select_prequalifier_type' tabIndex='0'>
                <div className='text--sm-medium'>{t('Choose a type of pre-qualification to add to this requirement.')}</div>
                {var_prequalifier_type_options.map(item =>
                    <div className='option_container' key={item.prequalifier_type}>
                        <CHECKBOX
                            name={item.prequalifier_type}
                            label={
                                <label>
                                    <div className='text--md-medium'>{item.prequalifier_type_display}</div>
                                    <div className='text--sm-regular'>{item.description}</div>
                                </label>
                            }
                            onChange={(e, { name }) => onChange_prequalifier_type(name)}
                            checked={item.prequalifier_type === var_prequalifier_type}
                            radio
                        />
                    </div>
                )}
            </Form>
        );
    }

    function display_select_existing_or_new() {
        return (
            <Form className='modal__content' aria-labelledby='hdr_select_existing_or_new' tabIndex='0'>
                <div className='text--sm-medium'>{t('Choose an existing pre-qualification or create a new one.')}</div>
                <div className='option_container'>
                        <CHECKBOX
                            name='EXISTING'
                            label={t('Existing pre-qualification')}
                            onChange={() => onChange_existing_or_new('EXISTING')}
                            checked={var_existing_or_new === 'EXISTING'}
                            radio
                        />
                        <SELECT
                            property='existing_prequalifier_id'
                            value={var_existing_prequalifier_id}
                            onChange={onChange_existing_prequalifier}
                            onOpen={() => set_modal_close_on_escape(false)}
                            onClose={() => set_modal_close_on_escape(true)}
                            placeholder={t('Select')}
                            options={var_existing_prequalifier_options}
                            disabled={var_existing_or_new !== 'EXISTING'}
                            errors={var_errors}
                        />
                </div>
                <div className='option_container'>
                        <CHECKBOX
                            name='NEW'
                            label={t('New pre-qualification')}
                            onChange={() => onChange_existing_or_new('NEW')}
                            checked={var_existing_or_new === 'NEW'}
                            radio
                        />
                </div>
            </Form>
        );
    }

    function display_new_prequalifier() {
        return (
            <>
                {requirement_is_active &&
                    <Message warning
                        icon={<Icon name='warning' className='warning-icon--yellow' />}
                        content={get_add_prequalifier_warning_text()}
                    />
                }

                <CMP_EDIT_PREQUALIFIER
                    prequalifier={var_new_prequalifier}
                    validate={var_validate_new_prequalifier}
                    onChange={onChange_new_prequalifier}
                    onValidate={onValidate_new_prequalifier}
                />
            </>
        );
    }
}

CMP_MDL_ADD_PREQUALIFIER.propTypes = {
    display: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired,
    requirement_id: propTypes.string,
    requirement_is_active: propTypes.bool
};

export default CMP_MDL_ADD_PREQUALIFIER;
