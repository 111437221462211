import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import API from 'libs/api-lib';
import { Modal, Button, Message } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';


export default function MDL_ASSIGNED({ display, onClose, title, requirement_name, requirement_id, requirement_status, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_deleteindividual_open, set_deleteindividual_open ] = useState(false);
    const [ var_deleteindividual_id, set_deleteindividual_id ] = useState(null);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_parent_focuselement, set_parent_focuselement ] = useState(null);
    const [ var_table_focuselement, set_table_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_parent_focuselement(document.activeElement);
            set_refreshtable(!var_refreshtable);
            populate_records({ ...default_populateconfig, sortby: 'individual_name' });
            set_modal(document.querySelector('#mdl_requirementdetails'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (!var_deleteindividual_open) {
            if (var_table_focuselement) {
                if (var_table_focuselement.isConnected) {
                    var_table_focuselement.focus();
                    set_table_focuselement(null);
                } else {
                    document.getElementById('mdl_content').focus();
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deleteindividual_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_requirement_count_details(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_individuals(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_requirement_count_details(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_requirement_count_details(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_requirement_count_details(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-org-requirement-count-details/' + requirement_id + '/' + title,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone,
                    requirement_status
                },
                body: filter
            }
        );
    }

    function API_delete_org_requirement_assignment() {
        return API.del('requirements', '/delete-org-requirement-assignment/' + var_deleteindividual_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_individual(individual_id) {
        navigate('/individuals/' + individual_id);
    }

    function onDelete_requirement(id) {
        set_table_focuselement(document.activeElement);
        set_message_text(null);
        set_deleteindividual_id(id);
        set_deleteindividual_open(true);
    }

    async function onConfirm_delete() {
        set_message_text(null);
        set_processing(true);
        document.getElementById('mdl_requirementdetails').scrollTo(0, 0);
        let error = false;
        try {
            await API_delete_org_requirement_assignment();
        } catch (e) {
            error = true;
            set_message_text(t('There was a problem saving.  Please try again later'));
            set_message_type('ERROR');
        }
        set_deleteindividual_open(false);
        set_processing(false);
        if (!error) {
            populate_records();
            onChange && onChange();
        }
    }

    function onClose_modal() {
        onClose(var_parent_focuselement?.id);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose_modal}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_requirementdetails'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_requirementdetails'
            >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_requirementdetails'>{t(title)}</div>
                    <div className='text--sm-regular'>{requirement_name}</div>
                </div>
            </div>

            <div id='mdl_content' className='modal__content' tabIndex='0' aria-labelledby='hdr_requirementdetails'>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='individuals' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('individuals')} downloadfunction={download_records}
                        onChange={populate_records}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('JOB TITLE')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_individuals.map(item =>
                            <Table.Row key={item.individualrequirement_id}>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.individual_id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.job_title}</Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    {item.organization_name}
                                    {requirement_status !== 'DRAFT' && item.requirementassignment_id &&
                                        <Button className='tertiary' onClick={() => onDelete_requirement(item.requirementassignment_id)}>
                                            <Icon name='delete' className='color--primary-500' />
                                        </Button>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose_modal}>{t('Close')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

            <CMP_CONFIRMATION display={var_deleteindividual_open} title={t('Remove individual')} message={
                <div style={{flexDirection: 'column'}}>
                    <div style={{marginBottom: '0.5rem'}}>{t('Removing this individual from the requirement may impact their ability to work and your compliance reports.')}</div>
                    <div style={{marginBottom: '0.5rem'}}>{t('You can reassign this requirement to the individual at any time.')}</div>
                </div>
            } positive_option={t('Remove')} negative_option={t('Cancel')} onConfirm={onConfirm_delete} onCancel={() => set_deleteindividual_open(false)} />

        </Modal>
    );

}