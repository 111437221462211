import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import { Form, Modal, Button } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import 'i18n';
import { CHECKBOX, FORM_SELECT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';


export default function MDL_ADD_CREDENTIAL({ display, prequalifier, status, onCredentialAdded, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_selected_issuer, set_selected_issuer ] = useState('');
    const [ var_issuer_list, set_issuer_list ] = useState([]);
    const [ var_selected_credentials, set_selected_credentials ] = useState([]);
    const [ var_credential_list, set_credential_list ] = useState([]);
    const [ var_all_credentials, set_all_credentials ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);

    const [ var_errors, set_errors ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------
    
    useEffect(() => {
        if (var_modal) {
            let firstelement = var_modal.querySelector('.modal__content');
            let lastelement = Array.from(var_modal.querySelectorAll('button:not([disabled])')).at(-1);

            //  set focus to first element within the modal
            firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key !== 'Tab') return;

                if (e.shiftKey && document.activeElement === firstelement) /* shift + tab */ {
                    lastelement.focus();
                    e.preventDefault();
                } else if (!e.shiftKey && document.activeElement === lastelement) /* tab */ {
                    firstelement.focus();
                    var_modal.querySelector('.modal__header').scrollIntoView();
                    e.preventDefault();
                }
            });
        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_add_credential'));
            set_selected_issuer('');
            set_selected_credentials([]);
            set_credential_list([]);
            set_all_credentials(false);
            if (var_issuer_list.length === 0) {
                populate_issuer_list();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_issuer_list() {
        let results = await API_get_issuer_list();
        set_issuer_list(results.map(item => ({ value: item.id, text: item.name })));
    }

    async function populate_credentials(issuer_id) {
        let results = await API_get_org_prequalifier_available_preapprovals(issuer_id);
        let issuer_rejected = prequalifier.credential.preapprovals.some(preapproval => preapproval.credential_id === null && preapproval.issuer_id === issuer_id);
        let transformed = results.map(item => {
            let already_added = issuer_rejected || prequalifier.credential.preapprovals.some(preapproval => preapproval.credential_id === item.id);

            return {
                value: item.id,
                primary_text: item.name,
                secondary_text: already_added ? t('Already added') : null,
                disabled: already_added,
                credit_value: item.credit_value,
                footprint_credit_value: item.credit_value
            };
        });
        set_credential_list(transformed);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_issuer_list() {
        return API.get('credentials', '/get-issuer-list');
    }

    function API_get_org_prequalifier_available_preapprovals(issuer_id) {
        return API.get('requirements2', `/get-org-prequalifier-available-preapprovals/${issuer_id}`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_issuer(event, { value }) {
        set_selected_issuer(value);
        set_selected_credentials([]);
        set_all_credentials(false);
        populate_credentials(value);
        set_errors([]);
    }

    function onChange_credential(value) {
        set_selected_credentials(value);
        set_errors([]);
    }

    function onChange_all_credentials() {
        let all_credentials = !var_all_credentials;
        set_all_credentials(all_credentials);
        if (all_credentials) {
            set_selected_credentials([]);
        }
        set_errors([]);
    }

    function onClick_save() {
        // validation
        let errors = [];
        if (!var_selected_issuer) {
            errors.push({ property: 'issuer', description: t('Issuer is required') });
        } else if (!var_all_credentials && var_selected_credentials.length === 0) {
            errors.push({ property: 'credentials', description: t('At least one credential must be selected') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        if (var_all_credentials) {
            onCredentialAdded(var_selected_issuer, var_issuer_list.find(item => item.value === var_selected_issuer).text, null, null);
        } else {
            onCredentialAdded(var_selected_issuer, var_issuer_list.find(item => item.value === var_selected_issuer).text, [ ...var_selected_credentials ],
                var_credential_list.filter(item => var_selected_credentials.includes(item.value)).map(item => ({ id: item.value, name: item.primary_text, credit_value: item.credit_value, footprint_credit_value: item.footprint_credit_value })));
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_credential'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>{t('Select credentials')}</div>
                </div>
                <div className='modal__header__right'>
                    <div className="modal__header__closeicon color--primary-500">
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0'>

                <FORM_SELECT
                    property='issuer'
                    label={t('Issuer')}
                    value={var_selected_issuer}
                    onChange={onChange_issuer}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('Select')}
                    options={var_issuer_list}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />

                <FORM_SELECT_RADIO
                    name='credentials'
                    property='credentials'
                    label={t('Credential')}
                    value={var_selected_credentials}
                    options={var_credential_list}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_credential}
                    placeholder={t('Search')}
                    disabled={var_all_credentials}
                    errors={var_errors}
                />

                {status === 'INVALID' &&
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'></div>
                        <div className='field detailsgroup__value'>
                            <CHECKBOX
                                property='all_credentials'
                                label={t('Reject all credentials from this issuer')}
                                checked={var_all_credentials}
                                onChange={onChange_all_credentials}
                                disabled={!var_selected_issuer || prequalifier.credential.preapprovals.some(item => item.issuer_id === var_selected_issuer)}
                            />
                        </div>
                    </div>
                }

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

        </Modal>
    );
}
