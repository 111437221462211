import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_DETAILS from './tab_details/tab_details';
import TAB_REQUIREMENTS from './tab_requirements/tab_requirements'
import TAB_INDIVIDUALS from './tab_individuals/tab_individuals';

export default function PREQUALIFICATION_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_prequalification, set_prequalification ] = useState({});



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        } else {
            set_tab('DETAILS');
        }
        populate_prequalification_details();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.prequalificationid]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_prequalification_details() {
        try {
            let results = await API_get_org_prequalification();
            set_prequalification(results);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_prequalification() {
        return API.get('requirements2', '/get-org-prequalification/' + params.prequalificationid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/requirements2', text: t('Requirements') }, { path: '/org/requirements2/prequalifications', text: t('Pre-qualifications') }]}
                current_page_text={var_prequalification.name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{var_prequalification.name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Pre-qualification')} id='tabs_prequalification_details'>
                    <TABS.TAB name='DETAILS' label={t('Details')} />
                    <TABS.TAB name='REQUIREMENTS' label={t('Requirements')} />
                    <TABS.TAB name='INDIVIDUALS' label={t('Individuals')} />
                </TABS>

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS prequalification={var_prequalification} onChange_prequalification={populate_prequalification_details} />
                }

                {var_tab === 'REQUIREMENTS' &&
                    <TAB_REQUIREMENTS prequalification_id={params.prequalificationid} />
                }

                {var_tab === 'INDIVIDUALS' &&
                    <TAB_INDIVIDUALS prequalification_id={params.prequalificationid} />
                }
            </div>
        </div>
    )
};