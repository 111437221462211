import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './cmp_qrcode.css';

function CMP_QRCODE({ name, qr_value, title, content, display, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, div.text--sm-regular.text--anchor, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_qrcode'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    //  functions ------------------------------------------------------------------------------------------------------

    function onClick_downloadQR(){
        const canvas = document.getElementById('canvasQRcode');
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = name + ' QR.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    function onKeyDown_downloadQR(e) {
        if (e.key === 'Enter') {
            onClick_downloadQR();
        }
    }

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_qrcode'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_qrcode'
        >
            <div className='modal__header'>
                <div className='header__wrapper'>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_qrcode'>{title}</div>
                    </div>
                </div>
            </div>
            <div className='modal__content' tabIndex='0'>
                <div className='text--sm-regular' style={{ marginBottom: '1rem' }}>
                    {content}
                </div>
                <QRCodeCanvas
                    className='qrcode'
                    id='canvasQRcode'
                    value={qr_value}
                    size={200}
                    level='H'
                />
                <div className='text--sm-regular text--anchor' style={{ marginTop: '1rem' }} onClick={onClick_downloadQR} onKeyDown={onKeyDown_downloadQR} tabIndex='0'>{t('Download printable QR code.')}</div>
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>
        </Modal>
    );
}

CMP_QRCODE.propTypes = {
    name: propTypes.string.isRequired,
    qr_value: propTypes.string.isRequired,
    title: propTypes.string,
    content: propTypes.string,
    display: propTypes.bool,
    onClose: propTypes.func
};

export default CMP_QRCODE;