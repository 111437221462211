import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import Logo from '../../cmp_logo';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'i18n';

function FORM_DETAILSGROUP({ label, value, type, linktext, badgecolor, logo_details=null }) {


    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const unique_id = _.uniqueId();

    //  functions ------------------------------------------------------------------------------------------------------

    function onClick_internallink(value) {
        if (value && typeof value === 'function') {
            value();
        } else {
            window.open(value);
        }
    }


    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            {type === 'logo' ?
                <div className='detailsgroup__value logo'>
                    <Logo targetid={logo_details.targetid} classification={logo_details.classification} filename={logo_details.filename}
                            credivera_on_missing={logo_details.credivera_on_missing} credivera_on_error={logo_details.credivera_on_error}
                            message_on_virus_scan={logo_details.message_on_virus_scan} alt={logo_details.alt} />
                </div>
            : type === 'internallink' ?
                <div className='detailsgroup__value text--sm-regular text--anchor' >
                    <Button id={`btn_formdetails_${unique_id}`} className='tertiary' onClick={() => onClick_internallink(value)}>{linktext ? linktext : t('Click link')}</Button>
                </div>
            : type === 'externallink' ?
                <div className='detailsgroup__value text--sm-regular text--anchor' >
                    <Button id={`btn_formdetails_${unique_id}`} className='tertiary' onClick={() => window.open(value)}>{linktext}</Button>
                </div>
            : type === 'badge' ?
                <div className={'badge text--sm-medium ' + badgecolor }>{value}</div>
                :
                <div className='detailsgroup__value text--sm-regular'>{value}</div>
            }
        </div>
    );
}


FORM_DETAILSGROUP.propTypes = {
    label: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.func]),
    type: propTypes.string,
    linktext: propTypes.string,
    badgecolor: propTypes.string,
    logo_details: propTypes.object
};

export default FORM_DETAILSGROUP;