import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';

import './mdl_attestation.css';


export default function MDL_ATTESTATION({ display, onClose, individualprequalifier_id, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_attestation, set_attestation ] = useState({});
    const [ var_attestation_checked, set_attestation_checked ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_errors, set_errors ] = useState([])
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, input[type="checkbox"]:not([disabled]), button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_attestation'));
            set_attestation({});
            set_attestation_checked(false);
            set_processing(false);
            set_errors([]);
            load_attestation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function load_attestation() {
        set_attestation(await API_get_credential_prequalifier_attestation());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_prequalifier_attestation() {
        return API.get('credentials', '/get-credential-prequalifier-attestation/' + individualprequalifier_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_put_credential_prequalifier_attestation() {
        return API.put('credentials', '/put-credential-prequalifier-attestation', {
            queryStringParameters: { tz: datelib.timezone },
            body: { individualprequalifier_id }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_checkbox() {
        set_errors([]);
        set_attestation_checked(!var_attestation_checked);
    }

    async function onClick_save() {
        // validation
        if (!var_attestation_checked) {
            set_errors([{ property: 'checkbox', description: 'You must make your attestation before submitting' }]);
            return;
        }

        set_processing(true);
        document.getElementById('mdl_attestation').scrollTo(0, 0);
        try {
            await API_put_credential_prequalifier_attestation();
            onChange && onChange();
        } catch (exception) {
            console.log(exception);
            set_errors([{ description: 'An unexpected error occurred. Please try again.' }]);
        }
        set_processing(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_attestation'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_attestation'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_attestation'>{t('Complete attestation')}</div>
                </div>
            </div>
            <Form className='modal__content' tabIndex='0'>

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {var_errors.length > 0 &&
                        <Message error header={var_errors[0].description} />
                    }

                    <div className='longdescription'>{var_attestation.long_description}</div>

                    <CHECKBOX
                        label={var_attestation.attestation_text}
                        property='checkbox'
                        disabled={var_attestation.status === 'VALID'}
                        checked={var_attestation_checked || var_attestation.status === 'VALID'}
                        onChange={onChange_checkbox} />
                </div>

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {(var_attestation.status && var_attestation.status !== 'VALID') &&
                        <Button className='primary' onClick={onClick_save}>{t('Submit')}</Button>
                    }
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );
}