import React, { useEffect } from 'react';

import CRD_DETAILS from './crd_details/crd_details';
import CRD_SETTINGS from './crd_settings/crd_settings';
import CRD_QR_CODE from './crd_qr_code/crd_qr_code';



export default function TAB_DETAILS({ credential_details, credential_settings, onChange_details, onChange_settings }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_DETAILS credential={credential_details} settings={credential_settings} onChange={onChange_details} />
            <CRD_SETTINGS credential_settings={credential_settings} onChange={onChange_settings} />
            {credential_details?.credits_required_for_issuance === 'YES' && credential_details?.status === 'ACTIVE' && <CRD_QR_CODE credential_id={credential_details.id} />}
        </>
    )
};
