import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import datelib from 'libs/date-lib';
import Processing from 'components/cmp_processing';
import { FORM_SELECT_RADIO, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';



function MDL_ADD_ASSIGNMENT({ display, onClose, requirement_name, requirement_id, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_selected_assignments, set_selected_assignments ] = useState([]);
    const [ var_all_individuals, set_all_individuals ] = useState('NO');
    const [ var_api_error, set_api_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display && requirement_id) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_addassignment'));
            set_assignments([]);
            set_selected_assignments([]);
            set_all_individuals('NO');
            set_api_error(null);
            set_processing(false);
            populate_assignments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, requirement_id]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments() {
        set_processing(true);
        try {
            let results = await API_get_org_requirement_available_assignments();
            if (results) {
                let transformed = results.map(item => ({
                    value: item.id,
                    primary_text: item.assignment_name,
                    secondary_text: item.already_added === 'YES' ? t('Already added to assignment') : null,
                    disabled: item.already_added === 'YES'
                }));
                set_assignments(transformed);
            }
        } catch (e) {
            console.log(e);
            set_api_error(t('There was a problem loading.  Please try again later'));
        }
        set_processing(false);
    }

    async function add_assignments() {
        set_processing(true);
        // Loop through selected locations
        for (let assignment of var_selected_assignments) {
            try {
                await API_post_location_requirement(assignment);
            } catch (e) {
                console.log(e);
                set_api_error(t('There was a problem saving.  Please try again later'));
            }
        }
        onChange();
        onClose();
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_requirement_available_assignments() {
        return API.get('requirements2', '/get-org-requirement-available-assignments/' + requirement_id);
    }

    function API_post_location_requirement(assignment_id) {
        return API.post('requirements2', '/post-location-requirement', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                location_id: assignment_id,
                requirement_id: requirement_id,
                all_individuals: var_all_individuals
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_assignments(value) {
        set_selected_assignments(value);
        set_api_error(null);
    }

    async function onClick_add() {
        if (var_selected_assignments.length === 0) {
            set_api_error(t('Please select at least one assignment to add'));
            return;
        }
        await add_assignments();
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_addassignment'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_reqdetails_addassignment'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_reqdetails_addassignment'>{t('Add assignment')}</div>
                    <div className='text--sm-regular'>{requirement_name}</div>
                </div>
            </div>

            <Form className='modal__content center padding--lg' id='form_reqdetails_addassignment' aria-labelledby='hdr_reqdetails_addassignment' tabIndex='0'>
                {var_api_error &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_api_error} />
                }
                <div className='margin--lg'>
                    <FORM_SELECT_RADIO
                        name='assignments'
                        property='assignments'
                        label={t('Assignments')}
                        value={var_selected_assignments}
                        options={var_assignments}
                        single_or_multiple='MULTIPLE'
                        onChange={onChange_assignments}
                        placeholder={t('Search')}
                        disabled={false}
                        errors={[]}
                    />
                </div>

                <FORM_TOGGLE label={t('Auto-assign')} description={t('Automatically assign this requirement to all individuals in the selected assignments.')} value={var_all_individuals}
                    onChange={set_all_individuals} options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]} />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_ADD_ASSIGNMENT;