import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import 'i18n';


export default function CRD_REQUIREMENTS({ prequalification_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'requirement_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_mdl_link_requirement_open, set_mdl_link_requirement_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_requirements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_mdl_link_requirement_open && ['btn_link_requirement', 'btn_link_requirement_mobile'].includes(var_focuselement?.id)) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_link_requirement_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_prequalifier_requirements(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_requirements(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_prequalifier_requirements(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_requirements(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_prequalifier_requirements(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_prequalifier_requirements(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-org-prequalifier-requirements/' + prequalification_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_link_requirement() {
        //set_focuselement(document.activeElement);
        // set_mdl_link_requirement_open(true);
        console.warn('Link requirement is not yet implemented');
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_prequalification_details_requirements' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Linked requirements')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button id='btn_link_requirement' className='primary' onClick={onClick_link_requirement}>{t('Link requirement')}</Button>
                </div>
            </div>

            <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={t('Requirements')} downloadfunction={download_requirements}
                    onChange={populate_requirements}>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                        <Table.HeaderCell field='requirement_name' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_requirements.map(item =>
                        <Table.Row key={item.id}>
                            <Table.Cell>
                                <div className={'badge text--sm-medium  ' +
                                    (   item.status === 'Valid' ?
                                        'badge--green'
                                        : 'badge--yellow')}>
                                    {item.status_display}
                                </div>
                            </Table.Cell>
                            <Table.Cell onClick={() => navigate(`/org/requirements2/${item.requirement_id}`)}>{item.requirement_name}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <div className='card__footer--mobilebtns'>
                <Button id='btn_link_requirement_mobile' className='primary' onClick={onClick_link_requirement}>{t('Link requirement')}</Button>
            </div>
        </div>
    );

}
